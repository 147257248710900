import React, { useState, useEffect, useRef } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
import { usePermissions } from '../../PermissionsProvider';
function ContactGroupLine(props){
	const { isActionAllowed, isAgent } = usePermissions();
	const navigate = useNavigate();
 	const [sessionData, setSessionData] = useState(null)
  	useEffect(()=>{
    	setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
	},[]) 


function editContactGroup(){
	props.editContactGroup(props.contact_group.id)
}


function checkEditPermission(){
	if(isActionAllowed('EDIT_CONTACT_GROUP') || isAgent())
		return true;
	return false
}
function checkBoxClicked(event){
	props.checkBoxClicked(props.contact_group.id, event.target.checked)
}

return (
	<tr style={{borderBottom:'1px solid rgb(235, 237, 240)',fontFamily:"Roboto,Helvetica,BogleWeb,MyriadPro,Helvetica Neue,Helvetica,Arial,sans-serif"}}>
	   { (sessionData !== null && sessionData.isAdmin) ? 
	   	  <th scope="row" class="ps-4">
             <div class="form-check font-size-16"><input type="checkbox" class="form-check-input contact-group-line-checkbox" onClick={checkBoxClicked} name={'checkbox-'+props.contact_group.id} style={{border:'1px solid #A9A9A9'}}/></div>
          </th> : '' }
	    <td style = {{width: "30%", cursor: 'pointer', color: '#0052cc',fontWeight:'800',fonFamily:'Helvetica'}} onClick = {editContactGroup}>{props.contact_group.name}</td>
	    <td style = {{width: "70%"}}>{props.contact_group.count}</td>
	 </tr>
  );
}

export default ContactGroupLine;