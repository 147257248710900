import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
function SupportEmailLine(props){


function openSupportEmail(){

}
function checkBoxClicked(event){
	props.checkBoxClicked(props.supportEmail.id, event.target.checked)
}


return (

		    <tr>
			    <th scope="row" class="ps-4" style={{maxWidth: '50px'}}>
	             <div class="form-check font-size-16"><input type="checkbox" class="form-check-input support-email-line-checkbox" onClick={checkBoxClicked} name={'checkbox-'+props.supportEmail.id} style={{border:'1px solid #A9A9A9'}}/></div>
	          	</th>		    	
		        <td  style = {{cursor:'pointer',fontWeight:'800',fonFamily:'Helvetica'}}><a onClick = {(event)=>props.openChangeEmailWindow(event, props.supportEmail.id)} href='' style = {{textDecoration: 'none'}}>{props.supportEmail.friendlyName}</a></td>
		        <td style = {{cursor:'pointer',fonFamily:'Helvetica'}}>{props.supportEmail.email}</td>
		        <td style = {{cursor:'pointer',fonFamily:'Helvetica'}}>{props.supportEmail.replyToEmail === 'null' ? '--' : props.supportEmail.replyToEmail}</td>
		        <td style = {{cursor:'pointer',fonFamily:'Helvetica'}}>{props.supportEmail.team === null ? '--' : props.supportEmail.team.name}</td>
		        <td style = {{cursor:'pointer',fonFamily:'Helvetica'}}>{props.supportEmail.isActive === 'true'? 'Yes' : 'No'}</td>
		    </tr>		
        );

}

export default SupportEmailLine;