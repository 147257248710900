import React, { useState, useEffect, useRef } from "react";
import CategoryLine from './CategoryLine';
import Article from './Article';
import * as axiosClient from "../../../js/Axios";
import * as constants from '../../../js/constants';
import '../css/kb.css';
import 'quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';

function KB() {
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [article, setArticle] = useState(null);
  const [edit, setEdit] = useState(false);
  const [list, setList] = useState(true);
  
  const nameRef = useRef(null);
  const descriptionRef = useRef(null);
  const indexRef = useRef(null);
  const showCreateCategoryRef = useRef(null);
  const [render, setRender] = useState(true)
  const [categoriesBackup, setCategoriesBackup] = useState([])
  useEffect(() => {
    loadCategories();
  }, []);
  
  const loadCategories = () => {
    axiosClient.getRequest(constants.KB_URL + 'categories')
      .then((response) => {
        // const categoriesList = response.data.map((element, index) => (
        //   <CategoryLine key={element.id} name={element.name} element={element} openCategory={openCategory} displayArticles={displayArticles} />
        // ));
        setCategories(response.data);
        setCategoriesBackup(response.data);
        setRender(!render)
      })
      .catch((err) => {
        console.error("Error loading categories", err);
        toast.error("Failed to load categories.");
      });
  };

  const displayContent = (id) => {
    axiosClient.getRequest(constants.KB_URL + 'articles?id=' + id)
      .then((response) => {
        setArticle(response.data);
        setList(false);
      })
      .catch((err) => {
        console.error("Error fetching article content", err);
        toast.error("Failed to load article.");
      });
  };

  const createCategory = (event) => {
    event.preventDefault();
    const payload = { name: event.target.name.value, description: event.target.description.value };
    const url = edit ? constants.KB_URL + 'category/' + event.target.index.value : constants.KB_URL + 'category';
    const request = edit ? axiosClient.patchRequest(url, payload) : axiosClient.postRequest(url, payload);

    request
      .then(() => {
        loadCategories();
        setEdit(false);
        showCreateCategoryRef.current.style.display = 'none';
      })
      .catch((err) => {
        console.error("Error creating/updating category", err);
        toast.error("Failed to create/update category.");
      });
  };

  const openCategory = (name, description, index) => {
    nameRef.current.value = name;
    descriptionRef.current.value = description;
    indexRef.current.value = index;
    setEdit(true);
    showCreateCategoryRef.current.style.display = 'block';
  };

  const displayCreateCategoryBox = () => {
    setEdit(false);
    showCreateCategoryRef.current.style.display = 'block';
  };

  const closeCreateCategoryForm = () => {
    showCreateCategoryRef.current.style.display = 'none';
    setEdit(false);
  };

  const publishArticle = (id) => {
    axiosClient.postRequest(constants.KB_URL + 'publish_article?id=' + id)
      .then((response) => {
		setCategories(prevCategories => {
		    const updatedCategories = prevCategories.map(category => {
		      if (category.subCategories && category.subCategories.length > 0) {
		        const updatedSubCategories = category.subCategories.map(subCategory => {
		          if (subCategory.articles && subCategory.articles.length > 0) {
		            const updatedArticles = subCategory.articles.map(article => {
		              return String(article.id) === String(id) ? { ...article, ...response.data } : article;
		            });
		            return { ...subCategory, articles: updatedArticles };
		          }
		          return subCategory;
		        });

		        return { ...category, subCategories: updatedSubCategories };
		      }
		      return category;
		    });

		    return updatedCategories; // Return updated state
		  });		
        toast.success("Article published successfully");
      })
      .catch((err) => {
        console.error("Error publishing article", err);
        toast.error('Unable to publish article.');
      });
  };

  const handleSearch = (event) => {
    console.log('handleSearch '+event.target.value)
    if(event.target.value === ''){
      console.log('Entered inside')
      return setCategories(categoriesBackup);
    }
    const filtered = categoriesBackup.filter(category =>
      category.name.toLowerCase().startsWith(event.target.value.toLowerCase())
    );
    setCategories(filtered);
  };

	function displayArticles(articles){
		console.log('displayArticles '+JSON.stringify(articles))
		setArticles(articles)
		setList(true)			
	}




  return (
    <>
      <div className="header-body">
        <div className="main-header">
          <div style={{ display: 'inline-block', float: 'left', textAlign: 'baseline' }}>
            <b>Knowledge Base</b>
          </div>
          <div style={{ display: 'inline-block', right: '0px', position: 'absolute' }}>
            <button className='ss-button' onClick={displayCreateCategoryBox}>+ New Category</button>
          </div>
        </div>
      </div>
      <div className='main-body'>
        <div style={{ padding: '10px', position: 'absolute', left: '0px', width: '350px', borderRight: '1px solid #dee2e6', minWidth: '250px', height: '100%', overflowY: 'auto', paddingLeft: '20px' }}>
          <input type='text' style={{ width: '100%' }} className='form-control' placeholder='search categories'  onChange={handleSearch}/>
          <div style={{ marginTop: '20px', fontSize: '26px', fontWeight: '600' }}>Categories</div>
          {
	          	categories.map((element, index) => (
	          		<CategoryLine key={element.id} name={element.name} element={element} openCategory={openCategory} displayArticles={displayArticles} />
	        	))
          }
        </div>
        <div style={{ position: 'absolute', left: '350px', width: 'calc(100% - 350px)', height: '100%', overflowY: 'auto' }}>
          {list ? (
            <table className='table'>
              <thead>
                <tr><th>Name</th><th>Visible in Agent Portal</th><th>Visible in Customer Portal</th><th>Status</th><th></th></tr>
              </thead>
              <tbody>
                {articles && articles.map((article, index) => (
                  <tr key={index}>
                    <td>
                      <a href="#" onClick={() => displayContent(article.id)} style={{textDecoration: 'none', fontWeight:'500'}}>{article.name}</a>
                    </td>
                    <td>{article.apVisible === 'false' ? 'No' : 'Yes'}</td>
                    <td>{article.cpVisible === 'false' ? 'No' : 'Yes'}</td>
                    <td>{article.status}</td>
                    <td>
                      {article.status === 'Draft' && (
                        <button className='ss-button' onClick={() => publishArticle(article.id)}>Publish</button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Article article={article} />
          )}
        </div>
      </div>

      <div className="kb-modal" ref={showCreateCategoryRef} style={{ display: 'none' }}>
        <div className="kb-modal-content">
          <span onClick={closeCreateCategoryForm} style={{ position: 'absolute', right: '30px', cursor: 'pointer', fontSize: '20px', zIndex: '100' }}>&times;</span>
          <h4 style={{ opacity: '0.8' }}>{edit ? 'Update Category' : 'Create Category'}</h4>
          <form onSubmit={createCategory}>
            <div className='create-form-label' style={{ marginTop: "3%" }}>Name:<p className='red-color'>*</p></div>
            <div><input type="text" name="name" style={{ width: "90%", paddingLeft: '5px' }} placeholder={'Name'} required maxlength='500' ref={nameRef} /></div>
            <div className='create-form-label' style={{ marginTop: "3%" }}>Description:</div>
            <div><textarea rows='5' name="description" style={{ width: "90%" }} placeholder={'Description'} ref={descriptionRef} /></div>
            <input style={{ display: "none" }} name='index' ref={indexRef} />
            <button className='ss-button'>{edit ? 'Update Category' : 'Create Category'}</button>
          </form>
        </div>
      </div>

      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
        newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
        draggable pauseOnHover theme="colored" />
    </>
  );
}

export default KB;
