
import React from "react"
import {useState, setState, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../../../js/Axios';
import * as constants from '../../../../../js/constants'
import '../../css/dashboard.css'

function CallCountDashboard(props){
	
return(		<div style={{marginTop:'20px',justifyContent:'start',flexDirection:'row',flexWrap: 'wrap' }}>
                  <div class="container dashboard-container">
        <div class="dashboard-grid">
                  <div class="dashboard-card ">
                      <div>
                          <h3>Total Calls</h3>
                          <p>{props.data.countStats !== undefined ? props.data.countStats[0] : 0 }</p>
                      </div>
                      <div class="circle" data-percent="65"></div>
                  </div>

                  <div class="dashboard-card ">
                      <div>
                          <h3>Incmoing Calls</h3>
                          <p>{props.data.countStats !== undefined ? props.data.countStats[2] : 0 }</p>
                      </div>
                      <div class="circle" data-percent="85"></div>
                  </div>

                  <div class="dashboard-card ">
                      <div>
                          <h3>Outgoing Calls</h3>
                          <p>{props.data.countStats !== undefined ? props.data.countStats[1] : 0 }</p>
                      </div>
                      <div class="circle" data-percent="45"></div>
                  </div>

                  <div class="dashboard-card ">
                      <div>
                          <h3>Total Minutes</h3>
                          <p>{props.data.countStats !== undefined ? props.data.countStats[3] : 0 }</p>
                      </div>
                  </div>            
              </div>
          </div>  
		</div>
	)
}


export default CallCountDashboard;