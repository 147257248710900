import React , { useState, useEffect, useRef }from 'react';
import ReactPaginate from 'react-paginate';

function Pagination(props){

  const [noOfPages, setNoOfPages] = useState(null) 
  const PER_PAGE = 25;
  useEffect(()=>{
      let noOfPages2 = Math.ceil(props.count / PER_PAGE);
      setNoOfPages(noOfPages2)
  })
  function handlePageClick(event){
    props.handlePagination(event.selected)
  }

return(<div class='apply-pagination'>
    {
      props.count === 0 ? '' :
      <ReactPaginate
            previousLabel={"<< Previous"}            
            nextLabel={"Next >>"}
            pageCount={noOfPages}

            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={''}
            nextLinkClassName={""}
            forcePage={props.pageNo}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"apply-pagination-active"}
          />
    }
  </div>)
}
export default Pagination;