import React, { useState, useEffect } from 'react';
import * as constants from '../../../js/constants'
import * as axiosClient from '../../../js/Axios';

const ApiInputComponent = ({ closeCreateWindow, editApiRef }) => {
  const [apiUrl, setApiUrl] = useState('');
  const [httpMethod, setHttpMethod] = useState('GET');
  const [headers, setHeaders] = useState([{ key: '', value: '' }]);
  const [body, setBody] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [error, setError] = useState('');
  const [name, setName] = useState('')

  useEffect(()=>{
    if(editApiRef.current){
        axiosClient.getRequest(constants.CHAT_BOT_API+'/'+editApiRef.current)
       .then(function (response) {
            setName(response.data.name)
            setApiUrl(response.data.url || '');
            setHttpMethod(response.data.httpMethod || 'GET');
            setBody(response.data.body)
            // setAuthType(response.data.authType || '');
            // setDescription(response.data.description || '');
            setHeaders(response.data.headers || []);
      }).catch((error) => {
             console.log(error)
      });
    }
  },[])
  // Handle adding headers
  const handleAddHeader = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };

  // Handle updating header key/value pairs
  const handleHeaderChange = (index, field, value) => {
    const newHeaders = [...headers];
    newHeaders[index][field] = value;
    setHeaders(newHeaders);
  };

  // Handle removing headers
  const handleRemoveHeader = (index) => {
    const newHeaders = headers.filter((_, i) => i !== index);
    setHeaders(newHeaders);
  };

  // Validate JSON format for the request body
  const validateJson = (json) => {
    try {
      JSON.parse(json);
      return true;
    } catch {
      return false;
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    console.log('handleSubmit')
    e.preventDefault();

    // Validate body for POST or PUT requests
    if ((httpMethod === 'POST' || httpMethod === 'PUT') && body && !validateJson(body)) {
      setError('Invalid JSON format in body.');
      return;
    }

    const apiData = {
      url: apiUrl,
      httpMethod: httpMethod,
      headers: headers.filter(header => header.key && header.value),
      body: httpMethod !== 'GET' ? body : null,
    };

    // Create the payload for the API request
    let payload = {
      name:name,
      url:apiUrl,
      httpMethod: httpMethod,
      body:body,
      headers: apiData.headers.length > 0 ? 
        apiData.headers.reduce((acc, header) => {
          acc[header.key] = header.value;
          return acc;
        }, {}) : {}
    };

    if(editApiRef.current){
      axiosClient.patchRequest(`${constants.CHAT_BOT_API}/${editApiRef.current}`, payload)
          .then((response) => {
              console.log("API details updated successfully", response.data);
              // Optional: You could add success notifications or reset form here if needed.
          })
          .catch((error) => {
              console.log("Error updating API data:", error);
          });
    }else{
      axiosClient.postRequest(constants.CHAT_BOT_API, payload)
         .then(function (response) {
          console.log('response '+response.data)
      }).catch((error) => {
             console.log(error)
      });
    }

  };


  const checkResponse = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(apiUrl, {
        method: httpMethod,
        headers: headers.reduce((acc, header) => {
          if (header.key && header.value) acc[header.key] = header.value;
          return acc;
        }, {}),
        body: httpMethod !== 'GET' ? JSON.stringify(JSON.parse(body)) : null,
      });

      const result = await response.text();
      setApiResponse(result);
      setError('');
    } catch (err) {
      setError(`Error: ${err.message}`);
      setApiResponse('');
    }
  };


  return (
    <div className="create-wrapper">      
      <h4>Configure API Request</h4>
      <a href="javascript:void(0)" class="create-closebtn" onClick={closeCreateWindow}>&times;</a>
      <div class='create-line'></div> 
      <form onSubmit={handleSubmit}>
        <div>
          <div className="create-form-label" style={{ marginTop: "30px" }}>Name:</div>
          
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="name"
            required
            style={{ width: "90%" }}
          />
        </div>      
        <div>
          <div className="create-form-label" style={{ marginTop: "30px" }}>API Endpoint:</div>
          
          <input
            type="url"
            value={apiUrl}
            onChange={(e) => setApiUrl(e.target.value)}
            placeholder="https://example.com/api"
            required
            style={{ width: "90%" }}
          />
        </div>

        <div style={{ marginTop: "20px" }}>
          <span className="create-form-label">HTTP Method:</span>
          <select value={httpMethod} onChange={(e) => setHttpMethod(e.target.value)} style={{ marginLeft: '10px', width: '100px', borderRadius: '5px', padding: '5px 8px' }}>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
          </select>
        </div>

        <div style={{ marginTop: "20px" }}>
          <div className="create-form-label">Headers:</div>
          {headers.map((header, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                value={header.headerName}
                placeholder="Header Key"
                onChange={(e) => handleHeaderChange(index, 'key', e.target.value)}
                style={{ width: '40%', marginTop: '10px' }}
              />
              <input
                type="text"
                value={header.headerValue}
                placeholder="Header Value"
                onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
                style={{ width: '40%', marginLeft: '10px', marginTop: '10px' }}
              />
              <button type="button" onClick={() => handleRemoveHeader(index)} className="ss-button" style={{ marginLeft: '10px', marginTop: '10px' }}>
                x
              </button>
            </div>
          ))}
          <button type="button" onClick={handleAddHeader} className="ss-button" style={{ marginTop: "10px" }}>Add Header</button>
        </div>

        {(httpMethod === 'POST' || httpMethod === 'PUT') && (
          <div style={{ marginTop: "20px" }}>
            <div className="create-form-label">Body (JSON format):</div>
            <textarea
              value={body}
              onChange={(e) => setBody(e.target.value)}
              placeholder='{"key": "value"}'
              style={{ width: '90%', height: '150px' }}
            />
          </div>
        )}

        <button  className="ss-button" style={{ marginTop: '20px' }} onClick={checkResponse}>Check API Response</button>

        {/* Display error messages */}
        {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}

        {/* Display API response */}
        <div style={{ marginTop: "20px" }}>
          <div className="create-form-label">API Response:</div>
          <textarea
            value={apiResponse}
            readOnly
            placeholder="API response will appear here..."
            style={{ width: '90%', height: '150px', backgroundColor: '#f5f5f5', padding: '10px' }}
          />
        </div>

        <button type='submit' className="ss-button" style={{ marginTop: '20px' }} >{editApiRef.current ? 'Update API' : 'Create API'} </button>
      </form>
    </div>
  );
};

export default ApiInputComponent;
