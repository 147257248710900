import React, {useState} from 'react';

function SupportEmailListDisplayHeader(props){

function createSupportEmail(){
	
}

return (
	<div class='header-body'>
	    <div class="main-header">
	    	<div style={{fontWeight:'bold',paddingLeft:'10px'}}>All Support Emails</div>     		          
	         <div class="header-right" ref={props.groupButtonsRef}>
	         	<button class='ss-button'  type="button"  onClick={() => props.performAction('delete')}>Delete</button>   
	         </div>
	         <button class='ss-button'  type="button" style={{marginTop: '2px'}} onClick={props.openChangeEmailWindow}>+ New Support Email</button>
	    </div>
	</div>
    );

}

export default SupportEmailListDisplayHeader;