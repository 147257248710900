import React from "react"
import {useRef, useState, setState, useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom"
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import {ToastContainer, toast } from 'react-toastify';
import CallRouteCondition from './CallRouteCondition'

function CreateCallRoute(props){
	const [conditions, setConditions] = useState([])
	const nameTextboxRef = useRef(null)
	const messageTextboxRef = useRef(null)
	const conditionsRef = useRef(null)
	const errorRef = useRef(null)
	const addRouteLink = useRef(null)
	const removeRouteLink = useRef(null)
	const navigate = useNavigate()
	const params = useParams();
	const SELECT_POS = 0
	const INPUT_POS = 1
	const DROP_DOWN_POS = 2;
	const SELECT_INPUT_POS = 3
	const SPECIFIC_AGENT = 'Agent'
	const SPECIFIC_TEAM = 'Team'
	const ALL_AGENTS = 'AllAgents'
	const ALL_AGENTS_SINGLE = 'All Agents One By One'
	const SPECIFIC_CONTACT = 'Contact'
	const SPECIFIC_COMPANY = 'Company'
	const CONTACT_GROUP = 'ContactGroup'
	const COMPANY_GROUP = 'CompanyGroup'
	const ANYBODY = 'Anybody'
	let editFilled = false
	let conditionsAdded = false

	useEffect(()=>{
		console.log("useEffect call in call_route ")
		removeRouteLink.current.style.display = 'none'
		errorRef.current.style.display = 'none'
		if(params.id !== undefined && !editFilled){
			editFilled = true			
			populateCreateForm()
		}		
		if(conditionsRef.current.children.length === 0 &&
			params.id === undefined && !conditionsAdded){
			conditionsAdded = true;
			addConditionsRef()
		}			
	},[])
	useEffect(()=>{
		if(addRouteLink.current)
			addRouteLink.current.scrollIntoView()
	},[conditions])

function populateCreateForm(){	   
	   axiosClient.getRequest(constants.CALL_URL+'call_route/'+params.id)
		.then(function (response) {
		  setConditions([])
		  nameTextboxRef.current.value = response.data.name
		  messageTextboxRef.current.value = (response.data.message === 'null' ? '' : response.data.message)
		  let temp = []
		  for(var i=0; i< response.data.payload.length; i++)
		  {
			temp.push(<CallRouteCondition index={i}
			data={response.data.payload[i]}/>)  	
		  }
		  setConditions(temp)
		  if(conditionsRef.current.children.length > 0)
			removeRouteLink.current.style.display = 'inline'
		}).catch(function (error) {
			console.log("Error in call route " + error)
		});		
}
	function addConditionsRef(event){
		if(event !== undefined)
			event.preventDefault()
		if(conditionsRef.current.children.length > 0){
			removeRouteLink.current.style.display = 'inline'
		} 
		setConditions([ ...conditions, <CallRouteCondition index={conditionsRef.current.children.length}/>])
		errorRef.current.scrollIntoView()
	}

//TODO add VoiceMail

function removeConditionsRef(){
 	conditionsRef.current.removeChild(conditionsRef.current.lastChild)
 	if(conditionsRef.current.children.length <= 1)
    	removeRouteLink.current.style.display = 'none'
 	if(conditionsRef.current.children.length < 5)
		addRouteLink.current.style.display = 'inline'
}	

function validateForm(event){
	console.log("validateForm selected values "+event.target.route.value)
	let fromErrors = {}
	let toErrors = {}
 	fromErrors[SPECIFIC_CONTACT] = 'Please search and select a value from contacts'
 	fromErrors[SPECIFIC_COMPANY] = 'Please search and select a value rom company'
 	fromErrors[COMPANY_GROUP] = 'Please search and select a value from company groups'
 	fromErrors[CONTACT_GROUP] = 'Please search and select a value from contact group'	
 	toErrors[SPECIFIC_AGENT] = 'Please select a <strong>To</strong> agent value'
 	toErrors[SPECIFIC_TEAM] = 'Please select a <strong>To/strong> team value'

	if(event.target.route.value === ''){
		displayError('Route name cannot be empty')
		return false;
	}
	console.log("ConditionsRef children length "+ conditionsRef.current.children.length+" ")
	for(var i = 0;i < conditionsRef.current.children.length; i++){
		console.log("Inside for loop "+event.target[('fromSelect-'+i)].value+" ")
		if(event.target[('fromSelect-'+i)].value.toLowerCase() !== ANYBODY.toLowerCase() &&
		  (event.target[('fromInput-'+i)].value === '' ||
		  (event.target[('fromInput-'+i)].value !== '' && event.target[('fromInput-'+i+'-hidden')].value === '-1'))){
		  	console.log('error '+event.target[('fromSelect-'+i)].value+' '+JSON.stringify(fromErrors)+' '+i)
			displayError(fromErrors[event.target[('fromSelect-'+i)].value])
			return	false;
		}
		console.log("Inside for loop toSelect"+event.target[('toSelect-'+i)].value+" "+event.target[('toInput-'+i)].value+" "+event.target[('toInput-'+i+'-hidden')].value)
		console.log("check "+ (event.target[('toSelect-'+i)].value.toLowerCase() !== ALL_AGENTS.toLowerCase()) )
		if((event.target[('toSelect-'+i)].value.toLowerCase() !== ALL_AGENTS.toLowerCase()) &&
		  (event.target[('toInput-'+i)].value === '' ||
		  (event.target[('toInput-'+i)].value !== '' && event.target[('toInput-'+i+'-hidden')].value === '-1'))){
			displayError(toErrors[event.target[('toSelect-'+i)].value])
			return	false;
		}
	}
	return true
}
function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()

}
function handleSubmit(event){
	event.preventDefault()
	errorRef.current.innerHTML = ''
	errorRef.current.style.display = 'none'
	if(!validateForm(event)){
		console.log("validateForm is wrong")
		return
	}

	console.log('name is '+event.target.route.value)
	let payload = {
		'name': event.target.route.value,
		'message' : messageTextboxRef.current.value,
		'conditions' : []
	}	
	for(var i=0;i< conditionsRef.current.children.length;i++){
		payload['conditions'].push({'fromSelection' : event.target[('fromSelect-'+i)].value,
									'fromValue' : event.target[('fromInput-'+i+'-hidden')].value,
									'toSelection' : event.target[('toSelect-'+i)].value,
									'toSelectionType' : event.target[('toSelectType-'+i)].value,
									'toValue': event.target[('toInput-'+i+'-hidden')].value
								})

	}
	console.log("payload "+JSON.stringify(payload))
	if(params.id === undefined){
		axiosClient.postRequest(constants.CALL_URL+'call_route', payload)
			.then(function (response) {
				navigate(constants.ADMIN_URL_ABS+'call_routes')
			}).catch(function (error) {
				console.log(error)
			    displayError(error.response.data.error)
			});
	}else{
			axiosClient.patchRequest(constants.CALL_URL+'call_route/'+params.id, payload)
					.then(function (response) {
					toast.success('Call Route Updated Successfully')
				}).catch(function (error) {
					if(error.response.data === undefined)
				    	displayError('Unknown error occurred. Please try again after sometime')
				    else
				    	displayError(error.response.data.error)

				});
		}
}
function redirectToCallRoutesList(){
	navigate(constants.ADMIN_URL_ABS+'call_routes')
}
function cancel(){
	navigate(constants.ADMIN_URL_ABS+'call_routes')
}
	return(
	<>
	 	<div class='header-body' style={{backgroundColor: 'white'}}>
	 		<a href='' style={{marginLeft:'20px', marginTop:'10px', textDecoration: 'none', fontSize:'16px'}} onClick={redirectToCallRoutesList}> > All Call Routes</a>
	 	</div>		
		<div class="main-body good-box-shadow create-call-route-flex" style={{top:'0px', height:'99vh',backgroundColor:'#FDFDFD'}}>
			<div class='container' style={{height:'calc(100% - 75px)', overflowY:'auto'}}>
				<div class="row">
					<div class='col-sm-12'>
						<form onSubmit= {handleSubmit} >
						  <div style={{ padding:'30px', paddingleft:'0px',height: 'calc(100vh - 105px)' ,minWidth:'700px'}} >			  	
							<h8 style={{color:'#044caf',fontWeight:'1000'}}>Configure Route For incoming Call</h8>	
							<div class='create-line'></div>			
							<div style={{marginTop:'20px',fontWeight:'600', fontSize:'16px',marginLeft:'10px', color:'black'}}>Route Name: </div>
							<div style={{marginLeft:'20px'}}><input ref={nameTextboxRef} name='route' required type='text' style={{height:'33px', border: '1px solid #aaa', borderRadius: '5px',width:'70%', fontSize:'12px',minWidth:'550px'}} placeholder={'Enter Call Route Name'} ref={nameTextboxRef}/></div>
							<div class='create-form-label' style={{ marginTop: "3%",color:'black',marginLeft:'10px',fontSize:'16px',fontWeight:'600'}}>Welcome Message: </div>
							<div style={{marginLeft:'20px'}}><input ref={messageTextboxRef} name='message' type='text' style={{height:'33px', border: '1px solid #aaa', borderRadius: '5px',width:'70%', fontSize:'12px',minWidth:'550px'}} placeholder={'Enter Welcome Message'} ref={messageTextboxRef}/></div>
							<div style={{ marginTop: "10px",color: 'black',fontSize:'16px',fontWeight:'600',marginTop:'20px', marginLeft:'10px'}}>Rules</div>				
							<div ref={conditionsRef}>						
								{conditions}					
							</div>
							<a href="javascript:void(0)" ref={addRouteLink} onClick={addConditionsRef} style={{float: 'left',marginLeft:'10%', fontWeight:'800',textDecoration:'none'}}>Add Rule</a>
				            <a href="javascript:void(0)" ref={removeRouteLink} onClick={removeConditionsRef} style={{marginLeft:'40%', fontWeight:'800',textDecoration:'none'}}>Remove Rule</a>
				            <div ref={errorRef} style={{marginTop: '50px' }} />
						  </div>	
							<div style={{ position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
								<button class='ss-button'  class='create-button' style={{marginLeft:'15%'}} onClick={cancel}>Cancel</button>
								<button class='ss-button'  class='create-button' style={{marginLeft:'15%'}}>{params.id !== undefined ? 'Update Route' : 'Create Route' }</button>
							</div>			  
						</form>	
					</div>
				</div>			
			</div>
			 <div style={{color:'black', fontWeight:'500', backgroundColor: 'white', marginRight: '5px', width: '20%',padding: '20px', fontSze:'14px', paddingTop:'100px'}} class='call-route-description'>
			 <div><p><strong>Welcome message</strong> is played to the caller when the call enters the route.</p></div>
			 <p>You can create rules to 
				handle incoming calls. Set rules to route calls from specific contacts, companies, contact groups, or company groups to designated agents or teams. Rules will execute in the listed order, starting from the top.
			 </p>
			</div>			
		  </div>
			<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>		  
	</>
	)


}

export default CreateCallRoute;