import React from "react"
import SideNav from './SideNav'
import Topnav from './Topnav/Topnav'
import {useState, setState, useRef, useEffect} from 'react';
import {ToastContainer, toast } from 'react-toastify';

function AccountCheck(){
	const toastRef = useRef(null)

	function hideToast(){
		if(toastRef.current)
			toastRef.current.style.display = 'none'
	}
	function logoff(){
		localStorage.removeItem('id_token')
		window.location.href='/login'
	}
	useEffect(()=>{
		toastRef.current.style.display = 'none'
		let session = JSON.parse(sessionStorage.getItem('ss_sess'))
		if(session === null || session === undefined)
			return
		
		if(session['redirectToLogin'] ){
			toastRef.current.innerHTML = session['redirectReason']
			if(!window.location.href.includes('/agents')){
				window.location.href='/agents'
			}
			toastRef.current.style.display = 'flex'
		}
		if(session['isAdmin']){
			if(!session['emailVerified']){
				toastRef.current.style.display = 'flex'
				toastRef.current.innerHTML = 'Email not verified. Please verify your email'
				toast.error('Email not verified. Please verify your email')	
				setTimeout(hideToast, 5000)
			}
		}
		if(session['isAdmin'] && session['accountStatus'] === 'SUSPENDED'){
			toastRef.current.innerHTML = 'Account Suspended. Please Renew Your Subscription'
			if(!window.location.href.includes('/admin/subscriptions'))
				window.location.href='/admin/subscriptions'
			toastRef.current.style.display = 'flex'		

		}else if(session['Agent'] && session['accountStatus'] === 'SUSPENDED'){
			toastRef.current.innerHTML = 'Account Suspended. Please contact Your Administrator'
			setTimeout(logoff, 5000)
		}
	});
	return (
		<>	
			<div class='toaster' ref={toastRef}/>
			<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>
    	</>
	)
}

export default AccountCheck;