
import React from "react"
import {useState, setState, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import DashboardDisplayHeader from './js/DashboardDisplayHeader'
import TicketDashboard from './js/TicketDashboard/TicketDashboard'
import CallDashboard from './js/CallDashboard/CallDashboard'
import '../../../css/main.css'

function Dashboard(){
 const [dashboardType, setDashboardType] = useState('Tickets')

function renderDashboards(type){
	if(dashboardType === 'Tickets')
		return <TicketDashboard />
	else if (dashboardType === 'Calls')
		return <CallDashboard />
}
function selectFilter(type){
	setDashboardType(type)	
	console.log('selectFilter '+type)
}

	return(
	 <>	
	 	<div class='header-body'>
	 	 	<DashboardDisplayHeader selectFilter={selectFilter}/>
	 	</div>
		<div class="main-body" style={{backgroundColor: 'white'}}>			
			{dashboardType === 'Tickets' ? <TicketDashboard /> : <CallDashboard /> }			
		</div>
	 </>
		)
}


export default Dashboard;