import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate, Redirect} from "react-router-dom"
import { Modal, Button, Form } from 'react-bootstrap';

function RadioButton(props){
	const menuRef = useRef(null)
    const [show,setShow] = useState(false)
    const [name, setName] = useState('Radio Buttons')
    const[childCount, setChildCount] = useState(2)
    const [childArr, setChildArr] = useState([1,2])
    useState(()=>{
          
    },[])
    
    let payload={'type':'radio',
                 'name':'Radio Buttons',
                 }
    function displayMenu(){
        console.log('displayMenu')
        menuRef.current.style.display='block'
    }
    function hideMenu(){
        console.log('hideMenu')
        menuRef.current.style.display='none'
    }
    function handleSubmit(){}
    function handleClose(){setShow(false)}
    function fetchRadioElement(pos){
        console.log('fetchRadioElement')
        return <div class="form-check">
                <input class="form-check-input" type="radio" name={name+'radio'+props.index} id={name+'radio-'+props.index} value="option1" checked style={{height:'15px',width:'15px'}}/>
                <label class="form-check-label" for={name+'radio-'+props.index}>
                    Option 1
                </label>
            </div>
    }
    function displayModal(){
        // if(props.payload[props.index] === undefined){
        //     props.payload[props.index] = {}
        // }
        // props.payload[props.index]['name']=name
        // props.payload[props.index]['childCount']=childCount   
        setShow(true)     
    }
    return(
       <>
       <div class="form-group" onMouseOver={displayMenu} onMouseLeave={hideMenu} style={{position:'relative'}}>
            <div class="icons" style={{display:'block',transition:'opacity 0.3s',position:'absolute',top:'10px',right:'10px'}} ref={menuRef}>
                <a class="fas fa-edit icon" title="Edit" onClick={()=> props.displayRBEditWindow(props.index)}>edit</a>
                <a class="fas fa-trash-alt icon" title="Delete" onClick={()=>props.deleteIndex(props.index)}>delete</a>
            </div> 
		 <div class="form-group" onMouseOver={displayMenu} onMouseLeave={hideMenu} style={{position:'relative'}}>
             <div class="icons" style={{display:'block',transition:'opacity 0.3s',position:'absolute',top:'10px',right:'10px'}} ref={menuRef}>
                <a class="fas fa-edit icon" title="Edit" onClick={displayModal}>edit</a>
                <a class="fas fa-trash-alt icon" title="Delete">delete</a>
            </div>
            <label class='create-form-label'>{name}</label>
            {childArr.map(index => fetchRadioElement(index))}                        
        </div>
      </div>
      
     </>
		)

}

export default RadioButton