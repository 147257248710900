import React, { useState, useEffect, useRef } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
import { usePermissions } from '../../PermissionsProvider';

function CompanyGroupLine(props){
	const { isActionAllowed, isAgent } = usePermissions();
	const navigate = useNavigate();
 	const [sessionData, setSessionData] = useState(null)
  	useEffect(()=>{
    	setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
	},[]) 

function editCompanyGroup(){
	if(!checkEditPermission())
		return
	props.editCompanyGroup(props.company_group.id)
}

function checkEditPermission(){
	if(isActionAllowed('EDIT_COMPANY_GROUP') || isAgent())
		return true;
	return false
}
function checkBoxClicked(event){
	props.checkBoxClicked(props.company_group.id, event.target.checked)
}
return (
	<tr style={{borderBottom:'1px solid rgb(235, 237, 240)',fontFamily:"Lato,Roboto,Helvetica,BogleWeb,MyriadPro,Helvetica Neue,Helvetica,Arial,sans-serif"}}>
	    { (sessionData !== null && sessionData.isAdmin) ?
	     <th scope="row" class="ps-4">
             <div class="form-check font-size-16"><input type="checkbox" class="form-check-input company-group-line-checkbox" onClick={checkBoxClicked} name={'checkbox-'+props.company_group.id} style={{border:'1px solid #A9A9A9'}}/></div>
          </th>
	     : '' }
	    <td style = {{width: "30%", textDecoration: 'none', cursor: 'pointer',fontWeight:'800',fonFamily:'Helvetica'}} onClick = {editCompanyGroup}>{props.company_group.name}</td>
	    <td style = {{width: "60%"}}>{props.company_group.count}</td>
	 </tr>
  );
}

export default CompanyGroupLine;