
import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../../../js/Axios';
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';

function TicketsStatusChart(props){
	const chartRef = useRef(null)
	const [data, setData] = useState(null)
	const labels = ['Low', 'Medium','High','Critical', 'Escalated']
  	var load = true;
 	
	useEffect(() => {
        Chart.register(ChartDataLabels);
		if(load){
			load=false
			loadCanvas()
		}
		
	})

function getCreatedData(){
    if(props.data.countStats === undefined  )
        return [0,0,0,0,0]
    return [props.data.countStats[4],
            props.data.countStats[5],
            props.data.countStats[6],
            props.data.countStats[7],
            props.data.countStats[8]
           ]
}
function getResolvedData(){
    if(props.data.countStats === undefined  )
        return [0,0,0,0,0]
    return [props.data.countStats[12],
            props.data.countStats[13],
            props.data.countStats[14],
            props.data.countStats[15],
            props.data.countStats[16]
           ]
}
function loadCanvas(){
 if(chartRef.current !== null)
 	chartRef.current.destroy();
 var myContext = document.getElementById( 
            "tickets_by_status").getContext('2d'); 
        chartRef.current = new Chart(myContext, { 
            type: 'bar', 
            data: { 
                labels: ["Open", "InProgress", "Blocked",  
                    "Need Information", "Closed"], 
                datasets: [{ 
                    label: 'Created', 
                    backgroundColor: ["#1c4e80","#d32d41","#0091d5","#488A99","#7e909A"], 
                    data: getCreatedData(), 
                }], 
            }, 
            options: {
                plugins:[ChartDataLabels],
                plugins: { 
                    title: { 
                        display: true, 
                        text: 'Ticket count by Status' 
                    },
                    datalabels: {
                        color: 'white',
                        font: {
                          weight: 'bold',
                          size: 12,
                        }
                    } 
                }
            } 
        });  
}

	return(<div class='dashboard-count' style={{width: '100%'}}>
			<div ><canvas id="tickets_by_status"/></div>	
		  </div>)
}

export default TicketsStatusChart;