import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import SupportEmailLine from "./SupportEmailLine"
import SupportEmailListDisplayHeader from './SupportEmailListDisplayHeader'
import CreateSupportEmail from './CreateSupportEmail'
import {ToastContainer, toast } from 'react-toastify';
import ChangeSupportEmail from './ChangeSupportEmail'


function SupportEmailsListDisplay(){
	const primaryCheckBoxRef = useRef(null)
	const groupButtonsRef = useRef(null)
	const [ids, setIds] = useState([])
	const [supportEmailLine, setSupportEmailLine] = useState("No Records Found...")
	const [checkboxState, setCheckboxState] = useState(false)
	const [showEmailChangeWindow ,setShowEmailChangeWindow] = useState(false)
	const supportEmailIdRef = useRef(-1)
	useEffect(() => {
		groupButtonsRef.current.style.visibility = 'hidden'	
		loadSupportEmails()
	},[])//use effect

	function loadSupportEmails(){
		axiosClient.getRequest(constants.ACCOUNT_URL+'support_email')
		.then(function (response) {
		let supportEmailList = response.data.map((element, index) => (				
						<SupportEmailLine supportEmail= {element} checkBoxClicked={checkBoxClicked} openChangeEmailWindow={openChangeEmailWindow}/>
		));
		setSupportEmailLine(supportEmailList) 
		}).catch(function (response) {
		    console.log(response);
		});		
	}

	function createSupportEmailGroup(){
		let supportEmailsChecked = []
		let elements = document.getElementsByClassName('supportEmail-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				supportEmailsChecked.push(elements[i].id)
		
		}

		axiosClient.getRequest(constants.CONTACT_URL)
		.then(function (response) {
		let supportEmailList = response.data.map((element, index) => (
						<SupportEmailLine supportEmail= {element} checkBoxClicked={checkBoxClicked} openChangeEmailWindow={openChangeEmailWindow}/>					           
		));
		setSupportEmailLine(supportEmailList) 
		}).catch(function (response) {
		    console.log(response);
		});		
	}

function searchSupportEmails(event){
	axiosClient.getRequest(constants.ACCOUNT_URL+"support_email/search?keyword="+event.target.value)
		.then(function (response) {
		let supportEmailList = response.data.map((element, index) => (					
						<SupportEmailLine supportEmail= {element} checkBoxClicked={checkBoxClicked} openChangeEmailWindow={openChangeEmailWindow}/>					           
		));
		setSupportEmailLine(supportEmailList) 
		}).catch(function (response) {
		    console.log(response);
		});
}

function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('support-email-line-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked && !idsTemp.includes(id)){
			idsTemp.push(id)
			groupButtonsRef.current.style.visibility = 'visible'				
		}
	}
	if(!event.target.checked){	
		groupButtonsRef.current.style.visibility = 'hidden'
		idsTemp.splice(0,idsTemp.length)
	}
	setIds(idsTemp)

}

function checkBoxClicked(id, value){
	let idsTemp = ids;
	if(value && !idsTemp.includes(id)){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.style.visibility = 'visible'
	}else{
		groupButtonsRef.current.style.visibility = 'hidden'
	}

}

function handleSubmit(event){
  event.preventDefault()

}

function closeChangeEmailWindow(load){
	setShowEmailChangeWindow(false)
	if(load)
		loadSupportEmails()
	supportEmailIdRef.current = -1
}
function openChangeEmailWindow(event, id){
	event.preventDefault()
	supportEmailIdRef.current = id
	setShowEmailChangeWindow(true)
}
function performAction(type){	
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.ACCOUNT_URL+'support_email/delete/'
	

//TODO: block becomes unblock
   	axiosClient.postRequest(url, payload)
       .then(function (response) {
       		loadSupportEmails()
			let elements = document.getElementsByClassName('support-email-line-checkbox')
			for (var i = 0; i < elements.length; i++) {
				if(elements[i].checked)
					elements[i].checked = false;		
			}
			toast.success('Support Email Deleted Successfully') 
			setIds([])     		
       }).catch((error) => {
           console.log(error)
       }); 
       groupButtonsRef.current.style.visibility = 'hidden'  
}

	return (
	<>
		<SupportEmailListDisplayHeader openChangeEmailWindow = {openChangeEmailWindow}
									groupButtonsRef={groupButtonsRef}
									performAction={performAction}/>
			<div class="main-body">
				<div class="container" style={{minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
		         <div class="row">
		            <div class="col-lg-12">
		               <div class>
		                  <div class="table-responsive">
		                     <table class="table table-nowrap align-middle table-borderless">
		                        <thead>
		                           <tr>
		                              <th scope="col" class="ps-4" style={{backgroundColor:'#f4f4f4'}}>		                                 
		                              </th>
		                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Name</th>
		                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Support Email</th>
		                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>From Email</th>
		                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Team</th>
		                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Active</th>
		                           </tr>
		                        </thead>
		                        <tbody>
		                        	{supportEmailLine}                    
		                        </tbody>
		                     </table>
		                  </div>
		               </div>
		            </div>
		         </div>         
		      </div>
			</div>
			<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>						
			{showEmailChangeWindow ? 									
				<ChangeSupportEmail 
							closeChangeEmailWindow={closeChangeEmailWindow} supportEmailIdRef={supportEmailIdRef}/>									
			 : ''
			}                
	</>
 );

}
export default SupportEmailsListDisplay;
