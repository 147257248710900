import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import {useNavigate} from "react-router-dom"
import Attachment from './Attachment'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/ticket.css'
import DynamicForm from '../../CustomFields/js/DynamicForm'

function CreateTicketForm(props){
  const [formData, setFormData] = useState({  });
  const [customFields, setCustomFields] = useState([]) 
  const errorRef =  useRef(null)
  const reporterRef = useRef(null)
  const assigneeRef = useRef(null)
  const teamRef = useRef(null)
  const descriptionRef = useRef(null)
  const addContactFields = useRef(null)
  const spinnerRef = useRef(null)
  const addContactFieldsLink = useRef(null)
  const [reporterId, setReporterId] = useState(-1)
  const [assigneeId, setAssigneeId] = useState(-1)
  const [teamId, setTeamId] = useState(-1)
  const navigate = useNavigate();
  const REPORTER_DROP_DOWN_INPUT_POS = 0;
  const REPORTER_DROP_DOWN_MENU_POS = 1;
  const REPORTER_DROP_DOWN_HIDDEN_INPUT_POS = 2;
  const [attachmentFilesList, setAttachmentFilesList] = useState([])

useEffect(()=>{
  spinnerRef.current.style.display = 'none'
},[])

useEffect(() => { 
        axiosClient.getRequest(constants.CUSTOM_FIELDS+"?name=Tickets")
        .then(function (response) {
            setCustomFields(response.data)
              let formDataTemp =   response.data.map((field) => ({
                    id: field.id,
                    name: field.name,
                    formValues: field.type === 'checkbox-group' ? '' : field.type === 'checkbox' ? false : '',
                    type: field.type,
                    apLabel: field.apLabel,
                    cpLabel:field.cpLabel,
                    values:field.values
                  }))
               setFormData(formDataTemp) 
        }).catch(function (response) {
            console.log(response);
        });
  },[])


function formDataToJson(formData) {
  console.log('formDataToJson  '+JSON.stringify(formData))
    const obj=[]
    formData.forEach((value, key) => {
        obj.push(value)      
    });
    return JSON.stringify(obj);
}
function handleSubmit(event){
  event.preventDefault();
  console.log('handleSubmit '+(reporterId === -1)+'  '+(event.target.mobileNumber.value === ""))
  errorRef.current.innerHTML = null
  errorRef.current.style.display = 'none'   
  let reporterContact = {}
  let assignee={}
  let team = {}
  let company = {}
  

  let payload = {
      'summary': event.target.summary.value,
      'description': descriptionRef.current.innerHTML,
      'priority': event.target.priority.value,
      'status' :event.target.status.value,
      'type': event.target.type.value,
      customFields: formDataToJson(formData)
   }
    if(event.target.fullName.value === "" && event.target.email.value === "" &&
         event.target.mobileNumber.value === "" && reporterId === -1){
      displayError('Reporter details are mandatory')
      return
    }
    if(reporterId !== -1){
      reporterContact['id'] = reporterId
      addContactFields.current.style.display = 'none'
    }
    else{
      if(event.target.fullName.value !== "" || event.target.email.value !== "" ||
         event.target.mobileNumber.value !== ""){
         if(event.target.fullName.value == ""){
          addContactFields.current.style.display = 'block'
          displayError("Contact's name is mandatory")         
          return;          
         }
         if(event.target.email.value == '' ){
            addContactFields.current.style.display = 'block' 
            displayError("Contact's email mandatory")
           return;          
         } 
      }
       reporterContact['fullName'] = event.target.fullName.value;
       reporterContact['email'] = event.target.email.value;
       reporterContact['mobileNumber'] = event.target.mobileNumber.value;
    }

    payload['reporterContact'] = reporterContact
    if(assigneeId !== -1){
      assignee['id'] = assigneeId;
      payload['assignee'] = assignee;
    }
    if(teamId !== -1){
      team['id'] = teamId;
      payload['team'] = team;
    }
    const filesMap = {}
    let files = []
    let sizes = []
    let fileTypes = []
    attachmentFilesList.forEach(file=>{
      files.push(file.name);
      sizes.push(file.size);
      fileTypes.push(file.type)
      filesMap[file.name] = file
    });   
    payload['files'] = files;
    payload['sizes'] = sizes;
    payload['types'] = fileTypes;
    spinnerRef.current.style.display = 'inline-block'

    event.target.disabled=true
    console.log('payload is '+JSON.stringify(payload))
    axiosClient.postRequest(constants.TICKET_URL, payload)
    .then(function (response) {        
        let attachmentList = response.data.attachments
        if(attachmentList !== null){
          let promises = [];
          for (var i = 0; i < response.data.attachments.length; i++) {
            promises.push(
              axiosClient.putExternalRequest(response.data.attachments[i].link,filesMap[response.data.attachments[i].name],{headers: {
                'Content-Type': 'multipart/form-data',
                'Content-Length': filesMap[response.data.attachments[i].name].size
              }}).then(response => {
                
              }))
            }
          Promise.all(promises).then(() => {
            spinnerRef.current.style.display = 'none'
            navigate(constants.TICKET_URL_ABS+response.data.id)
          }).catch((error) =>{
            spinnerRef.current.style.display = 'none'
            displayError('Error uploading attachment')
            setTimeout(navigate(constants.TICKET_URL_ABS+response.data.id), 5000)
          });
        }else{
          spinnerRef.current.style.display = 'none'
          navigate(constants.TICKET_URL_ABS+response.data.id)
        }        
    }).catch((error) => {
      console.log(error)
      displayError(error.response.data.error)
      spinnerRef.current.style.display = 'none'
      event.target.disabled=false
    });
    
}

  function onSelectDropdown(event, type,id,name){
      console.log('onSelectDropdown ')
      if(type === 'reporter'){
        reporterRef.current.children[REPORTER_DROP_DOWN_INPUT_POS].value = name
        reporterRef.current.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'none'
        reporterRef.current.children[REPORTER_DROP_DOWN_HIDDEN_INPUT_POS].value= id
        setReporterId(id)
      }else if(type === 'assignee'){
        assigneeRef.current.children[REPORTER_DROP_DOWN_INPUT_POS].value = name
        assigneeRef.current.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'none'
        assigneeRef.current.children[REPORTER_DROP_DOWN_HIDDEN_INPUT_POS].value= id
        setAssigneeId(id)
      }else if(type === 'team'){
        teamRef.current.children[REPORTER_DROP_DOWN_INPUT_POS].value = name
        teamRef.current.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'none'
        teamRef.current.children[REPORTER_DROP_DOWN_HIDDEN_INPUT_POS].value= id
        setTeamId(id)        
      }
  }

  function addChildToDropdown(element, event, type){
      let aTag = document.createElement('a')
      aTag.name=element.id
      // aTag.innerHTML = element.name //+';'+element.email 
      let outerDiv = document.createElement('div')
      let innerLeftDiv = document.createElement('div')
      let innerRightDiv = document.createElement('div')
      innerLeftDiv.innerHTML = element.name
      if(type === 'team')
        innerRightDiv.innerHTML=''
      else
        innerRightDiv.innerHTML=element.email
      innerLeftDiv.style.float='left'
      innerLeftDiv.classList.add('add-ellipsis')
      innerRightDiv.style.float='right'
      outerDiv.appendChild(innerRightDiv)
      outerDiv.appendChild(innerLeftDiv)
      aTag.appendChild(outerDiv)
      // aTag.onclick = onSelectDropdown()
      aTag.addEventListener('click', function(event){
          onSelectDropdown(event,type,element.id,element.name);
      });
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].appendChild(aTag)
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'block'
  }

  function handleReporter(event){
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'none'
      event.target.parentElement.children[REPORTER_DROP_DOWN_HIDDEN_INPUT_POS].value = -1;
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].innerHTML = ''
      if(event.target.value.length < 3)
        return      
      axiosClient.getRequest(constants.CONTACT_URL+"search_by_name?keyword="+event.target.value)
      .then(function (response) {
        if(response.data === ''){
          return
        }          
          response.data.forEach((element, index)=>{             
              addChildToDropdown(element,event, "reporter")
          })
      }).catch(function (response) {
          console.log(response);
      });
  }

function handleAssignee(event){
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].innerHTML = ''
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'none'
      event.target.parentElement.children[REPORTER_DROP_DOWN_HIDDEN_INPUT_POS].value = -2;
      if(event.target.value.length < 3)
        return
      axiosClient.getRequest(constants.USER_URL+"search_by_name?keyword="+event.target.value)
      .then(function (response) {
          response.data.forEach((element, index)=>{             
              addChildToDropdown(element,event, "assignee")
          })
      }).catch(function (response) {
          console.log(response);
      });

}

function addNewContactFileds(){
  addContactFields.current.style.display = 'inline'
}
function removeNewContactFileds(){

}

function handleTeam(event){
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].innerHTML = ''
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'block'
      event.target.parentElement.children[REPORTER_DROP_DOWN_HIDDEN_INPUT_POS].value = -2;
      if(event.target.value.length < 3)
        return
      axiosClient.getRequest(constants.TEAM_URL+"search_by_name?keyword="+event.target.value)
      .then(function (response) {
          response.data.forEach((element, index)=>{             
              addChildToDropdown(element,event, "team")
          })
      }).catch(function (response) {
          console.log(response);
      });
}
function removeAttachmentFromEdit(id){
    let attachmentFilesListArr = [...attachmentFilesList]
    attachmentFilesListArr.splice(id, 1);
    setAttachmentFilesList(attachmentFilesListArr)
}
function handleAttachment(event){
  console.log('handleAttachment '+attachmentFilesList.length)
    errorRef.current.innerHTML = ''
    event.preventDefault();
    if(Object.keys(event.target.files).length === 0){
      return
    }
    console.log('size is '+event.target.files[0].size)
    if( event.target.files[0].size > (1000 *1000 *1000))
    {
        errorRef.current.innerHTML = 'Maximum file size is 1GB'
        return;
    }
    let div = document.createElement('div')
    setAttachmentFilesList([...attachmentFilesList,event.target.files[0]])
}
function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}
return (
    <div class="ticket-create-form" style={{backgroundColor:'#F2F1F0'}}>
      <div ref={errorRef} style={{margin: '5px',fontWeight:'600' }}/>
      <form onSubmit={handleSubmit} >
       <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 75px)', overflowY: 'scroll'}}>
        <a href="javascript:void(0)" class="create-ticket-closebtn" onClick={props.closeCreateWindow}>&times;</a>
        <div style={{color:'#044caf',fontWeight:'800', fontSize:'20px' }}>+ Create Ticket </div>
        <div class='create-ticket-line'></div>
        <div class='create-ticket-form-label' style={{ marginTop: "3%"}}>Summary:<p class='red-color'>*</p></div>
        <div><input type="text" name="summary" style={{width: "90%" , border: '2px solid #aaa'}} required maxlength="1000"/></div>
        <div class='create-ticket-form-label' style={{ marginTop: "3%"}}>Reporter:<p class='red-color'>*</p></div>
        <div style={{position:'relative' }} ref={reporterRef}>
            <input type="text"  style={{width: "90%", border: '2px solid #aaa'}} onChange={handleReporter} name="reporter"/>
            <div style={{display: 'none', position: 'absolute'}} class='create-ticket-dropdown-content'/>
            <input style= {{display: 'none'}} name = 'reporter-hidden-input' value='-1'/>
        </div>
        <div><a href="javascript:void(0)" ref={addContactFieldsLink} onClick={addNewContactFileds} class="ticket-create-form-field">Add new Contact</a></div>
        <div ref={addContactFields} style={{ display: 'none'}}>
          <div class='create-ticket-form-label'>Reporter's Full Name: </div>
          <div><input type="text"  name = "fullName" class='contact-field' style={{width: "87%", marginLeft: '3%', border: '2px solid #aaa'}}/></div>
          <div class='create-ticket-form-label'>Reporter's Email<p class='red-color'>*</p>: </div>
          <div><input type="text"  name = "email" class='contact-field' style={{width: "87%", marginLeft: '3%' , border: '2px solid #aaa'}}/></div>
          <div class='create-ticket-form-label'>Reporter's Mobile: </div>
          <div><input type='text'  name= 'mobileNumber' class='contact-field' style={{width: "87%", marginLeft: '3%', marginBottom:'3%' , border: '2px solid #aaa'}}/></div>
        </div>
        <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Description:</div>
        <div style={{border: '2px solid #aaa', width:'90%', borderRadius:'10px'}}>
        <div contenteditable='true' rows = '8'  placeholder="Type a message" name='description' id='editor' style={{borderRadius:'10px', border:'none',borderBottom:'2px solid #aaa',borderBottom:'1px solid black', fontWeight:'400',height:'400px',padding:'24px',fontSize:'17px',lineHeight:'1.5' }} ref={descriptionRef}></div>
        <div style={{minHeight:'50px'}}>
              <div style={{display: 'flex',flexDirection:'row', flexWrap: 'wrap',width:'90%'}}>
                  {attachmentFilesList.map((attachment,index) => 
                              <Attachment name={attachment.name} 
                                  isEditing={true}
                                  index = {index}
                                  size={attachmentFilesList[index].size}
                                  type={attachmentFilesList[index].type}
                                  removeAttachmentFromEdit={removeAttachmentFromEdit}/>
                  )}            
              </div>
            <label for="file-input" style={{marginTop:'10px'}}>           
              <img class="chatbox-attachment-img" src={require('../../../img/attachment.png')}/>
            </label>
            <input id="file-input" type="file" style = {{display: "none" }} onChange={handleAttachment}/>
        </div>
      </div>        
      <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Team:</div>
      <div style={{position:'relative' }} ref={teamRef}>
        <input type="text"  style={{width: "90%", border: '2px solid #aaa'}} onChange={handleTeam} name="team"/>
        <div style={{display: 'none', position: 'absolute'}} class='create-ticket-dropdown-content'/>
        <input style= {{display: 'none'}} name = 'team-hidden-input' />
      </div>
        <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Assignee:</div>
          <div style={{position:'relative' }} ref={assigneeRef}>
            <input type="text"  style={{width: "90%"}} onChange={handleAssignee} name="reporter"/>
            <div style={{display: 'none', position: 'absolute'}} class='create-ticket-dropdown-content'/>
            <input style= {{display: 'none'}} name = 'assignee-hidden-input' />
        </div>
        <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Priority: </div>
        <div><select name="priority" style={{width: '90%',padding: '5px'}}>
              <option value="Low">Low</option>      
              <option value="Medium">Medium</option>
              <option value="High">High</option>
              <option value="Critical">Critical</option>
              <option value="Escalated">Escalated</option>
          </select>        
        </div>
        <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Status:</div>
        <div><select name="status" style={{width: '90%',padding: '5px'}}>
              <option value="Open">Open</option>      
              <option value="Closed">Closed</option>
              <option value="InProgress">InProgress</option>
              <option value="Blocked">Blocked</option>
              <option value="NeedInfo">Need Information</option>
            </select>          
        </div>
        <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Type:</div>
          <div><select name="type" style={{width: '90%',padding: '5px'}}>
              <option value="Incident">Incident</option>      
              <option value="Service Request">Service Request</option>
              <option value="Change Request">Change Request</option>
              <option value="Payments">Payments</option>
              <option value="Escalation">Escalation</option>
          </select>
        </div>  
        <DynamicForm customFields={customFields} setFormData={setFormData} formData={formData}/>      
      </div>
      <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%', display:'table', margin:'auto',backgroundColor:'white'}}>
        <button class='ss-button'  style={{ marginTop: "3%",marginLeft:'30%'}} class='create-button'>Create Ticket </button>
        <span class="spinner-border" role="status" style={{position:'relative',top:'10px',right:'75px', color: 'black', height:'25px', width:'25px',zIndex:'100'}} ref={spinnerRef}/>
      </div>
    </form>
      
    </div>
    );
}
export default CreateTicketForm;