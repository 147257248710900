import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from "../../../js/Axios"
import * as constants from '../../../js/constants'
import {useNavigate} from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import { useParams} from "react-router-dom"

function CreateChatBotWindow(props){  
  
  const nameRef = useRef(null)
  const descriptionRef = useRef(null)
  const errorRef = useRef(null)
  const spinnerRef = useRef(null)
  const params = useParams();

  useEffect(()=>{
    if(isEdit){
      nameRef.current.value=props.name
      descriptionRef.current.value=props.description
    }
  })
  function isEdit(){
    return (params.id !== undefined)
  }
  function handleSubmit(e){
    console.log('handleSubmit '+ e.target.name.value)
  	e.preventDefault()
  	props.createBot(e.target.name.value, e.target.description.value)
  }

  return (
    <div class='create-wrapper'>
      <form onSubmit={handleSubmit}>          
          <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
              <a href="javascript:void(0)" class="create-closebtn" onClick={()=>props.setCreate(false)}>&times;</a>
              <div ref={errorRef} name="error"/>
              <h8 style={{color:'#044caf',fontWeight: '800', fontSize:'20px'}}><b>+ Bot</b> </h8>
              <div class='create-line'></div>
              <div class='create-form-label' style={{ marginTop: "3%"}}>Bot Name:<p class='red-color'>*</p></div>
              <div><input type="text" name="name" style={{width: "90%"}}  placeholder={'Name'} ref={nameRef} required /></div>
              <div class='create-form-label' style={{ marginTop: "3%"}}>Description</div>
              <textarea style={{width: "90%", height:'100px'}}  placeholder={'Description'} ref={descriptionRef}   rows='4' name='description'/>
          </div>    
          <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
            <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}}>{isEdit() ? 'Update Bot' : 'Create Bot'}</button>
            <span class="spinner-border" role="status" style={{position:'relative',top:'10px',right:'75px', color: 'black', height:'25px', width:'25px',zIndex:'100'}} ref={spinnerRef}/>
          </div>          
        </form>
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>
    </div>

    );
}

export default CreateChatBotWindow;
