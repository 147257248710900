import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate, Redirect} from "react-router-dom"
import { Modal, Button, Form } from 'react-bootstrap';

function CheckBox(props){
	const [show,setShow] = useState(false)
	const menuRef = useRef(null)
	const groupRef = useRef(null)
	const [checkboxes, setChecboxes] = useState(null)
	useEffect(()=>{
		let checkboxList = props.payload.names.map((element, index) => (
					<div>
	    				<input class="form-check-input create-form-label" type="checkbox" value={element} style={{height:'15px',width:'15px'}}/>
	    				<label class="form-check-label" for="inlineCheckbox1">{element}</label>
	    			</div>
	    		))
	    setChecboxes(checkboxList)
	},[])
	function displayMenu(){
        menuRef.current.style.display='block'
    }
    function hideMenu(){
        menuRef.current.style.display='none'
    }
    function displayEditFnction(){
    	console.log('displayEditFnction')
    	props.displayCBEditWindow(props.index)
    }	


	return(
		<>
		<div class="form-group" onMouseOver={displayMenu} onMouseLeave={hideMenu} style={{position:'relative'}}>
            <div class="icons" style={{display:'block',transition:'opacity 0.3s',position:'absolute',top:'10px',right:'10px'}} ref={menuRef}>
                <a class="fas fa-edit icon" title="Edit" onClick = {displayEditFnction}>edit</a>
                <a class="fas fa-trash-alt icon" title="Delete" onClick={()=>props.deleteIndex(props.index)}>   delete</a>
            </div> 
			<div class="form-group">
	            <label class='create-form-label'>{props.payload['agentPortalName']} </label>
				 <div class="form-check ">
				 	{checkboxes}
				</div>
			</div>
		</div>		
		</>
		)

}

export default CheckBox