import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../../js/constants'

function DashboardDisplayHeader(props){

const navigate = useNavigate(); 
const [filterState, setFilterState] = useState('Tickets')
function selectFilter(type){
  setFilterState(type)
  props.selectFilter(type)
}

function displayMenu(){
  document.getElementById('header-menu').style.display='block'
}
function hideMenu(){
  document.getElementById('header-menu').style.display='none'
}
return (
	    <div class="main-header" style={{marginTop: '2px'}}>
      {/*
		    <div class="header-dropdown"  onMouseLeave = {hideMenu} onMouseEnter={displayMenu}>
            <div id="header-menu" style={{display: "none"}} >
               <div><a class="dropdown-item-menu" href="#" onClick={()=>selectFilter('Tickets')} >Tickets</a></div>
               <div><a class="dropdown-item-menu" href="#" onClick={()=>selectFilter('Calls')} >Calls</a></div>               
            </div> 
            <span style={{fontWeight: 'bolder',fontSize:'18px' }}>{filterState}</span>           
		    </div>
      */}
	    </div>
    );
}

export default DashboardDisplayHeader;