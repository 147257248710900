import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate, Redirect} from "react-router-dom"

function DateField(props){
    const menuRef = useRef(null)
	function displayMenu(){
        menuRef.current.style.display='block'
    }
    function hideMenu(){
        menuRef.current.style.display='none'
    }
	return(
	<>
	  <div class="form-group" onMouseOver={displayMenu} onMouseLeave={hideMenu} style={{position:'relative'}}>
            <div class="icons" style={{display:'block',transition:'opacity 0.3s',position:'absolute',top:'10px',right:'10px'}} ref={menuRef}>
                <a class="fas fa-edit icon" title="Edit" onClick={()=> props.displayCBEditWindow(props.index)}>edit</a>
                <a class="fas fa-trash-alt icon" title="Delete" onClick={()=>props.deleteIndex(props.index)}>delete</a>
            </div> 
		 <div class="form-group">
            <label class='create-form-label'>{props.payload.agentPortalName}</label>
            <input type="date" class="form-control" id="exampleDate"/>
        </div>
      </div>
    </>
		)

}

export default DateField