  import React from "react"
  import {useState, setState, useRef, useEffect} from 'react';
  import {useNavigate} from "react-router-dom"
  import * as axiosClient from '../../../js/Axios';
  import * as constants from '../../../js/constants'
  import '../../../css/main.css'
  import {over} from 'stompjs';
  
  import { io } from 'socket.io-client';
  const voice = require('@twilio/voice-sdk')

  function ChatCustomerMessage(props){
      const chatBodyRef = useRef(null)
      const inputTextRef = useRef(null)
      const clientRef = useRef(null)      

      return(
             <div class="chat-header-row ChatMyMessage">     
                 <div class="chat-header-info">
                   <div class="main-header">
                     <p>{props.text}</p>       
                   </div>
                 </div>
                 <div className={'chat-header-img'}>
                   <img src={require('../../../img/test.png')} alt=''/>
                 </div>
               </div>     
          )

  }

  export default ChatCustomerMessage;