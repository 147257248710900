import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
import { usePermissions } from '../../PermissionsProvider';

function SLAListDisplayHeader(props){

	const navigate = useNavigate(); 
	const { isActionAllowed } = usePermissions();
	function createNewSLA(){
		navigate(constants.SLA_URL_ABS+'create')
	}

return (
	<div class='header-body'>
	    <div class="main-header">
	    	<div class="header-left" style={{fontWeight:'bold',paddingLeft:'10px' }}>All SLAs</div>
     		     <div class="search-wrapper">
   					    {<img src={require('../../../img/search.png')} alt=''/>}
   					    <input placeholder="search here" class="search-input" onChange={props.searchSLAs}/>
             </div>
             <div class="header-right" ref={props.groupButtonsRef}>
	            {isActionAllowed('DELETE_SLA') && (  <button class='ss-button'  onClick={() => props.performAction('delete')}>Delete</button>)}
	         </div>
	         	{isActionAllowed('CREATE_SLA') && ( <button class='ss-button' onClick={createNewSLA}>+ New SLA</button>)}
	    </div>
	</div>
    );

}

export default SLAListDisplayHeader;