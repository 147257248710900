import '../css/article.css'
import Breadcrumb from 'react-bootstrap/Breadcrumb';


function Article(props){

return(

    <div class="container padding-30 ss-article workik-article-container">
        <div class="row">
            <div class="col-md-8 padding-20">
                <div class="row">
                    <Breadcrumb>
                          <Breadcrumb.Item href="#"><img src={require('../../../img/home.png')} alt='Home' style={{height:'25px', width:'25px'}}/></Breadcrumb.Item>
                          <Breadcrumb.Item href="#">
                                <a href="#">{props.article.category.name}</a>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active><a href="#">{props.article.subcategory.name}</a></Breadcrumb.Item>
                    </Breadcrumb>
                  
                    <div class="panel panel-default">
                        <div class="article-heading margin-bottom-10">
                            <a href="#">
                                <h2>{props.article.description} </h2></a>
                        </div>
                        <div class="article-info">
                            <div class="art-date">
                                <a href="#">
                                {props.article.createdDate} </a>
                            </div>                            
                        </div>
                        <div class="article-content">
                            <div dangerouslySetInnerHTML={{ __html: (decodeURIComponent(props.article.content).replaceAll('+',' ')) }} />
                        </div>                                            
                    </div>
                </div>
            </div>    
        </div>
      </div>
   )

}export default Article