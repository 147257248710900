import React from 'react';
import {useState, setState, useRef, useEffect} from 'react';
import Attachment from './Attachment'
import DropdownSearch from '../../Util/DropdownSearch'

function ReplyBox(props){
	const [searchTerm, setSearchTerm] = useState("");  // Input field value
  const [filteredOptions, setFilteredOptions] = useState([]);  // Filtered list based on search
  const [showDropdown, setShowDropdown] = useState(false);  // Control dropdown visibility

  // Example data, replace with actual options
  const options = ["Apple", "Banana", "Orange", "Mango", "Pineapple", "Grapes"];

  // Handle input change and filter options
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.length > 0) {
      const filtered = options.filter((option) =>
        option.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(filtered);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  // Handle selecting an option
  const handleOptionSelect = (option) => {
    setSearchTerm(option);
    setShowDropdown(false);  // Hide dropdown when option is selected
  };
	function displayCannedResponses(event){

  }

  return (
                
                  <div class="reply-box-container" ref={props.replyBoxRef}>
                      <div class="reply-box-header">
                        <div class="reply-box-header-row">
                          <div class="from-chatbibox">
                            {props.toRef ? <span class="ticket-comment-to">To: <input value={props.ticket['reporter_contact'].email} ref={props.toRef} style={{width:'300px',fontSize:'16px'}}/></span> : '' }
                            <div style={{float:'right', fontSize:'16px', fontWeight:'800',cursor: 'pointer', color:'black'}} onClick ={props.closeReplyBox}>&times;</div>
                          </div>
                        </div>
                      </div>
                      <div class="reply-box-toolbar">
                          <label for="file-input" style={{verticalAlign:'baseline'}}>            
                           <img class="replybox-attachment-img" src={require('../../../img/attachment.png')}/>
                          </label>
                          <input id="file-input" type="file" style = {{display: "none" }} onChange={props.handleAttachment}/>
                          <button class="reply-box-toolbar-btn" onClick={(event)=>props.format(event,'bold')}><strong>B</strong></button>
                          <button class="reply-box-toolbar-btn" onClick={(event)=>props.format(event,'italic')}><em>I</em></button>
                          <button class="reply-box-toolbar-btn" onClick={(event)=>props.format(event,'underline')}><s>U</s></button>
                          
                      </div>

                      <div contenteditable='true' class="reply-box-message-input" placeholder="Type a message" ref={props.commentRef}  ></div>
                      <div style={{display: 'flex',flexWrap:'wrap'}}>
                          {props.attachmentFilesList.map((attachment,index) => 
                              <Attachment name={attachment.name} 
                                  isEditing={true}
                                  index = {index}
                                  size={attachment.size}
                                  removeAttachmentFromEdit={props.removeAttachmentFromEdit}
                                  source={'create'}/>
                          )}
                      </div>
                      <div class="reply-box-options">
                          
                          <span style={{verticalAlign: 'middle', display:' flex'}}><input type='checkbox' style={{float:'right', marginRight:'5px'}} name='private-comment' ref={props.privateCommentRef}/> Private Comment </span>
                          
                          <div class="icons">
                              <button class=" ss-button" onClick={(event) => props.addComment(event, props.commentRef.current.innerHTML, props.toRef ? props.toRef.current.value : '' )}>Comment</button>
                              <span class="spinner-border" role="status" style={{color: 'white', height:'15px', width:'15px',position: 'relative',display: 'none' }} ref={props.spinnerRef}/>
                          </div>
                      </div>
                      <div ref={props.errorRef} style={{color: 'red'}}/>
                  </div>
                
    )
}

export default ReplyBox;