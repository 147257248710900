
import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../../../js/Axios';
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';

function TicketsPriorityChart(props){
	const chartRef = useRef(null)
	const [data, setData] = useState(null)
	const DATA_COUNT = 5;
	const NUMBER_CFG = {count: DATA_COUNT, min: -100, max: 100};
	const labels = ['Low', 'Medium','High','Critical', 'Escalated']
  	var load = true;
 	
	useEffect(() => {
        Chart.register(ChartDataLabels);
        console.log('TicketsPriorityChart '+props.data.countStats)
		if(load){
			load=false
			loadCanvas()
		}
		
	})

function getCreatedData(){
    if(props.data.countStats === undefined  )
        return [0,0,0,0,0]
    return [props.data.countStats[13],
            props.data.countStats[12],
            props.data.countStats[11],
            props.data.countStats[10],
            props.data.countStats[9]
           ]
}
function getResolvedData(){
    if(props.data.countStats === undefined  )
        return [0,0,0,0,0]
    return [props.data.countStats[14],
            props.data.countStats[15],
            props.data.countStats[16],
            props.data.countStats[17],
            props.data.countStats[18]
           ]
}
function loadCanvas(){
 if(chartRef.current !== null)
 	chartRef.current.destroy();
 var myContext = document.getElementById( 
            "tickets_by_priority").getContext('2d'); 
 chartRef.current = new Chart(myContext, { 
            type: 'bar', 
            data: { 
                labels: ["Low", "Medium", "High",  
                    "Critical", "Escalated"], 
                datasets: [{ 
                    label: 'Created', 
                    backgroundColor: ["#1c4e80","green","#0091d5","#488A99","#7e909A"], 
                    data: getCreatedData(), 
                }, { 
                    label: 'Resolved', 
                    backgroundColor: "#d32d41", 
                    data: getResolvedData(), 
                }], 
            }, 
            options: {                
                plugins:[ChartDataLabels], 
                plugins: 
                { 
                    title: { 
                        display: true, 
                        text: 'Ticket count by priority' ,
                    },datalabels: {
                        color: 'white',
                        font: {
                          weight: 'bold',
                          size: 12,
                        }
                    }
                },

                scales: { 
                    x: { 
                        stacked: true, 
                    }, 
                    y: { 
                        stacked: true 
                    }
                } 
            } 
        });  
}

	return(<div class='dashboard-count' style={{width: '100%'}}>
			 <canvas id="tickets_by_priority"/>
		  </div>
          )
}

export default TicketsPriorityChart;