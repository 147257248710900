import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
import { usePermissions } from '../../PermissionsProvider';

function CompanyGroupListDisplayHeader(props){
	const { isActionAllowed } = usePermissions();
	const navigate = useNavigate(); 

function createNewCompanyGroup(){
	props.showCreateCompanyGroupFormFun()
}

return (
	<div class='header-body'>
	    <div class="main-header">
	    	<div style={{cursor: 'pointer',fontWeight:'bold',paddingLeft:'10px' }}>All Company Groups</div>
     		 <div class="search-wrapper">
   					{<img src={require('../../../img/search.png')} alt=''/>}
   					<input placeholder="search here" class="search-input" onChange={props.searchCompanyGroups}/>
             </div>	          
	         <div class="header-right" ref={props.groupButtonsRef}>
	            {isActionAllowed('DELETE_COMPANY_GROUP') && (<button class='ss-button'  type="button" onClick={() => props.performAction('delete')}>Delete</button> )}
	         </div>
	         {isActionAllowed('CREATE_COMPAN_GROUP') && (<button class='ss-button'  type="button"  onClick={createNewCompanyGroup}>+ Create New Group</button>)}
	    </div>
	</div>
    );

}

export default CompanyGroupListDisplayHeader;