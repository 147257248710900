import React, {useState, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
import { usePermissions } from '../../PermissionsProvider';

function CompanyListDisplayHeader(props){
const { isActionAllowed } = usePermissions();
const navigate = useNavigate(); 
const searchRef = useRef(null)
const [filterState, setFilterState] = useState('All Companies')

function openCompany(id){
	let path = constants.COMPANY_URL_ABS +props.company.id ;
    console.log("path is "+path)
    navigate(path);
}
  function displayCompanyMenu(){
  if(document.getElementById("header-menu").style.display === 'none')
    document.getElementById("header-menu").style.display = 'inline'
  else
    document.getElementById("header-menu").style.display = 'none'
  }
  function hideCompanyMenu(){
      document.getElementById("header-menu").style.display = 'none'
  }

  function createNewCompany(){
  	props.showCreateCompanyFormFun()
  }

  function selectFilter(filter, type){
  	hideCompanyMenu()
  	setFilterState(type)
  	searchRef.current.value = ''
  	props.filterCompanies(filter,0)
  }

return (
	<div class='header-body'>
	    <div class="main-header">
		    <div class="header-dropdown" onClick={displayCompanyMenu} onMouseLeave = {hideCompanyMenu}>
				<a class="btn-icon">
					<img src={require('../../../img/dots.png')} alt='' style={{rotate: "90deg", height: "20px" }}/>
				</a>
				<div>
				<div id="header-menu" style={{display: "none"}} onClick={displayCompanyMenu}>
				   <div><a class="dropdown-item-menu" href="#" onClick={() => selectFilter('all', 'All Companies') }>All Companies</a></div>
				   <div><a class="dropdown-item-menu" href="#" onClick={() => selectFilter('day', 'Created Last One day') }>Created Last One day</a></div>
				   <div><a class="dropdown-item-menu" href="#" onClick={() => selectFilter('week', 'Created Last One week') }>Created Last One week</a></div>
				   <div><a class="dropdown-item-menu" href="#" onClick={() => selectFilter('month', 'Created Last One Month') }>Created Last One Month</a></div>
				</div>
				</div>
		    </div>
	            

	    	<div style={{cursor: 'pointer',fontWeight:'bold',fontSize:'14px' }} onClick={displayCompanyMenu} class='header-filter-name'>{filterState}</div>
     		 <div class="search-wrapper">
   					{<img src={require('../../../img/search.png')} alt=''/>}
   					<input placeholder="search here" class="search-input" onChange={(event) => props.searchCompanies(event, 0)} ref={searchRef}/>
             </div>	          
	         <div class="header-right" ref={props.groupButtonsRef}>
	            {isActionAllowed('DELETE_COMPANY') && (<button class='ss-button'  type="button"  onClick={() => props.performAction('delete')}>Delete</button>)}
	         </div>
	         {isActionAllowed('CREATE_COMPANY') && (<button class='ss-button' type="button" onClick={createNewCompany}>+ New Company</button>)}
	    </div>
	</div>
    );

}

export default CompanyListDisplayHeader;