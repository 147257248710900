import React, { useState, useRef, useEffect, useContext } from "react";
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import '../../../css/main.css'
import {over} from 'stompjs'; 
import { io } from 'socket.io-client';
import ChatUserMessage from './ChatUserMessage'
import ChatCustomerMessage from './ChatCustomerMessage'
import ChatMessageAttachment from './ChatMessageAttachment'
import SocketContext from '../../SocketContext'


function ChatMiddleWindow(props){
  console.log('ChatMiddleWindow '+JSON.stringify(props.middleBoxChatMessages))
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [messages, setMessages] = useState([])
  const socket = useContext(SocketContext);
  const [chatMessages, setChatMessages] = useState([])
  const messageRef = useRef(null)
  const messagesEndRef = useRef(null)


  useEffect(() => {
      socket.on('message', (data) => {
        console.log('Message received: ChatMiddleWindow ', data);
      });

      return () => {
        socket.off('message');
      };
  }, []);

  function onMessage(message){
      console.log('onMessage  '+message)
      message = JSON.parse(message)
      console.log("Message on user side received "+message.type)
      if(message.type === 'welcome'){
   
      }else if(message.type === 'connect_to_user'){
         console.log(message)
      }
  }

    useEffect(()=>{
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  },[props.middleBoxChatMessages])


// Function to handle selecting a customer
const selectCustomer = (customerId) => {
setSelectedCustomerId(customerId);
};

// Function to send a new message (by agent)
const handleSendMessage = () => {
  console.log('handleSendMessage '+messageRef.current.innerHTML)
  if (messageRef.current.innerHTML.trim() === '') return;
  let session = JSON.parse(sessionStorage.getItem('ss_sess'))
  const newMessage = { type:'user_chat_message', content: messageRef.current.innerHTML, sender: 'agent', conversationId: props.currentConversationIdRef.current, userId: session.userId, cmUserId: session.userId, accountId:  session.accountId };
  console.log('handleSendMessage '+JSON.stringify(newMessage))
  socket.send(newMessage)
  props.setMiddleBoxChatMessages((prevMessages) => [...prevMessages, newMessage]);
  messageRef.current.innerHTML = ''
};



function fetchType(message){
  console.log('fetchType '+ JSON.stringify(message))
  if(message.cmUserId && message.cmUserId !== 'null')
    return 'user'
  else
    return 'customer';
}

  return( 
          
        <div class = 'chat-middle-box' >  
            <div style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div class="chat-header-img" style={{ marginRight: '10px' }}>
                  <img 
                    src={require('../../../img/Headshot.jpg')} 
                    alt="Profile" 
                    style={{ width: '50px', height: '50px', borderRadius: '50%' }} 
                  />
                </div>
                <div class="chat-header-info">
                  <h4 style={{ margin: 0, color: '#007bff', fontSize: '1.2em' }}>{(props.contact !== 'null') ? props.contact : 'Unknown' }</h4>
                </div>
              </div>
            </div>
              <div class="chat-wrapper">
                 <div className="chat-wrapper-items" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                      {props.middleBoxChatMessages.length === 0 ? (
                        <div style={{ textAlign: 'center', fontSize: '18px', color: 'black' }}>No Chats</div>
                      ) : (
                        props.middleBoxChatMessages.map((message, index) => {
                          switch (fetchType(message)) {
                            case 'user':
                              return <ChatUserMessage key={index} text={message.content} />;
                            case 'customer':
                              return <ChatCustomerMessage key={index} text={message.content} />;
                            case 'attachment':
                              return <ChatMessageAttachment key={index} />;
                            default:
                              return null;
                          }
                        })
                     )}

                     <div ref={messagesEndRef} />
                 </div>

    {/*                    <div class="chat-wrapper-items">
                       <ChatUserMessage text = "Hi David, have you got the project report pdf?"/>
                       <ChatCustomerMessage text="No i did not get it!"/>
                       <ChatUserMessage text = "ok i will just sent it<br /> plz be sure to fill the details<br /> by today end of the day."/>
                       <ChatCustomerMessage text="Hi David, have you got the project report pdf"/>
                       <ChatMessageAttachment />  
                   </div>                 
    */}                   
              </div>

            <div 
              className="chatbibox-wrapper chat-footer" 
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                backgroundColor: '#f9f9f9',
                borderTop: '1px solid #e0e0e0',
                padding: '10px 15px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div 
                className="chatbox-input" 
                style={{
                  flexGrow: 1,
                  padding: '10px 15px',
                  borderRadius: '20px',
                  border: '1px solid #ccc',
                  outline: 'none',
                  fontSize: '0.95rem',
                  backgroundColor: '#ffffff',
                  color: '#333',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <div 
                  contentEditable="true"
                  ref={messageRef}
                  style={{
                    flexGrow: 1,
                    padding: '8px',
                    borderRadius: '20px',
                    border: 'none',
                    outline: 'none',
                    overflowY: 'auto', // Ensure it scrolls if content overflows
                    whiteSpace: 'pre-wrap', // Preserve whitespace and line breaks
                    textAlign: 'left', // Align text to the left
                    minHeight: '40px', // Minimum height for the input area
                  }}
                  placeholder="Write something..."
                  onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        if (e.getModifierState('Shift')) {
                          // Tab + Enter is pressed, insert a new line
                          document.execCommand('insertHTML', false, '<br><br>');
                          e.preventDefault();
                        } else {
                          // Only Enter is pressed, handle sending the message
                          e.preventDefault();
                          handleSendMessage(e);
                        }
                      }
                    }}
                />
                 
                <ul style={{ display: 'flex', gap: '8px', listStyleType: 'none', padding: 0, margin: 0 }}>
                  <li><img className="chatbox-attachment-img" src={require('../../../img/attachment.png')} style={{ width: '20px', height: '20px', cursor: 'pointer' }} /></li>
                </ul>
              </div>
              <div className="chatbibox-footer" style={{ marginLeft: '10px' }}>
                <button 
                  className="ss-button send" 
                  style={{
                    backgroundColor: '#1f77b4',
                    border: 'none',
                    padding: '8px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClick={handleSendMessage}
                >
                  <img src={require('../../../img/paper-plane.png')} style={{ width: '18px', height: '18px' }} />
                </button>
              </div>
            </div>

         
        </div>

          
      )

}

export default ChatMiddleWindow;