import React, { useEffect, useRef, useState } from 'react';
import * as constants from '../../../js/constants';
import * as axiosClient from '../../../js/Axios';

const variables = [
  { name: 'system.time', type: 'time' },
  { name: 'system.date', type: 'date' },
  { name: 'system.timezone', type: 'string' },
  { name: 'user.city', type: 'string' },
  { name: 'ticket.priority', type: 'enum', options: ['Low', 'Medium', 'High'] },
  // Add other variables here...
];

// Conditions by type of variable
const conditionsByType = {
  time: ['is', 'is before', 'is after'],
  date: ['is', 'is before', 'is after', 'is today'],
  string: ['is', 'contains', 'starts with', 'ends with', 'is empty'],
  enum: ['is', 'is not'],
  // Add other types here...
};


function NodePanel({ closeCreateWindow, addNode, updateNode, selectedNode,handleDelete }){  
  const [nodeType, setNodeType] = useState("");
  const [userProperty, setUserProperty]=useState('')
  const [buttonLabels, setButtonLabels] = useState([])
  const [sbNodeType, setSbNodeType] = useState("");
  const [sbOptions, setSbOptions] = useState([]);
  const [suggestions,setSuggestions]= useState([])
  const [selectedAPIs, setSelectedAPIs] = useState([])
  const [selectedVideos, setSelectedVideos] = useState([])
  const [selectedVariable, setSelectedVariable] = useState('');
  const [selectedCondition, setSelectedCondition] = useState('');
  const [value, setValue] = useState('');
  const [rule, setRule] = useState({ variable: '', condition: '', value: '' });
  const nameRef = useRef(null)
  const messageRef = useRef(null)
  const sbNodeTypeRef = useRef(null)
  const nodeTypeRef = useRef(null)
  const [labelValue, setLabelValue] = useState('')
  const [labelValue2, setLabelValue2] = useState('')
  const [timeInputFormat, setTimeInputFormat] = useState('')
  const [timeDisplayFormat, setTimeDisplayFormat] = useState('')
  const [searchInput, setSearchInput] = useState("");
  const [apiList, setApiList] = useState([]); // Full API list to filter from
  const [conditions, setConditions] = useState([{ variable: '', operator: '', value: '' }]);
  const [actions, setActions] = useState([{ type: '', details: '' }]);
  const [logicOperator, setLogicOperator] = useState("AND");  
  const [selectedArticles, setSelectedArticles] = useState({
      articles: [],      // Stores selected article names and IDs
      suggestions: [],   // Stores article suggestions for each input
      numArticles: 1     // Number of article input fields
    });
  let labels = []
  const spinnerRef = useRef(null)

  useEffect(()=>{
    spinnerRef.current.style.display = 'none'

    if(selectedNode){          
      let event = {
         'target':{'value' : selectedNode.data.nodeType}
      };
      handleNodeTypeChange(event)      
      event = {
         'target':{'value' : reverseOptionsMap[selectedNode.data.type]}
      };

      setSbNodeType(selectedNode.data.type)      
    }
  },[])

  useEffect(() => {
    if(!selectedNode) 
      return
    if (sbNodeType === "button") {
        nameRef.current.value = selectedNode.data.name
        messageRef.current.value = selectedNode.data.message
        setSbNodeType(selectedNode.data.type)        
        setAttributes({ ...attributes, ['numButtons']:  selectedNode.data.labels.length});
        setButtonLabels(selectedNode.data.labels)
        setUserProperty(selectedNode.data.userProperty)
        
    }else if (sbNodeType === "single_choice"){
        nameRef.current.value = selectedNode.data.name
        messageRef.current.value = selectedNode.data.message
        setLabelValue(selectedNode.data.label)
        setSbNodeType(selectedNode.data.type)        
        
    }else if (sbNodeType === "multiple_choice") {
        nameRef.current.value = selectedNode.data.name
        messageRef.current.value = selectedNode.data.message
        setSbNodeType(selectedNode.data.type)        
        setAttributes({ ...attributes, ['numChoices']:  selectedNode.data.labels.length});
        setButtonLabels(selectedNode.data.labels)        
    }
    else if (sbNodeType === "date_selection") {
        nameRef.current.value = selectedNode.data.name
        messageRef.current.value = selectedNode.data.message
        setSbNodeType(selectedNode.data.type)        
        setValue(selectedNode.data.label) 
    }else if (sbNodeType === "date_time_selection") {
        nameRef.current.value = selectedNode.data.name
        messageRef.current.value = selectedNode.data.message
        setSbNodeType(selectedNode.data.type)        
        setTimeInputFormat(selectedNode.data.time_input_format) 
        setTimeDisplayFormat(selectedNode.data.time_display_format)
    }else if (sbNodeType === "slider") {
        nameRef.current.value = selectedNode.data.name
        messageRef.current.value = selectedNode.data.message
        setSbNodeType(selectedNode.data.type)  
        setAttributes({...attributes,
              minValue: selectedNode.data.minValue,
              maxValue: selectedNode.data.maxValue,
              stepValue: selectedNode.data.stepValue,
              currentValue: selectedNode.data.currentValue
        });        
    }else if (sbNodeType === "star_rating") {
        nameRef.current.value = selectedNode.data.name
        messageRef.current.value = selectedNode.data.message
        setSbNodeType(selectedNode.data.type)  
        setAttributes({...attributes, rating: selectedNode.data.rating});        
    }else if (sbNodeType === "free_text" || sbNodeType === "free_text_area" ) {
        nameRef.current.value = selectedNode.data.name
        messageRef.current.value = selectedNode.data.message
        setSbNodeType(selectedNode.data.type)  
        setAttributes({...attributes, placeholder: selectedNode.data.placeholder, 
                          placeholder: selectedNode.data.placeholder,
                          mandatory: selectedNode.data.mandatory
                    });        
    }else if (sbNodeType === "response_video") {
        nameRef.current.value = selectedNode.data.name
        messageRef.current.value = selectedNode.data.message
        setSbNodeType(selectedNode.data.type)  
        setAttributes({
              ...attributes, 
              numVideos: selectedNode.data.video.length, // Set the number of videos
              videos: selectedNode.data.video // Set the video array
            });
    }else if (sbNodeType === "response_link") {
        nameRef.current.value = selectedNode.data.name
        messageRef.current.value = selectedNode.data.message
        setSbNodeType(selectedNode.data.type)        
        setAttributes({ ...attributes, ['numLinks']:  selectedNode.data.labels.length});
        setButtonLabels(selectedNode.data.labels)
        
    }else if (sbNodeType === "set_user_property") {
        nameRef.current.value = selectedNode.data.name
        messageRef.current.value = selectedNode.data.message
        setSbNodeType(selectedNode.data.type)                      
    }else if (sbNodeType === "create_ticket") {
        nameRef.current.value = selectedNode.data.name
        setSbNodeType(selectedNode.data.type)        
        setAttributes({ ...attributes, summary:  selectedNode.data.summary, description:  selectedNode.data.description});              
    }else if (sbNodeType === "resolve_ticket" ||
              sbNodeType === "reopen_ticket" ||
              sbNodeType === "escalate_ticket") {
        nameRef.current.value = selectedNode.data.name
        setSbNodeType(selectedNode.data.type)        
        setAttributes({ ...attributes, ticketId:  selectedNode.data.ticketId});              
    }else if (sbNodeType === "decision_tree") {
        nameRef.current.value = selectedNode.data.name
        setSbNodeType(selectedNode.data.type)                      
        setConditions(selectedNode.data.conditions || []);        
    }

  }, [sbNodeType]);
  

  function isEdit(){
    return selectedNode;
  }

  const [attributes, setAttributes] = useState({
    nodeName: "",
    message: "",
    numButtons: 1,
    numArticles: 1,
    numLinks: 1,
    numChoices: 1,
    numVideos: 1,
    buttonLabels: ["", "", ""], // Up to 3 buttons initially
    articleNames: [],  // Stores selected article names
    articleIds: [],    // Stores selected article IDs
    videos: [],
    options: [],
    minValue: "",
    maxValue: "",
    stepValue: "",
    rating: "",
    location: "",
    dateTime: "",
    fileUpload: null,
    email: "",
    apiEndpoint: "",
    tags: "",
    criteria: "",
    mandatory:'',
    placeholder:'',
    ticketId:''
  });
  const [attributesArr, setAttributesArr] = useState([
    'name',
    'message',
    'label',
    'date_format',
    'time_display_format',
    "time_input_format",
    'file_type',
    'description',
    'summary',
    'ticketId',
    'minValue',
    'maxValue',
    'stepValue',
    'currentValue',
    "rating",
    "placeholder",
    "mandatory",
    "ticketId",
    "user_property"
  ]);
  // Options for sbNodeType based on nodeType
const reverseOptionsMap = {
    "button":"User Input: Button",
    "single_choice":"User Input: Single Choice",
    "multiple_choice": "User Input: Multiple Choice",
    "date_selection": "User Input: Date Selection (Calendar)",
    "date_time_selection": "User Input: Date And Time Selection",
    "slider": "User Input: Adjustable Slider",
    "star_rating": "User Input: Star Rating",
    "upload_file": "User Input: Upload a File",
    "free_text" : "User Input: Single Line Text",
    "free_text_area" : "User Input: Multi Line Text",
    "decision_tree" : "User Input: Multi Line Text",
    "response_message": "Bot Response: Message",
    "response_article": "Bot Response: Article",
    "response_video": "Bot Response: Video",
    "response_link": "Bot Response: Share a Link",
    "set_user_property": "Action: Set User Property",
    "trigger_api": "Action: Trigger External API",
    "create_ticket": "Action: Create Ticket",
    "resolve_ticket": "Action: Resolve Ticket",
    "reopen_ticket": "Action: Reopen Ticket",
    "escalate_ticket": "Action: Escalate Ticket",
    "message": "End Chat: Final Message to User",
    "forward_to_operator": "Action: Forward to Agent",
    "terminate_chat": "End Chat: Terminate the Chat Session"
}


const optionsMap = {
  input: [
    { label: "User Input: Button", value: "button" },
    { label: "User Input: Single Choice", value: "single_choice" },
    { label: "User Input: Multiple Choice", value: "multiple_choice" },
    { label: "User Input: Date Selection (Calendar)", value: "date_selection" },
    { label: "User Input: Date And Time Selection", value: "date_time_selection" },
    { label: "User Input: Adjustable Slider", value: "slider" },
    { label: "User Input: Star Rating", value: "star_rating" },    
    // { label: "User Input: Upload a File", value: "upload_file" },
    { label: "User Input: Single Line Text", value: "free_text" },
    { label: "User Input: Multi Line Text", value: "free_text_area" },
    // { label: "User Input: Share Location", value: "share_location" },
    // { label: "User Input: Carousel with Input Options", value: "input_carousel" },
    // { label: "User Input: Free Text", value: "free_text" },
  ],
  condition: [
    { label: "Bot Condition: Logical Node", value: "decision_tree" },
  ],
  response: [
    { label: "Bot Response: Message", value: "response_message" },
    { label: "Bot Response: Article", value: "response_article" },
    { label: "Bot Response: Video", value: "response_video" },
    { label: "Bot Response: Share a Link", value: "response_link" },
    // { label: "Bot Response: Image/Item Carousel", value: "item_carousel" },
  ],
  action: [
    { label: "Action: Logical Node", value: "decision_tree" },
    { label: "Action: Trigger External API", value: "trigger_api" },
    { label: "Action: Create Ticket", value: "create_ticket" },    
    { label: "Action: Resolve Ticket", value: "resolve_ticket" },
    { label: "Action: Reopen Ticket", value: "reopen_ticket" },
    { label: "Action: Escalate Ticket", value: "escalate_ticket" }
  ],
  end: [
    { label: "End Chat: Final Message to User", value: "response_message" },
    { label: "Action: Forward to Agent", value: "forward_to_operator" }
  ],
};


  const variableDetails = variables.find(v => v.name === selectedVariable);
  const availableConditions = variableDetails ? conditionsByType[variableDetails.type] : [];

  const handleVariableChange = (e) => {
    setSelectedVariable(e.target.value);
    setSelectedCondition('');
    setValue('');
  };

  const handleConditionChange = (e) => {
    setSelectedCondition(e.target.value);
  };

  const handleValueChange = (e) => {
    setValue(e.target.value);
  };

 const handleNodeTypeChange = (event) => {
    const selectedValue = event.target.value;
    setNodeType(selectedValue);
    setSbOptions(optionsMap[selectedValue] || []);
  };

  const handleSbNodeTypeChange = (e) => {
    console.log('handleSbNodeTypeChange '+ e.target.value)
    setSbNodeType(e.target.value);
  };

  const handleAttributeChange = (e, field) => {
    labels = new Array(e.target.value).fill('');
    setAttributes({ ...attributes, [field]: e.target.value });
  };

  const handleValues =(e,index)=>{
    labels[index] = e.target.value;
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    let id = `${Date.now()}`
    if(selectedNode)
      id= selectedNode.id
    let newNode = {
          id: id, // Unique ID for the node
          type: sbNodeType.toLowerCase().replace(" ", "_"),
          type:'chatMenuBox',
          position: { x: Math.random() * 400, y: Math.random() * 400 }, 
        };
    let payload = {}
    attributesArr.forEach((attribute) => {
        if(event.target[attribute]){
          console.log('handleSubmit for loop '+attribute+'   '+event.target[attribute].value)
          payload[attribute] = event.target[attribute].value;
        }
    })
    if(!selectedNode){
      payload['id'] =id
      console.log('updating id')
    }
    payload['labels']=labels
    payload['type']=sbNodeType
    payload['nodeType']=nodeType
    if(sbNodeType.toLowerCase().replace(" ", "_") === 'response_video'){
      payload['video'] = [...attributes.videos]
    }else if (sbNodeType.toLowerCase().replace(" ", "_") === 'decision_tree') {
      payload['conditions'] = conditions;
      payload['logicOperator'] = logicOperator;
    }
    payload['handleDelete'] = handleDelete
    payload['userProperty'] = userProperty
    labels = []
    // payload['onClick'] = () => handleNodeClick(id)
    newNode['data'] = payload
    console.log('before updating '+JSON.stringify(newNode))
    if(selectedNode)
      updateNode(newNode)
    else
      addNode(newNode); 
    closeCreateWindow();
  };


const searchArticles = (e, index) => {
  console.log('searchArticles '+e.target.value)
  const searchTerm = e.target.value;

  // Mock API call to get articles that match the search term
  const searchResults = getArticlesBySearchTerm(searchTerm);

  // Update the suggestions for the current input field
  setSelectedArticles((prevState) => {
    const newSuggestions = [...prevState.suggestions];
    newSuggestions[index] = searchResults;
    return {
      ...prevState,
      suggestions: newSuggestions
    };
  });
};

const selectArticle = (index, article) => {
  // Update the selected article name and ID for the corresponding index
  setSelectedArticles((prevState) => {
    const updatedArticles = [...prevState.articles];
    updatedArticles[index] = { name: article.name, id: article.id };

    return {
      ...prevState,
      articles: updatedArticles,
      suggestions: [] // Clear suggestions after selection
    };
  });
};


const selectArticles = (index, article) => {
  // Update the selected article name and ID for the corresponding index
  setSelectedArticles((prevState) => {
    const updatedArticles = [...prevState.articles];
    updatedArticles[index] = { name: article.name, id: article.id };

    return {
      ...prevState,
      articles: updatedArticles,
      suggestions: [] // Clear suggestions after selection
    };
  });
};

const getArticlesBySearchTerm = (term) => {
  // This is a mock function; you would replace it with an API call
  const allArticles = [
    { id: 1, name: "Article One" },
    { id: 2, name: "Article Two" },
    { id: 3, name: "Another Article" }
  ];

  // Filter articles by name based on the search term
  return allArticles.filter((article) =>
    article.name.toLowerCase().startsWith(term.toLowerCase())
  );
};


function handleTimeInputFormatChange(event){
  setTimeInputFormat(event.target.value)
}
function handleTimeDisplayFormatChange(event){
  setTimeDisplayFormat(event.target.value)
}

  const handleVideoChange = (e, index, field) => {
    const updatedVideos = [...attributes.videos];
    if (!updatedVideos[index]) {
        updatedVideos[index] = { name: '', url: '', thumbnailUrl: '' };
      }
    updatedVideos[index][field] = e.target.value;
    setAttributes({ ...attributes, videos: updatedVideos });
  };
// Function to handle search input change and filter APIs
const handleSearchInputChange = (event) => {
    console.log('handleSearchInputChange '+event.target.value)
    const searchValue = event.target.value;
    setSearchInput(searchValue);
    axiosClient.getRequest(constants.CHAT_BOT_API+'/search?keyword='+event.target.value)
    .then(function (response) {
      setSelectedAPIs(response.data);  
    }).catch(function (response) {
        console.log(response);
    }); 
};

// Function to select an API
const selectAPI = (api) => {
    setSearchInput(api.name); // Set input to selected API's name
    setSelectedAPIs([]); // Hide dropdown
};
  const handleVideoUpload = (e, index, field) => {
    const file = e.target.files[0];
    if (file) {

      const updatedVideos = [...attributes.videos];
      if (!updatedVideos[index]) {
        updatedVideos[index] = { name: '', url: '', thumbnailUrl: '' };
      }
      updatedVideos[index][field] = URL.createObjectURL(file);
      setAttributes({ ...attributes, videos: updatedVideos });
    }
  };


const addCondition = () => {
        setConditions([...conditions, { variable: '', operator: 'AND', value: '' }]);
    };

    const removeCondition = (index) => {
        setConditions(conditions.filter((_, i) => i !== index));
    };

    const updateCondition = (index, field, value) => {
        const updatedConditions = [...conditions];
        updatedConditions[index][field] = value;
        setConditions(updatedConditions);
    };

    const addAction = () => {
        setActions([...actions, { type: '', details: '' }]);
    };

    const removeAction = (index) => {
        setActions(actions.filter((_, i) => i !== index));
    };

    const updateAction = (index, field, value) => {
        const updatedActions = [...actions];
        updatedActions[index][field] = value;
        setActions(updatedActions);
    };  
  return (
          <div class="create-wrapper">
            <form onSubmit={handleSubmit}>
             <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
              <a href="javascript:void(0)" class="create-closebtn" onClick={closeCreateWindow}>&times;</a>
              <h8 style= {{color: '#044caf', fontWeight: '800', fontSize: "20px"}}><b>+ Node Panel</b></h8>
              <div class='create-line'></div>
              <div style={{marginTop: "3%"}} class='create-form-label'>Node Type</div>
                    <div>
                        <select
                          style={{width: '90%',padding: '7px'}}
                          type="text"
                          name="nodeType"
                          value={nodeType}
                          onChange={handleNodeTypeChange}
                          ref={nodeTypeRef}
                        >
                          <option value="">Select Node Type</option>
                          <option value="input" >Receive Input From User</option>
                          <option value="response">Respond To User</option>
                          <option value="action">Perform An Action</option>
                          <option value="end">Terminate Chat Session</option>
                        </select>
                      </div>
                  <div style={{marginTop: "3%"}} class='create-form-label'>Sub Node Type</div>
                      <div>
                        <select type="text" name="sbNodeType" style={{width: '90%',padding: '7px'}} onChange={handleSbNodeTypeChange} ref={sbNodeTypeRef}>
                          <option value="">Select Sub Node Type</option>
                          {sbOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                  </div>



            {/* Conditionally Render Input Boxes Based on Sub Node Type */}
                {sbNodeType === "button" && (
                  <div>
                    <div className="create-form-label">Node Name</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      name='name'
                      placeholder="Enter Node Name"
                      ref={nameRef}
                    />

                    <div className="create-form-label">Message</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      name='message'
                      placeholder="Enter Message"
                      ref={messageRef}
                    />

                    <div className="create-form-label">Number of Buttons</div>
                    <input
                      type="number"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.numButtons}
                      onChange={(e) => handleAttributeChange(e, "numButtons")}
                      placeholder="Enter Number of Buttons"
                    />

                    {Array.from({ length: attributes.numButtons }, (_, index) => (
                      <div key={index}>
                        <div className="create-form-label">Button Label {index + 1}</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}   
                          value={buttonLabels[index]}                                               
                          placeholder={`Enter Label for Button ${index + 1}`}
                          onChange={(e) => handleValues(e, index)}
                        />
                      </div>
                    ))}
                    <div className="create-form-label">Set value to a variable</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}                                            
                      placeholder="Variable name"
                      name="userProperty"
                      value={userProperty}
                      onChange={(e) => setUserProperty(e.target.value)}
                    />                    
                  </div>
                )}

                {sbNodeType === "single_choice" && (
                  <div>
                    <div className="create-form-label">Node Name</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      name='name'
                      placeholder="Enter Node Name"
                      ref={nameRef}
                    />

                    <div className="create-form-label">Message</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}                      
                      placeholder="Enter Message"
                      name='message'
                      ref={messageRef}
                    />

                    <div className="create-form-label">Label</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}                      
                      placeholder="Enter Label"
                      name='label'
                      value={labelValue}
                    />
                    <div className="create-form-label">Set value to a variable</div>
                    <input
                      type="text"
                      name="user_property"
                      style={{ width: "90%", padding: "7px" }}                                            
                      placeholder="Variable name"
                    />                        
                  </div>
                )}
                {sbNodeType === "multiple_choice" && (
                  <div>
                    <div className="create-form-label">Node Name</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      name='name'
                      placeholder="Enter Node Name"
                    />

                    <div className="create-form-label">Message</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      name='message'
                      placeholder="Enter Message"
                    />

                    <div className="create-form-label">Number of Choices</div>
                    <input
                      type="number"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.numChoices}
                      onChange={(e) => handleAttributeChange(e, "numChoices")}
                      placeholder="Enter Number of Choices"
                    />

                    {Array.from({ length: attributes.numChoices }, (_, index) => (
                      <div key={index}>
                        <div className="create-form-label">Choice Label {index + 1}</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}                                                    
                          placeholder={`Enter Label for Choice ${index + 1}`}
                          onChange={(e) => handleValues(e, index)}
                        />
                      </div>
                    ))}
                    <div className="create-form-label">Set value to a variable</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}                                            
                      placeholder="Variable name"
                      name="user_property"
                    />                      
                  </div>
                )}
                {sbNodeType === "date_selection" && (
                  <div>
                    <div className="create-form-label">Node Name</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      name='name'
                      placeholder="Enter Node Name"
                      ref={nameRef}
                    />

                    <div className="create-form-label">Message</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      name='message'
                      placeholder="Enter Message"
                      ref={messageRef}
                    />

                    <div className="create-form-label">Format</div>
                    <select                      
                      style={{ width: "90%", padding: "7px" }}
                      name='date_format'
                      value={labelValue}
                    >
                      <option>MM/DD/YYYY</option>
                      <option>DD/MM/YYYY</option>
                      <option>YYYY-MM-DD</option>
                      <option>MM-DD-YYYY</option>
                    </select>
                    <div className="create-form-label">Set value to a variable</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}                                            
                      placeholder="Variable name"
                      name="user_property"
                    />                      
                  </div>
                )}
                {sbNodeType === "date_time_selection" && (
                  <div>
                    <div className="create-form-label">Node Name</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      name='name'
                      placeholder="Enter Node Name"
                      ref={nameRef}
                    />

                    <div className="create-form-label">Message</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      name='message'
                      ref={messageRef}
                    />
                    <div className="create-form-label">Input Format</div>
                    <select                      
                      style={{ width: "90%", padding: "7px" }}
                      name='time_input_format'
                      value={timeInputFormat}
                      onChange={handleTimeInputFormatChange}
                    >
                      <option>12 Hours</option>
                      <option>24 Hours</option>
                    </select>                                        
                    <div className="create-form-label">Display Format</div>
                    <select                      
                      style={{ width: "90%", padding: "7px" }}
                      name='time_display_format'
                      value={timeDisplayFormat}
                      onChange={handleTimeDisplayFormatChange}
                    >
                      <option>HH:mm</option>
                      <option>HH:mm:ss</option>
                    </select>   
                    <div className="create-form-label">Set value to a variable</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}                                            
                      placeholder="Variable name"
                      name="user_property"
                    />                                       
                  </div>
                )}
                {sbNodeType === "slider" && (
                  <div>
                    <div className="create-form-label">Node Name</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.nodeName}
                      onChange={(e) => handleAttributeChange(e, "nodeName")}
                      placeholder="Enter Node Name"
                      name='name'
                      ref={nameRef}
                    />
                    <div className="create-form-label">Message</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.message}
                      onChange={(e) => handleAttributeChange(e, "message")}
                      placeholder="Enter Message"
                      message='message'
                      ref={messageRef}
                    />
                    <div className="create-form-label">Min Value</div>
                    <input
                      type="number"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.minValue}
                      onChange={(e) => handleAttributeChange(e, "minValue")}
                      placeholder="Enter Minimum Value"
                      name='minValue'
                    />

                    <div className="create-form-label">Max Value</div>
                    <input
                      type="number"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.maxValue}
                      onChange={(e) => handleAttributeChange(e, "maxValue")}
                      placeholder="Enter Maximum Value"
                      name="maxValue"
                    />

                    <div className="create-form-label">Step Value</div>
                    <input
                      type="number"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.stepValue}
                      onChange={(e) => handleAttributeChange(e, "stepValue")}
                      placeholder="Enter Step Value"
                      name='stepValue'
                    />
                    <div className="create-form-label">Current Value</div>
                    <input
                      type="number"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.currentValue}
                      onChange={(e) => handleAttributeChange(e, "stepValue")}
                      placeholder="Enter Step Value"
                      name='currentValue'
                    />  
                    <div className="create-form-label">Set value to a variable</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}                                            
                      placeholder="Variable name"
                      name="user_property"
                    />                                        
                  </div>
                )}

                {sbNodeType === "star_rating" && (
                  <div>
                    <div className="create-form-label">Node Name</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      placeholder="Enter Node Name"
                      ref={nameRef}
                      name='name'
                    />
                    <div className="create-form-label">Message</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      placeholder="Enter Message"
                      ref={messageRef}
                      name='message'
                    />
                    <div className="create-form-label">Rating</div>
                    <select
                      type="number"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.rating}
                      onChange={(e) => handleAttributeChange(e, "rating")}
                      placeholder="Enter Rating Value"
                      name='rating'
                    >
                      <option  value='star_rating'>Star Rating</option>
                      <option value='like_dislike'>Like/Dis Like</option>
                      <option value='emoji'>Emoji Rating</option>
                      <option value='likert'>Likert Scale</option>
                    </select>
                  </div>
                )}

                {sbNodeType === "share_location" && (
                  <div>
                    <div className="create-form-label">Node Name</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.nodeName}
                      onChange={(e) => handleAttributeChange(e, "nodeName")}
                      placeholder="Enter Node Name"
                    />

                    <div className="create-form-label">Location</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.location}
                      onChange={(e) => handleAttributeChange(e, "location")}
                      placeholder="Enter Location"
                    />
                    <div className="create-form-label">Set value to a variable</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}                                            
                      placeholder="Variable name"
                      name="user_property"
                    />                      
                  </div>
                )}



                {sbNodeType === "upload_file" && (
                  <div>
                    <div className="create-form-label">Node Name</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      name='name'
                      placeholder="Enter Node Name"
                    />
                    <div className="create-form-label">Message</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      name='message'
                      placeholder="Enter Message"
                    />
                    <div className="create-form-label">File Types(Comma Seperated)</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      name='file_type'
                      onChange={(e) => handleAttributeChange(e, "fileUpload")}
                    />
                  </div>
                )}

                {sbNodeType === "Email" && (
                  <div>
                    <div className="create-form-label">Node Name</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.nodeName}
                      onChange={(e) => handleAttributeChange(e, "nodeName")}
                      placeholder="Enter Node Name"
                    />

                    <div className="create-form-label">Email</div>
                    <input
                      type="email"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.email}
                      onChange={(e) => handleAttributeChange(e, "email")}
                      placeholder="Enter Email"
                    />
                  </div>
                )}

                {sbNodeType === "api_call" && (
                  <div>
                    <div className="create-form-label">Node Name</div>
                    <input
                      type="text"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.nodeName}
                      onChange={(e) => handleAttributeChange(e, "nodeName")}
                      placeholder="Enter Node Name"
                    />

                    <div className="create-form-label">API Endpoint</div>
                    <input
                      type="url"
                      style={{ width: "90%", padding: "7px" }}
                      value={attributes.apiEndpoint}
                      onChange={(e) => handleAttributeChange(e, "apiEndpoint")}
                      placeholder="Enter API Endpoint"
                    />
                  </div>
                )}

                
              
                  {sbNodeType === "response_video" && (
                      <div>
                      <div className="create-form-label">Node Name</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name='name'
                          onChange={(e) => handleAttributeChange(e, "nodeName")}
                          placeholder="Enter Node Name"
                          ref={nameRef}
                        />
                      <div className="create-form-label">Message</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name='message'
                          onChange={(e) => handleAttributeChange(e, "message")}
                          placeholder="Enter Message"
                          ref={messageRef}
                        />
                        <div className="create-form-label">Number of Videos</div>
                        <input
                          type="number"
                          style={{ width: "90%", padding: "7px" }}
                          value={attributes.numVideos}
                          onChange={(e) => handleAttributeChange(e, "numVideos")}
                          placeholder="Enter Number of Videos"
                        />

                           {Array.from({ length: attributes.numVideos }, (_, index) => (
                                <div key={index}>
                                  {/* Video Name */}
                                  <div className="create-form-label">Video Name {index + 1}</div>
                                  <input
                                    type="text"
                                    style={{ width: "90%", padding: "7px" }}
                                    value={attributes.videos[index]?.name || ""}
                                    onChange={(e) => handleVideoChange(e, index, "name")}
                                    placeholder={`Enter Name for Video ${index + 1}`}
                                  />

                                  {/* Video URL */}
                                  <div className="create-form-label">Video URL {index + 1}</div>
                                  <input
                                    type="text"
                                    style={{ width: "90%", padding: "7px" }}
                                    value={attributes.videos[index]?.url || ""}
                                    onChange={(e) => handleVideoChange(e, index, "url")}
                                    placeholder={`Enter URL for Video ${index + 1}`}
                                  />

                                  {/* Upload Thumbnail Option */}
                                  <div className="create-form-label">Or Upload Thumbnail {index + 1}</div>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    style={{ width: "90%", padding: "7px" }}
                                    onChange={(e) => handleVideoUpload(e, index, "thumbnailUrl")}
                                  />

                                  {/* Preview of Thumbnail */}
                                  {attributes.videos[index]?.thumbnailUrl && (
                                    <img
                                      src={attributes.videos[index].thumbnailUrl}
                                      alt={`Thumbnail for Video ${index + 1}`}
                                      style={{ width: "90px", height: "auto", marginTop: "10px" }}
                                    />
                                  )}
                                </div>
                          ))}
                      </div>
                    )}

                  {sbNodeType === "response_link" && (
                      <div>
                      <div className="create-form-label">Node Name</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name='name'
                          placeholder="Enter Node Name"
                          ref={nameRef}
                        />
                      <div className="create-form-label">Message</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name='message'
                          placeholder="Enter Message"
                          ref={messageRef}
                        />
                        <div className="create-form-label">Number of Links</div>
                        <input
                          type="number"
                          style={{ width: "90%", padding: "7px" }}
                          value={selectedVideos.numVideos}
                          onChange={(e) => handleAttributeChange(e, "numLinks")}
                          placeholder="Enter Number of Videos"
                        />

                        {Array.from({ length: attributes.numLinks }, (_, index) => (
                          <div key={index}>
                            <div className="create-form-label">Link Name {index + 1}</div>
                            <input
                              type="text"
                              style={{ width: "90%", padding: "7px" }}
                              value={buttonLabels[index]}  
                              onChange={(e) => handleValues(e, index)}
                              placeholder={`Enter Name for Video ${index + 1}`}
                            />
                          </div>
                        ))}
                      </div>
                    )}


                    {sbNodeType === "image_carousel" && (
                      <div>
                        <div className="create-form-label">Carousel Title</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          value={attributes.carouselTitle}
                          onChange={(e) => handleAttributeChange(e, "carouselTitle")}
                          placeholder="Enter the carousel title"
                        />

                        <div className="create-form-label">Image URLs (Comma Separated)</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          value={attributes.imageUrls}
                          onChange={(e) => handleAttributeChange(e, "imageUrls")}
                          placeholder="Enter image URLs, separated by commas"
                        />
                        
                        <div className="create-form-label">Caption for Images (Comma Separated)</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          value={attributes.captions}
                          onChange={(e) => handleAttributeChange(e, "captions")}
                          placeholder="Enter captions for images, separated by commas"
                        />
                      </div>
                    )}
                    {sbNodeType === "set_property" && (
                      <div>
                        <div className="create-form-label">Node Name</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name='name'
                          placeholder="Enter the property name"
                        />
                        <div className="create-form-label">Property Name</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          value={attributes.propertyName}
                          onChange={(e) => handleAttributeChange(e, "propertyName")}
                          placeholder="Enter the property name"
                        />

                        <div className="create-form-label">Property Value</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          value={attributes.propertyValue}
                          onChange={(e) => handleAttributeChange(e, "propertyValue")}
                          placeholder="Enter the property value"
                        />

                        <div className="create-form-label">User ID (Optional)</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          value={attributes.userId}
                          onChange={(e) => handleAttributeChange(e, "userId")}
                          placeholder="Enter user ID to set property for a specific user (optional)"
                        />
                      </div>
                    )}

                    {sbNodeType === "response_message" && (
                      <div>
                        <div className="create-form-label">Node Name</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name='name'
                          placeholder="Enter the property name"
                          ref={nameRef}
                        />

                        <div className="create-form-label">Message</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name='message'
                          placeholder="Enter the property value"
                          ref={messageRef}
                        />
                      </div>
                    )}
                    {(sbNodeType === "terminate_chat" ||
                      sbNodeType === "forward_to_operator")&& (
                      <div>
                      </div>
                    )}
                    {sbNodeType === "set_user_property" && (
                      <div>
                        <div className="create-form-label">Node Name</div>
                        <input
                          type="text"
                          name='name'
                          style={{ width: "90%", padding: "7px" }}
                          placeholder="Enter Node Name"
                          ref={nameRef}
                        />
                      
                        <div className="create-form-label">Variable Name</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name="message"
                          placeholder="Enter variable name..."
                          ref={messageRef}
                        />
                      </div>
                    )}


                    {(sbNodeType === "create_ticket") && (
                      <div>
                        <div className="create-form-label">Node Name</div>
                        <input
                          type="text"
                          name='name'
                          style={{ width: "90%", padding: "7px" }}
                          placeholder="Enter Node Name"
                          ref={nameRef}
                        />

                        <div className="create-form-label">Summary Variable</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name='summary' 
                          placeholder="Enter variable name..."   
                          onChange={(e) => handleAttributeChange(e,"summary")}
                          value={attributes.summary}                      
                        />
                        <div className="create-form-label">Description Name</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name="description"
                          placeholder="Enter variable name..."
                          onChange={(e) => handleAttributeChange(e,"description")}
                          value={attributes.description}
                        />
                      </div>
                    )}

                    { (sbNodeType === "resolve_ticket" ||
                      sbNodeType === "reopen_ticket"  ||
                      sbNodeType === "escalate_ticket") && (
                      <div>
                        <div className="create-form-label">Node Name</div>
                        <input
                          type="text"
                          name='name'
                          style={{ width: "90%", padding: "7px" }}
                          placeholder="Enter Node Name"
                          ref={nameRef}
                        />

                        <div className="create-form-label">Ticket Id Variable</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name='ticketId' 
                          placeholder="Enter variable name..."   
                          onChange={(e) => handleAttributeChange(e,"ticketId")}
                          value={attributes.ticketId}                      
                        />
                      </div>
                    )}


                    {sbNodeType === "trigger_api" && (
                        <div>
                            <div className="create-form-label">Node Name</div>
                            <input
                                type="text"
                                name='name'
                                style={{ width: "90%", padding: "7px" }}
                                placeholder="Enter Node Name"
                            />
                        
                            <div className="create-form-label">Search APIs</div>

                            <input
                                type="text"
                                style={{ width: "90%", padding: "7px" }}
                                name="placeholder"
                                placeholder="Search APIs..."
                                value={searchInput}
                                onChange={handleSearchInputChange}
                            />
                          <div style={{position:'relative'}}>   
                            {selectedAPIs.length > 0 && (
                                <div className="dropdown" style={{ position: 'absolute',  width: '90%', zIndex: '100',boxShadow: '0 0 10px 10px #eeeeee',maxHeight:'100px', overflowY:'auto' }}>
                                    {selectedAPIs.map((api) => (
                                        <div
                                            key={api.id}
                                            style={{
                                                padding: '7px',
                                                backgroundColor: '#fff',
                                                
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => selectAPI(api)}
                                        >
                                            {api.name}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="create-form-label">Message</div>

                            <input
                                type="text"
                                style={{ width: "90%", padding: "7px" }}
                                name="message"
                                placeholder="Message..."
                                ref={messageRef}                                
                            />
                      </div>
                    )}

                    {sbNodeType === "free_text" && (
                      <div>
                        <div className="create-form-label">Node Name</div>
                        <input
                          type="text"
                          name='name'
                          style={{ width: "90%", padding: "7px" }}
                          placeholder="Enter Node Name"
                          ref={nameRef}
                        />

                        <div className="create-form-label">Message</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name='message'                          
                          placeholder="Enter tMessage..."
                          ref={messageRef}
                        />
                        <div className="create-form-label">Placeholder Text</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name="placeholder"
                          placeholder="Enter placeholder text..."
                          onChange={(e) => handleAttributeChange(e,"placeholder")}
                          value={attributes.placeholder}
                        />
                        <div style={{marginTop:'10px'}}>
                          <input
                            type="checkbox"
                            style={{ width: "20px", padding: "7px" }}
                            name="mandatory"
                            onChange={(e) => handleAttributeChange(e,"mandatory")}
                            value={(attributes.mandatory === true)}
                          /><span className="create-form-label">Mandatory Field</span>
                        </div>
                        <div className="create-form-label">Set value to a variable</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}                                            
                          placeholder="Variable name"
                          name="user_property"
                        />                          
                      </div>
                    )}


                    {sbNodeType === "free_text_area" && (
                      <div>
                        <div className="create-form-label">Node Name</div>
                        <input
                          type="text"
                          name='name'
                          style={{ width: "90%", padding: "7px" }}
                          placeholder="Enter Node Name"
                          ref={nameRef}
                        />

                        <div className="create-form-label">Message</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name='message'
                          onChange={(e) => handleAttributeChange(e, "propertyValue")}
                          placeholder="Enter tMessage..."
                          ref={messageRef}
                        />
                        <div className="create-form-label">Placeholder text</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name="placeholder"
                          placeholder="Enter placeholder text..."
                          onChange={(e) => handleAttributeChange(e,"placeholder")}
                          value={attributes.placeholder}
                        />
                         <div style={{marginTop:'10px'}}>
                          <input
                            type="checkbox"
                            style={{ width: "20px", padding: "7px" }}
                            name="mandatory"
                            onChange={(e) => handleAttributeChange(e,"mandatory")}
                            value={(attributes.mandatory === true)}
                          /><span className="create-form-label">Mandatory Field</span>
                        </div>
                      <div className="create-form-label">Set value to a variable</div>
                      <input
                        type="text"
                        style={{ width: "90%", padding: "7px" }}                                            
                        placeholder="Variable name"
                        name="user_property"
                      />                          
                      </div>
                    )}

                    {sbNodeType === "response_article" && (
                      <div>
                        <div className="create-form-label">Node Name</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name='name'
                          placeholder="Enter the property name"
                        />

                        <div className="create-form-label">Message</div>
                        <input
                          type="text"
                          style={{ width: "90%", padding: "7px" }}
                          name='message'
                          placeholder="Enter the Message..."
                        />

                        <div className="create-form-label">Attach Articles (Optional)</div>
                        <input
                          type="number"
                          style={{ width: "90%", padding: "7px" }}
                          value={attributes.numArticles}
                          onChange={(e) => handleAttributeChange(e, "numArticles")}
                          placeholder="Enter Number of Articles"
                        />

                      {Array.from({ length: selectedArticles.numArticles }, (_, index) => (
                            <div key={index} style={{ position: 'relative' }}>
                              <div className="create-form-label">Attach Article {index + 1}</div>
                              <input
                                type="text"
                                style={{ width: "90%", padding: "7px" }}                                
                                onChange={(e) => searchArticles(e, index)}
                                placeholder={`Search and select Article ${index + 1}`}
                              />

                              {selectedArticles.suggestions[index]?.length > 0 && (
                                <div className="dropdown" style={{ position: 'absolute', top: '40px', width: '90%', zIndex: 100 }}>
                                  {selectedArticles.suggestions[index].map((article) => (
                                    <div
                                      key={article.id}
                                      style={{
                                        padding: '7px',
                                        backgroundColor: '#fff',
                                        border: '1px solid #ccc',
                                        cursor: 'pointer'
                                      }}
                                      onClick={() => selectArticle(index, article)}
                                    >
                                      {article.name}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                          </div>                       
                    )}

                    {sbNodeType === "decision_tree" && (
                            <div className="decision-maker">
                              <div className="create-form-label">Node Name</div>
                              <input
                                type="text"
                                style={{ width: "90%", padding: "7px" }}
                                name="name"
                                placeholder="Enter the property name"
                                ref={nameRef}
                              />

                              {conditions.map((condition, index) => (
                                <div key={index} className="condition" style={{ marginTop: "10px" }}>
                                  {/* Show logical operator before each condition except the first */}
                                  {index > 0 && (
                                    <div style={{ marginBottom: "10px" }}>
                                      <label>Logical Operator Between Conditions:</label>
                                      <select value={logicOperator} onChange={(e) => setLogicOperator(e.target.value)}>
                                        <option value="AND">AND</option>
                                        <option value="OR">OR</option>
                                      </select>
                                    </div>
                                  )}
                                  
                                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <input
                                      type="text"
                                      placeholder="Variable"
                                      value={condition.variable}
                                      onChange={(e) => updateCondition(index, 'variable', e.target.value)}
                                      style={{ width: '30%' }}
                                    />
                                    <select
                                      value={condition.operator}
                                      onChange={(e) => updateCondition(index, 'operator', e.target.value)}
                                      style={{ width: '20%' }}
                                    >
                                      <option value="=">=</option>
                                      <option value=">">></option>
                                      <option value="<">&lt;</option>
                                      <option value="contains">contains</option>
                                    </select>
                                    <input
                                      type="text"
                                      placeholder="Value"
                                      value={condition.value}
                                      onChange={(e) => updateCondition(index, 'value', e.target.value)}
                                      style={{ width: '30%' }}
                                    />
                                    <button type="button" onClick={() => removeCondition(index)}>Remove</button>
                                  </div>
                                </div>
                              ))}

                              <button type="button" onClick={addCondition} style={{ marginTop: "10px" }}>
                                Add Condition
                              </button>
                            </div>
                    )}

                <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
                  <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}}>{isEdit() ? 'Update Node' : 'Create Node'}</button>
                  <span class="spinner-border" role="status" style={{position:'relative',top:'10px',right:'75px', color: 'black', height:'25px', width:'25px',zIndex:'100'}} ref={spinnerRef}/>
                </div>
             </div>
          </form>
          </div>

    );
}

export default NodePanel;