import React, { useState, useRef, useEffect } from "react";
import '../css/draggable.css'
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const DraggableTable = (props) => {

  let ticketSystemNames=["Summary", "Reporter", "Description", "Team","Assignee", "Priority",
          "Stataus", "Type"]
  let contactSystemNames = ["Full Name", "Email", "Contact Number","Work Phone","Unique Id","Company",
           "Address", "Notes"]
  let companySystemFields = ["Name", "Email", "Phone Number", "Company Number", "WorkPhone","Website",
      "Address","Notes"]
  let agentSystemFields = ["Full Name","Email", "Mobile Number",  "WorkPhone", "Role", "Timezone","Address"]

  const [systemNames, setSystemNames] = useState(null)
  const [rows, setRows] = useState([])
  const dropdownRef = useRef(null)



  useEffect(()=>{

      if(props.name === 'Tickets')
          setSystemNames(ticketSystemNames)
      else if(props.name === 'Agents')
          setSystemNames(agentSystemFields)
      else if(props.name === 'Contacts')
          setSystemNames(contactSystemNames)
      else if(props.name === 'Company')
          setSystemNames(companySystemFields)
      setRows(props.rows)
  },[props.rows])
  


  const draggedRowRef = useRef(null);
  const handleDragStart = (event, index) => {
    draggedRowRef.current = index;
    event.target.classList.add("dragging");
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
    const draggedOverRowIndex = index;

    if (draggedRowRef.current === draggedOverRowIndex) {
      return;
    }

    const updatedRows = [...rows];
    const draggedRow = updatedRows[draggedRowRef.current];
    updatedRows.splice(draggedRowRef.current, 1);
    updatedRows.splice(draggedOverRowIndex, 0, draggedRow);

    setRows(updatedRows);
    draggedRowRef.current = draggedOverRowIndex;
  };

  const handleDragEnd = (event) => {
    event.target.classList.remove("dragging");
    draggedRowRef.current = null;
  };
  function displayName(fieldName){
      if(systemNames.includes(fieldName)){
        return <span><div style={{width:'150px',maxWidth:'150px', display: 'inline-block'}} class='add-ellipsis'>{fieldName} </div> <span style={{backgroundColor:'#6e786c', color:'white',padding:'2px 10px',fontSize:'14px'}}>System</span></span>
      }else
        return <div style={{width:'250px', maxWidth:'250px',display: 'inline-block'}} class='add-ellipsis'>{fieldName} </div>
  }

  function fetchActive(fieldName, active){
    if(systemNames.includes(fieldName))
        return <span>Yes</span>
    else
      return (active === 'true') ? 'Yes' : 'No'
  }

  function displayCustomField(index){
    if(rows[index].element)
      props.displayCustomField(rows[index].element)
  }

  function toggleDropdown() {      
      dropdownRef.current.style.display = dropdownRef.current.style.display === 'block' ? 'none' : 'block';
  }

function editItem() {
  alert('Edit option clicked');
  // Add your edit functionality here
}

function deleteItem() {
  alert('Delete option clicked');
  // Add your delete functionality here
}


function checkBoxClicked(event){
  props.checkBoxClicked(props.customField.id, event.target.checked)
}


return (
  <div style={{position:'absolute', left:'275px', width:'calc(95% - 275px)', height:'100%',overflowY:'auto'}}>
    <table class="table table-nowrap align-middle table-borderless alternate-border-color" >
      <thead>
        <tr>
          <th></th>
          <th style={{backgroundColor : 'rgb(244, 244, 244)'}}>Agent Portal Label</th>          
          <th style={{backgroundColor : 'rgb(244, 244, 244)'}}>Customer Portal Label</th>
          <th style={{backgroundColor : 'rgb(244, 244, 244)'}}>Type</th>
          <th style={{backgroundColor : 'rgb(244, 244, 244)'}}>Active</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr
            key={row.id}
            draggable
            onDragStart={(event) => handleDragStart(event, index)}
            onDragOver={(event) => handleDragOver(event, index)}
            onDragEnd={handleDragEnd}             
          >
            <th scope="row" class="ps-4" style={{width:'50px'}}>
               <div class="form-check font-size-16"><input type="checkbox" class="form-check-input custom-field-checkbox" onClick={checkBoxClicked} name={'checkbox-'+row.id} style={{border:'1px solid #A9A9A9'}} disabled={!!systemNames.includes(row['apLabel'])} /></div>
            </th>
            <td style={{cursor:'pointer',width:'250px'}}><a href='#' onClick={()=> displayCustomField(index)} style={{cursor:'pointer',width:'250px'}}>{displayName(row['apLabel'])}</a></td>
            <td>{row.CP}</td>
            <td style={{color: 'black',fontWeight:'600'}}>{row.type}</td>                      
            <td>{fetchActive(row['apLabel'], row.active)}</td>          
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  );
};

export default DraggableTable;
