import React, { useState, useRef, useEffect } from 'react';
import '../css/draggable.css'
function DynamicForm(props)  {
   

  const handleChange = (e, fieldId) => {    
    const { name, value, type, checked } = e.target;
    props.setFormData((prevData) =>
      prevData.map((field) => {
        if (field.id === fieldId) {              
          if (type === 'checkbox') {
            const customType = e.target.getAttribute('data-custom-type')            
            if(customType === 'checkbox-group'){              
              const selectedValues = field.formValues;
              if (checked) {
                return {
                  ...field,
                  formValues: selectedValues+";"+value,
                };
              } else {
                return {
                  ...field,
                  formValues: selectedValues.split(value).join(''),
                };
              }
            }else if (field.type === 'checkbox') {
                return { ...field, formValues: checked };
          }
        }else {
            return {
              ...field,
              formValues: value,
            };
          }
       }
       return field;
      })
    );
  };


    return (
<>        
     {props.customFields?.map((field, index) => (
        <div key={index} style={{ marginTop: "3%" }}>
          <label>
            {field.type !=='checkbox' ? field.apLabel : ''}
           <span style={{color:'red'}}> {(field.apMandatorySubmission === 'true') && " *"}</span>
          </label>
          <br />
          {field.type === "textbox" || field.type === "number" || field.type === "email" || field.type === "date" || field.type === "password" ? (
            <input
              type={field.type}              
              name={field.apLabel}
              placeholder={field.placeholder === 'null' ? '' : field.placeholder}
              required={field.apMandatorySubmission}
              style={{width: "90%"}}
              onChange={(e) => handleChange(e, field.id)}
              value={props.formData.find((f) => f.id === field.id)?.formValues || ''}
              disabled = {field.editable}
            />
          ): field.type === "datetime" ? (
            <input
              type='datetime-local'
              name={field.apLabel}
              placeholder={field.placeholder}
              required={field.apMandatorySubmission}
              style={{width: "90%"}}
              onChange={(e) => handleChange(e, field.id)}
              value={ props.formData.find((f) => f.id === field.id)?.formValues || ''}
            />
          ) : field.type === "dropdown" ? (
            <select name={field.apLabel} style={{width: '90%',padding: '5px',borderRadius:'5px'}} 
                  onChange={(e) => handleChange(e, field.id)}
                  value={props.formData.find((f) => f.id === field.id)?.formValues || ''}>
              <option value="">Select {field.label}</option>
              {field.values.split(";").map((option, idx) => (
                <option key={idx} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ): field.type === "checkbox" ? (
          <>
            <input
              type="checkbox"
              name={field.apLabel}
              required={field.required}
              data-custom-type={field.type} 
              onChange={(e) => handleChange(e, field.id)}  
              checked={props.formData.find((f) => f.id === field.id)?.formValues}                       
            /><span style={{marginLeft:'5px'}}> {field.apLabel} </span>
          </>
          ): field.type === "checkbox-group" ? (
            field.values.split(";").map((option, idx) => (
              <span key={idx} style={{marginTop:'3%', verticalAlign:'center', marginLeft:'5px'}}>
                <input
                  type="checkbox"
                  data-custom-type='checkbox-group'
                  name={option}
                  value={option}                   
                  checked={props.formData.find((f) => f.id === field.id)?.formValues?.split(';').includes(option)}
                  style={{verticalAlign:'middle',marginLeft:'5px'}}  
                  onChange={(e) => handleChange(e, field.id)}                 
                />
               <span style={{marginLeft:'5px', fontSize:'14px'}}> {option}</span>
              </span>
            ))
          ) : field.type === "radio" ? (
            field.values.split(";").map((option, idx) => (
              <span key={idx} style={{marginTop:'3%',  marginLeft:'5px'}}>
                <input
                  type="radio"
                  name={field.apLabel}
                  value={option} 
                  checked={props.formData.find((f) => f.id === field.id)?.formValues === option}
                  style={{verticalAlign:'middle',marginLeft:'5px'}}  
                  onChange={(e) => handleChange(e, field.id)}             
                />
               <span style={{marginLeft:'5px',fontSize:'16px',fontWeight:'500'}}> {option}</span>
              </span>
            ))
          ): field.type === "textarea" ? (
            <textarea
              row= '4'
              name={field.apLabel}
              required={field.required}
              style={{width: "90%", height: "100px",color:'black'}}
              value={props.formData.find((f) => f.id === field.id)?.formValues || ''}
              onChange={(e) => handleChange(e, field.id)}
            />
          ) 
          : null}
        </div>
      ))}
</>
    );
};

export default DynamicForm;
