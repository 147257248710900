import React from "react"
import {useState, setState, useRef, useEffect} from 'react';

function AuthorizationCheck(){

let adminUrls = ["admin/phone_numbers","admin/call_routes","admin/ivr","/admin/sla","/admin/business_hours","/admin/subscriptions"]
let sess = JSON.parse(sessionStorage.getItem("ss_sess"))

if((sess !== null) && (sess["role"].toUpperCase() === 'agent'.toUpperCase())){
	let url = window.location.href;
	for(var i=0; i<adminUrls.length;i++){
		if(url.includes(adminUrls[i])){
			window.location.href='/'
		}
	}
}

return(<></>)
}

export default AuthorizationCheck;