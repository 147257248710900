import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'


function BotLine(props){

 const navigate = useNavigate(); 
 const [sessionData, setSessionData] = useState(null)
  

function editBot(){
		props.editBot(props.bot.id)
}
function checkBoxClicked(event){
	props.checkBoxClicked(props.bot.id, event.target.checked)
}

let marginTopIndex = (props.index+3)+'px'
return (
	<tr>
	  	 <th scope="row" class="ps-4" style={{width:'50px'}}>
	  		<div class="form-check font-size-16"><input type="checkbox" onClick={checkBoxClicked} class='form-check-input chat-bot-line-checkbox' name={'checkbox-'+props.bot.id} style={{border:'1px solid #A9A9A9'}}/></div>
	  	 </th> 
	    <th scope="row" class="ps-4" style = {{width: "300px", cursor: 'pointer', color: '#0052cc',fontWeight:'800',fonFamily:'Helvetica'}} onClick = {editBot}>{props.bot.name}</th>
	    <td>{props.bot.count}</td>
	 </tr>
   );

}

export default BotLine;