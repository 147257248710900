import React, { useState, useEffect,useRef } from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants';

function SLACondition({ index, condition, onConditionChange }) {
  const fromInputRef = useRef(null)
  const [firstSelect, setFirstSelect] = useState(condition.firstSelect || 'Created By');
  const [fromSelection, setFromSelection] = useState(condition.fromSelection || 'Contact');
  const [inputValue, setInputValue] = useState(condition.inputValue || '');
  const [fromValue, setFromValue] = useState(condition.fromValue || '');
  const INPUT_POS = 0
  const DROP_DOWN_POS = 1;
  const HIDDEN_INPUT = 2;
  const fromSelectionUrl = {
    Contact: constants.CONTACT_URL,
    Company: constants.COMPANY_URL,
    ContactGroup: constants.CONTACT_GROUP_URL,
    CompanyGroup: constants.COMPANY_GROUP_URL,
    User: constants.USER_URL,
    Team: constants.TEAM_URL,
  };

  useEffect(() => {
    console.log('useEffect  '+JSON.stringify(condition)+"    "+fromSelectionUrl[condition.fromSelection])
    if (condition && condition.fromValue) {
      axiosClient.getRequest(fromSelectionUrl[condition.fromSelection] + condition.fromValue)
        .then(response => {
          setInputValue(response.data.name);
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [condition]);


function addChildToFromDropdown(event,element,type){
  console.log(" addChildToFromDropdown "+element.email)
    const DROP_DOWN_POS_CURR = 1;
    const HIDDEN_FIELD_CURR = 2;
    console.log("addChildToFromDropdown "+ event.target.outerHTML)
    let fromConditionDropDownRef = event.target.parentElement.children[DROP_DOWN_POS_CURR]
      let aTag = document.createElement('a')
      aTag.name=element.id      
      aTag.innerHTML = (element.fullName === undefined ? element.name : element.fullName )
      aTag.onclick = (event) => onSelectFromValueDropdown(element.id, element.name)
      fromConditionDropDownRef.appendChild(aTag)
      fromConditionDropDownRef.style.display='inline'
}
  const fetchFromData = (event) => {
     fromInputRef.current.children[DROP_DOWN_POS].innerHTML = ''
     fromInputRef.current.children[DROP_DOWN_POS].style.display = 'none' 
    const keyword = event.target.value;
    setInputValue(keyword);
    if (keyword.length < 3) return;

      // let fromSelection = document.getElementsByName('fromSelect-'+event.target.name.split('-')[1])[0]
      console.log('fromSelection '+fromSelection)
      axiosClient.getRequest(fromSelectionUrl[fromSelection] +"search_by_name?keyword="+event.target.value)
      .then(function (response) {
        response.data.forEach((element, index)=>{
            addChildToFromDropdown(event,element,fromSelection.value)
          })    
      }).catch(function (response) {
          console.log(response)
          console.log(response.data.error)
      }); 
  };

  const handleFirstSelectChange = (e) => {
    const value = e.target.value;
    setFirstSelect(value);
    setFromSelection('');
    setInputValue('');
    setFromValue('');
    onConditionChange(index, { firstSelect: value, secondSelect: '', inputValue: '', fromValue: '' });
    if(value === 'Channel' || value === 'Type')
      fromInputRef.current.children[0].disabled=true
    else
      fromInputRef.current.children[0].disabled=false
  };

  const handleSecondSelectChange = (e) => {
    const value = e.target.value;
    setFromSelection(value);
    setInputValue('');
    setFromValue('');
    onConditionChange(index, { firstSelect, fromSelection: value, inputValue: '', fromValue: '' });
  };

  const onSelectFromValueDropdown = (id, name) => {
    setFromValue(id);
    setInputValue(name);
    onConditionChange(index, { firstSelect, fromSelection, inputValue: name, fromValue: id });
    fromInputRef.current.children[1].style.display = 'none'
    fromInputRef.current.children[1].innerHTML=''
  };

  const getSecondSelectOptions = () => {
    switch (firstSelect) {
      case 'Created By':
        return ['Contact', 'Company', 'Contact Group', 'Company Group'];
      case 'Assigned To':
        return ['User', 'Team'];
      case 'Channel':
        return ['Email Ticketing', 'Agent Portal', 'Customer Portal', 'Call', 'Chat'];
      case 'Type':
        return ['Incident', 'Service Request', 'Change Request', 'Payment', 'Escalation'];
      default:
        return [];
    }
  };

  return (
    <div style={{ paddingTop: '1px', display: 'flex' }}>
      {/* First Dropdown */}
      <div style={{ width: '200px', fontWeight: 'bold', paddingTop: '5px', fontSize: '16px' }}>
        Condition Type
        <select value={firstSelect} onChange={handleFirstSelectChange} className="sla-condition-input" style={{width: '200px'}}>
          <option value="Created By">Created By</option>
          <option value="Assigned To">Assigned To</option>
          <option value="Channel">Channel</option>
          <option value="Type">Type</option>
        </select>
      </div>

      {/* Second Dropdown */}
      <div style={{width: '200px', fontWeight: 'bold', marginLeft: '2%', paddingTop: '5px', fontSize: '16px' }}>
        Value
        <select value={fromValue} onChange={handleSecondSelectChange} className="sla-condition-input" disabled={!firstSelect} name= {'fromSelect-'+index} style={{width: '200px'}}>
          {getSecondSelectOptions().map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
      </div>
      <div style={{width: '60%', fontWeight: 'bold',position: 'relative', marginLeft: '2%',  paddingTop: '10px', fontSize: '16px' }} ref={fromInputRef}>
        Value
        <input
          value={inputValue}
          placeholder="Search ..."
          onChange={fetchFromData}
          className="sla-condition-input"
          name={'fromInput-'+index+'-hidden'}
          style={{width: '60%'}}
        />
        <div class="sla-condition-dropdown-content" style={{display: 'none' }}/>
      </div>      
    </div>
  );
}

export default SLACondition;
