import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the Permissions Context
const PermissionsContext = createContext();

// Permissions Provider
export const PermissionsProvider = ({ children }) => {
    const [permissions, setPermissions] = useState([]);
    const [isAdmin, setIsAdmin] = useState([])
    const [role, setRole]=useState(null)
    useEffect(()=>{
        let sess = JSON.parse(sessionStorage.getItem('ss_sess'));
        setIsAdmin(sess.isAdmin)
        if(sess.permissions)
        setPermissions(sess.permissions.split(";")); 
        setRole(sess.role)
    },[])

    const isActionAllowed = (action) => {
        if(isAdmin) return true;
        return permissions.includes(action)
    };
    const isAgent = () => {
        return role.toLowerCase() === 'agent';
    };
    const isMenuVisible = (menu) => {
        console.log('isMenuVisible '+isAdmin)
        if(isAdmin === 'true') return true;
        if(menu === 'Contact'){
            return isActionAllowed('VIEW_CONTACT') || isActionAllowed('VIEW_COMPANY') || 
                    isActionAllowed('VIEW_CONTACT_GROUP') || isActionAllowed('VIEW_COMPANY_GROUP')
        } else if(menu === 'Ticket'){
            return isActionAllowed('VIEW_TICKET')
        }else if(menu === 'Call'){

            return isActionAllowed('VIEW_CALL')
        } else if(menu === 'Agent'){
            return isActionAllowed('VIEW_AGENT')
        } else if(menu === 'Admin'){
            return isActionAllowed('VIEW_PHONE_NUMBER') ||
                   isActionAllowed('VIEW_CALL_ROUTE') ||
                   isActionAllowed('VIEW_IVR') ||
                   isActionAllowed('VIEW_SLA') ||
                   isActionAllowed('VIEW_BUSINESS_HOURS') ||
                   isActionAllowed('VIEW_CUSTOM_ROLE') ||
                   isActionAllowed('VIEW_SUBSCRIPTION_DETAILS')||
                   isActionAllowed('VIEW_KB')
        }  

    };

    return (
        <PermissionsContext.Provider value={{ isActionAllowed, isMenuVisible, isAgent }}>
            {children}
        </PermissionsContext.Provider>
    );
};


export const usePermissions = () => {
    return useContext(PermissionsContext);
};
