import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate, Redirect} from "react-router-dom"

function Dropdown(props){
    const [show, setShow] = useState(false)
    const [options, setOptions] = useState([])

    const menuRef = useRef(null)
     const groupRef = useRef(null)

     useEffect(()=>{
      let optionsList = props.payload.names.map((element, index) => (        
              <option  value={element} style={{height:'15px',width:'15px'}} selected>{element}</option>
          ))
      setOptions(optionsList)
     },[])

     function displayMenu(){
        console.log('displayMenu')
        menuRef.current.style.display='block'
    }
    function hideMenu(){
        console.log('hideMenu')
        menuRef.current.style.display='none'
    }

     
	return(
    <>
        <div class="form-group" onMouseOver={displayMenu} onMouseLeave={hideMenu} style={{position:'relative'}}>
            <div class="icons" style={{display:'block',transition:'opacity 0.3s',position:'absolute',top:'10px',right:'10px'}} ref={menuRef}>
                <a class="fas fa-edit icon" title="Edit" onClick={()=> props.displayDDEditWindow(props.index)}>edit</a>
                <a class="fas fa-trash-alt icon" title="Delete" onClick={()=>props.deleteIndex(props.index)}>delete</a>
            </div>            
             <div class="form-check ">
                    <label class='create-form-label'>{props.payload.agentPortalName} </label>
                    <select >
                        {options}
                    </select>
            </div>
        </div>
          
    </>
		)

}

export default Dropdown