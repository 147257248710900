import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
function SubCategoryLine(props){
	const menuRef = useRef(null)
	function displayMenu(){
		menuRef.current.style.display = 'inline-block'
	}	

	function hideMenu(){
		menuRef.current.style.display = 'none'
	}

	function openSubCategory(){
		props.openSubCategory(props.element.name, props.element.description, props.element.id)
	}

	return (
	<>
		<div style={{borderRadius:'5px',marginTop:'20px',  padingLeft:'20px', padding:'5px', cursor: 'pointer'}} onMouseEnter={displayMenu} onMouseLeave={hideMenu} onClick={()=>props.displayArticles(props.element.articles)}>
				<div style={{display:'inline-block',width:'70%',fontSize:'14px',fontWeight:'500',color:'#212529'}} class='add-ellipsis'>{props.name}</div>
				<div style={{position:'absolute', right:'0px', display:'none'}} ref={menuRef}>
					 <Dropdown style={{verticalAlign:'center'}}>
					      <Dropdown.Toggle style={{backgroundColor:'white', border:'white',paddingTop:'0px'}}>
					       	<img src={require('../../../img/dots.png')} alt='' style={{ height: "20px"}}/>
					      </Dropdown.Toggle>

					      <Dropdown.Menu>					        
					        <Dropdown.Item onClick={openSubCategory}>Edit</Dropdown.Item>
					        <Dropdown.Item href="/article/new">Article</Dropdown.Item>
					      </Dropdown.Menu>
					    </Dropdown>		    								
				</div>				
		</div>								
    </>
	)
}

export default SubCategoryLine;