import React, { useState, useEffect } from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import { useLocation } from 'react-router-dom';
import '../css/ticket.css'
import TicketLine from "./TicketLine"
import CommentLine from "./CommentLine"
import CreateTicketForm from './CreateTicketForm'
import TicketHeader from './TicketHeader'
import TicketListFilterBox from './TicketListFilterBox'
import TicketListDisplayHeader from './TicketListDisplayHeader'
import TicketLeftWindow from './TicketLeftWindow'
import TicketMiddleBox from './TicketMiddleBox'
import Pagination from '../../Pagination'

function TicketsListDisplay(){
	const [count, setCount] = useState(null)
	const [loadFilter, setLoadFilter] = useState(null)
	const [filterBoxQueryPayload, setFilterBoxQueryPayload] = useState('')
	const [ticket, setTicket] = useState(null)
	const [commentLine, setCommentLine] = useState([])
	const [filter, setFilter] = useState('unresolved')
	const [pageNo, setPageNo] = useState(0)
	const [pageSize, setPageSize] = useState(25)	
	const [displayFilterBox, setDisplayFilterBox] = useState(false)
	const [ticketLine, setTicketLine] = useState(null)
	const [showCreateTicketForm, setShowCreateTicketForm] = useState(false)
	const location = useLocation();
	let changed = false;
	
	useEffect(() => {
		console.log('useEffect in TicketsListDisplay')
		const urlParams = new URLSearchParams(window.location.search);
		preDefinedFilterTickets(filter,pageNo)
		// if(urlParams.get("filter")===null || urlParams.get("filter")=== "unresolved")
		// 	preDefinedFilterTickets(filter,pageNo)
		// else{
		// 	let pn = (urlParams.get("pageNo") === null) ? 0 : urlParams.get("pageNo")
		// 	let fil = urlParams.get("filter")
		// 	setFilter(fil)			
		// 	setPageNo(pn)
		// 	handlePagination(pn) 
		// }		
	},[location.pathname])//use effect

function loadTickets(pn){
		setPageNo(pn)
		axiosClient.getRequest(constants.TICKET_URL+'?pageNo='+pn)
		.then(function (response) {
			let ticketList = response.data.map((element, index) => (					
							<TicketLine ticket= {element} displayTicketQuickView={displayTicketQuickView}/>					           
			));
			if(Object.keys(ticketList).length === 0){
				setCount(0)
				setTicketLine('No Records Found...')
			}else{
				setTicketLine(ticketList)
				let countTemp = response.data[0].count;
				setCount(countTemp)
			}
			setQueryParam(filter, pn)			
		}).catch(function (response) {
		    console.log(JSON.stringify(response));
		});	
}

function handleFilterSubmit(payload,pn){
	setPageNo(pn)
	setFilterBoxQueryPayload(payload)
	localStorage.setItem('ticketFilter',payload)
	axiosClient.postRequest(constants.TICKET_URL+'apply_filter?pageNo='+pn,payload)
	.then(function (response) {
		let ticketList = response.data.map((element, index) => (					
						<TicketLine ticket= {element} displayTicketQuickView={displayTicketQuickView}/>					           
		));
		if(Object.keys(ticketList).length === 0){
			setCount(0)
			setTicketLine('No Records Found...')
		}else{
			setTicketLine(ticketList)
			let countTemp = response.data[0].count;
			setCount(countTemp)
		}
		setQueryParam(filter, pn)
	}).catch(function (response) {
	    console.log(response);
	});  
}
function closeCreateWindow(){
	setShowCreateTicketForm(false)
}

function setQueryParam(type, number){
	let queryParams = new URLSearchParams(window.location.search);
	queryParams.set('filter', type);
	queryParams.set('pageNo', number);
	window.history.pushState(null, null, "?" + queryParams.toString());
}

function preDefinedFilterTickets(type,pn){
	setPageNo(pn)
	axiosClient.getRequest(constants.TICKET_URL+"filter?type="+type+'&pageNo='+pn)
		.then(function (response) {
			let ticketList = null;
			ticketList = response.data.map((element, index) => (					
							<TicketLine ticket= {element} displayTicketQuickView={displayTicketQuickView}/>
			));
			if(Object.keys(ticketList).length === 0){
				setCount(0)
				setTicketLine('No Records Found...')
			}else{
				setTicketLine(ticketList)
				let countTemp = response.data[0].count;
				setCount(countTemp)
			}
			setFilter(type)
			setQueryParam(type, pn)
			setFilterBoxQueryPayload('')
			setDisplayFilterBox(false)
	}).catch(function (response) {
		    console.log(response);
	});
}

function handlePagination(number){	
	setPageNo(number)
	if(filterBoxQueryPayload === '' && filter === 'all')
		loadTickets(number)		
	else if(filterBoxQueryPayload === '' && filter !== 'all')
		preDefinedFilterTickets(filter,number)
	else
		handleFilterSubmit(filterBoxQueryPayload,number)	
}

function showCreateTicketFormFun(){
	setShowCreateTicketForm(true)
}

function displayFilterBoxFun(){
	setDisplayFilterBox(!displayFilterBox)
}
function closeFilterBox(){
	setDisplayFilterBox(false)
}

function displayTicketQuickView(id){
	// console.log('dispayTicketQuickView '+id)
	// return
	// 	axiosClient.getRequest(constants.TICKET_URL+id)
	// 	.then(function (response) {
	// 		setTicket(response.data)
	// 		setCommentLine(response.data.comments)
	// 	}).catch(function (response) {
	// 	    console.log(response);
	// 	});		
}
	return (
	<>
		<div class='ticket-quick-view'>
          {ticket === null ? '' : <TicketHeader ticket={ticket}/> }
          {ticket === null ? '' : <TicketLeftWindow ticket={ticket} contact={ticket.reporter_contact}/> }
          {ticket === null ? '' : <TicketMiddleBox ticket={ticket}  /> }
          {
            commentLine.map((comment,index) => 
              <CommentLine comment={comment} content={comment.content} />
            )
          }		
		</div>
		<TicketListDisplayHeader  showCreateTicketFormFun={showCreateTicketFormFun} displayFilterBoxFun={displayFilterBoxFun}
					preDefinedFilterTickets={preDefinedFilterTickets}/>	
		<div class="main-body">
			<div style={{display: 'flex', flexDirection:'row'}}>
				{
		   			displayFilterBox ?
		   					<TicketListFilterBox handleFilterSubmit={handleFilterSubmit} closeFilterBox={closeFilterBox}/>
		   			: ''
		   		}
		   	  <div style={{width: '100%'}}>
		   		<div class="container" style={{minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
		         <div class="row">
		            <div class="col-lg-12">
		               <div class>
		                  <div class="table-responsive">
		                     <table class="table table-nowrap align-middle table-borderless">
		                        <thead>
		                           <tr>		                           	  
		                           	  <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Reported By</th>
		                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Summary</th>		                              
		                              <th scope="col" style={{backgroundColor:'#f4f4f4', textAlign:'center'}}>Assignee</th>
		                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Status</th>
		                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Priority</th>
									  <th scope="col" style={{backgroundColor:'#f4f4f4'}}>First Response</th>                              
		                           </tr>
		                        </thead>
		                        <tbody>
		                        	{ticketLine}                    
		                        </tbody>
		                     </table>
		                  </div>
		               </div>
		            </div>
		         </div>         
		      </div>
		    </div>
				{ showCreateTicketForm ? <CreateTicketForm closeCreateWindow= {closeCreateWindow}/> : '' }
			</div>
		   </div>
			<Pagination count={count} pageNo={pageNo}
				   					 handlePagination={handlePagination}/>			
		</>
		);

	}
export default TicketsListDisplay;