import React, { useState, useEffect, useRef } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../../js/constants'
import { usePermissions } from '../../../PermissionsProvider';

function BusinessCalenderLine(props){
	const { isActionAllowed } = usePermissions();
	const navigate = useNavigate(); 

	function openBusinessCalender(){
		if(isActionAllowed('EDIT_BUSINESS_HOURS'))
			navigate(constants.BUSINESS_HOURS_URL_ABS+props.businessCalender.id)
	}

	function checkBoxClicked(event){
		props.onCheckboxClicked(props.businessCalender.id, event.target.checked)
	}
return (
	        <tr >
	            <td style = {{width: "1%"}}><input type="checkbox" onChange={checkBoxClicked} class='business-line-checkbox' name={'checkbox-'+props.businessCalender.id}/></td>
	            <td onClick = {openBusinessCalender} style = {{width: "2%"}}><a href="#" style = {{ textDecoration: 'underline', cursor: 'pointer',fontWeight:'800',fonFamily:'Helvetica'}} >{props.businessCalender.name}</a></td>
	            <td style = {{width: "1%"}}>{props.businessCalender.is24HourCalender ? 'Yes' : 'No'}</td>
	            <td style = {{width: "2%"}}>{props.businessCalender.timezone}</td>
	         </tr>
            );

}

export default BusinessCalenderLine;