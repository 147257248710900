
import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../../../js/Axios';
import * as constants from '../../../../../js/constants'
import CallCountDashboard from './CallCountDashboard'

function CallDashboard(){
	const [dropdownValue, setDropDownValue] = useState('Today')
	const dropdownRef = useRef(null)
	const [data,setData] = useState([])
	const DAY = 'day'
	let loadComponent = true

	useEffect(() => {
		if(loadComponent){
			loadComponent = false;
		loadDashboard(DAY)	
	}
	
},[])//use effect
function loadDashboard(durationParam){
	console.log('loadDashboard')
	axiosClient.getRequest(constants.CALL_URL+'dashboard?duration='+durationParam)
	.then(function (response) {
		setData(response.data)
	}).catch(function (response) {
	    console.log(response);
	});	
}
function displayDropdown(event){
	if(dropdownRef.current.style.display === 'none')
		dropdownRef.current.style.display = 'block'
	else
		dropdownRef.current.style.display = 'none'
}

function hideDropdown(event, type){
event.preventDefault()
	if(type === undefined){
		dropdownRef.current.style.display = 'none'
		return
	}
	setDropDownValue(type)
}

	return(<div style={{height:'100%', overflowY: 'auto',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;', margin: '0 30px'}}>	
		<div style={{position:'relative', display:'block',textDecoration:'underline',cursor:'pointer',fontSize:'14px',fontWeight:'600'}} onClick={displayDropdown} >{dropdownValue}
		  <div ref={dropdownRef} class='create-dropdown-content' style={{width:'300px',top:'30px', display:'none'}}>
			<div onClick={(event)=>hideDropdown(event, 'Today')}><a href=''>Today</a></div>
			<div onClick={(event)=>hideDropdown(event, 'This Week')}><a href=''>This Week</a></div>
			<div onClick={(event)=>hideDropdown(event, 'Last Seven Days')}><a href=''>Last Seven Days</a></div>
			<div onClick={(event)=>hideDropdown(event, 'This Month')}><a href=''>This Month</a></div>
			<div onClick={(event)=>hideDropdown(event, 'Last Thirty Days')}><a href=''>Last Thirty Days</a></div>
			<div onClick={(event)=>hideDropdown(event, 'Last Three Months')}><a href=''>Last Three Month</a></div>			
			<div onClick={(event)=>hideDropdown(event, 'This Year')}><a href=''>This Year</a></div>
			<div onClick={(event)=>hideDropdown(event, 'Date')}><a href=''>Custom Date</a></div>
		  </div>			
		</div>
		<CallCountDashboard data={"data"}/>

	</div>
		)
}
export default CallDashboard;