import React, { useEffect, useRef, useState} from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from "../../../../js/Axios"
import * as constants from '../../../../js/constants'
import {useNavigate} from "react-router-dom"


function PurchasePhoneNumber(props){
  const errorRef = useRef(null)
  const spinnerRef = useRef(null)
  const selectedCountryInputRef = useRef(null)
  const [countries, setCountries] = useState([])
  const numberTypeRef = useRef(null)
  const phoneNumberRef = useRef(null)
  const phoneNumberSelectRef = useRef(null)
  const countryDropDownRef = useRef(null)
  const numberDropdownRef = useRef(null)
  let countrySelected = '';
  let selectedType = ''
  let countryCodes = {'United States of America': 'US', "Canada": 'CA',"Virgin Islands, US": "VI"}
  useEffect(() => {
      spinnerRef.current.style.display='none'    
  },[])

  function displayCountries(value){
      console.log('')
      countryDropDownRef.current.innerHTML = ''
      for(var i=0;i< countries.length;i++){
          //country_name;country_code;is_local;is_mobile;is_tollfree
          let arr = countries[i].split(';')
          let aTag = document.createElement('a')
          aTag.name=countries[i]
          aTag.innerHTML = arr[0]
          aTag.onclick = selectCountry
          aTag.style.cursor = 'pointer'
          if(arr[0] !== null && arr[0].startsWith(value))
            countryDropDownRef.current.appendChild(aTag)
      }    
  }
  function displayCountryDropdown(event)  {
    console.log('displayCountryDropdown '+event.target.value)
    event.target.parentElement.children[0].value = ''
    event.target.parentElement.children[1].style.display= 'block'
    phoneNumberRef.current.style.display='none'
    phoneNumberSelectRef.current.innerHTML = ''
    numberTypeRef.current.style.display='none'
    countryDropDownRef.current.style.display = 'block'
  }

  function displayError(msg){
    errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
    errorRef.current.style.display='block'
    errorRef.current.scrollIntoView()
  }
  function handleSubmit(event){
    event.preventDefault()
    spinnerRef.current.style.display='inline-block'
    let payload = {'phoneNumber' : event.target.phoneNumber.value,
                    'type' : event.target.number_type.value,
                    'country' : selectedCountryInputRef.current.value,
                    'country_code':countryCodes[selectedCountryInputRef.current.value]
                  }
    axiosClient.postRequest(constants.TWILIO_URL+'purchase', payload)
    .then(function (response) {
      spinnerRef.current.style.display='none'
      props.closePhoneWindow();
      window.location.reload()      
    }).catch(function (error) {
        console.log(error)
        spinnerRef.current.style.display='none'
        if(error.response !== null && error.response.data !== null){
          displayError(error.response.data.error)
        }
    });     

  }
  function selectCountry(event){
    selectedCountryInputRef.current.value=event.target.innerHTML
    countryDropDownRef.current.style.display = 'none'
    numberTypeRef.current.style.display = 'block'
    if(event.target.innerHTML === 'Virgin Islands, US'){
      numberTypeRef.current.children[2].style.visibility='hidden'
    }else
      numberTypeRef.current.children[2].style.visibility='visible'
  }


function typeSelected(type,pageNo, pageSize){
    selectedType = type
    phoneNumberRef.current.style.display = 'block'
    let country = ''
    if(selectedCountryInputRef.current.value === 'United States of America')
      country = 'US' 
    else
      country = 'CA'
    phoneNumberSelectRef.current.innerHTML = '' 
    let queryParams = '?country='+country+'&type='+type+'&pageNo='+pageNo+"&pageSize="+pageSize
    axiosClient.getRequest(constants.TWILIO_URL+'fetch_phone_numbers'+queryParams)
    .then(function (response) {
      if(response.data === ''){
          let aTag = document.createElement('option')
          aTag.innerHTML = 'No Numbers Available'
          aTag.style.cursor = 'pointer'
          phoneNumberSelectRef.current.innerHTML = ''          
          phoneNumberSelectRef.current.appendChild(aTag)        
      }
      let phoneNumbersArr = response.data.split(",")
    
      console.log("Countries length "+ phoneNumbersArr.length)
      for(var i=0;i< phoneNumbersArr.length;i++){
          //country_name;country_code;is_local;is_mobile;is_tollfree
          let aTag = document.createElement('option')
          aTag.name=phoneNumbersArr[i]
          aTag.innerHTML = phoneNumbersArr[i]
          aTag.style.cursor = 'pointer'          
          phoneNumberSelectRef.current.appendChild(aTag)
      }
    }).catch(function (error) {
        if(error.response !== null){
          if(error.response)
            errorRef.current.innerHTML = error.response.data
          errorRef.current.style.display = 'block'
        }
    });     
}
  return (
    <div class='create-wrapper'>
      <form onSubmit={handleSubmit}>          
          <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'#F5F5F5F5',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
            <a href="javascript:void(0)" class="create-closebtn" onClick={props.closePhoneWindow}>&times;</a>
                <h8 style={{color:'#044caf',fontWeight:'800', fontSize:'20px'}}><b>Purchase Phone Number</b> </h8>
                <div class='create-line'/>
                <div class='create-form-label' style={{ marginTop: "3%"}}>Available Countries:<p class='red-color'>*</p></div>
                  <div style={{position:'relative' }}>
                      <input type="text"  style={{width: "90%"}} name="country" onChange={displayCountryDropdown} onClick={displayCountryDropdown} ref={selectedCountryInputRef}/>
                      <div style={{ maxHeight: '300px', overflowY: 'auto', position: 'absolute', display: 'none'}} class='create-dropdown-content' ref={countryDropDownRef}>
                        <div style={{cursor:'pointer', fontWeight: '600', padding:'10px'}} onClick={selectCountry}>United States of America</div>
                        <div style={{cursor:'pointer', fontWeight: '600', padding:'10px'}} onClick={selectCountry}>Virgin Islands, US</div>
                        <div style={{cursor:'pointer', fontWeight: '600', padding:'10px'}} onClick={selectCountry}>Canada </div>
                      </div>
                </div>                
                <div ref={numberTypeRef} style={{display: 'none' }}>
                  <div class='create-form-label' style={{ marginTop: "3%"}}>Number Types Available:<p class='red-color'>*</p></div>
                  <span style={{display:'inline'}}> Local: <input type="radio"  name="number_type" value="local" onChange={()=> typeSelected('local','0','10')} style={{verticalAlign:'middle'}}/></span>                  
                  <span style={{display:'inline', marginLeft:"3%"}}>TollFree: <input type="radio"  name="number_type" value="tollfree" onChange={()=> typeSelected('tollfree','0','10')} style={{verticalAlign:'middle'}}/></span>
                </div>
                <div  ref={phoneNumberRef} style={{display: 'none' }}>
                  <div class='create-form-label' style={{ marginTop: "3%"}}>Phone Numbers:<p class='red-color'>*</p></div>
                    <div style={{position:'relative' }}>
                    <select type="text"  style={{width: "90%", height: '35px'}} ref={phoneNumberSelectRef} name='phoneNumber'/>
                    <div  style={{ position: 'absolute', display: 'none'}} class='create-dropdown-content' ref={numberDropdownRef}/>
                  </div>
                </div>
              <button class='ss-button'  class= 'create-button'> Purchase </button>
          </div>
      </form>
      <div ref={errorRef} name="error"/>
      <div class="overlay" ref={spinnerRef}>
        <span class="spinner-border spinner-center" role="status" style={{height:'50px', width:'50px'}} />
      </div>

    </div>

    );
}

export default PurchasePhoneNumber;