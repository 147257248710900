import React from 'react';
import {useState, setState, useRef, useEffect} from 'react';
import ProfilePicture from '../../ProfilePicture/ProfilePicture'
import Attachment from './Attachment'
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import {useNavigate, useParams} from "react-router-dom"

function TicketHeader(props){
  const [edit, setEdit] = useState(false)
  const [summary, setSummary] = useState(null)
  const [description, setDescription] = useState(null)
  const [attachmentList, setAttachmentList] = useState([])
  const [removedAttachmentList, setRemovedAttachmentList] = useState([])
  const [attachmentListBackup, setAttachmentListBackup] = useState([])
  // const summaryRef = useRef(null)
  const commentRef = useRef(null)
  let spinnerRef = useRef(null)
  const navigate = useNavigate()
  let attachmentsLoaded = false
  // const [content, setContent] = useState(null)
  // const [comment, setComment] = useState(null)

  useEffect(()=>{
      if(!attachmentsLoaded)   {
        attachmentsLoaded = true
        if(props.ticket.attachments !== null){
          let attachmentNames = props.ticket.attachments
          let tempAttachmentList = attachmentList
          tempAttachmentList.push(...attachmentNames)
          setAttachmentList(tempAttachmentList)
          setAttachmentListBackup(tempAttachmentList)
        }
      }
        setDescription(props.ticket.description)
        setSummary(props.ticket.summary)
  },[])

function openContact(event){
  navigate(constants.CONTACT_URL_ABS+props.ticket.reporter_contact.id)
}
function cancelEdit(){
  setAttachmentList(attachmentListBackup)
  setEdit(false)
}
function addComment(){
  console.log('addComment '+attachmentList)
  let payload={description: commentRef.current.innerHTML,
      removedAttachmentList: removedAttachmentList,
      // summary: summaryRef.current.value
    }
      axiosClient.patchRequest(constants.TICKET_URL+'update_description/'+props.ticket.id,payload)
        .then(function (response) {
          setSummary(response.data.summary)
          props.setSummary(response.data.summary)
          setDescription(response.data.description)
          // summaryRef.current.inn
          // setComment(response.data)
          setEdit(false)
        }).catch((error) => {
      });  
}

function removeAttachmentFromEdit(attachmentId){
    console.log("removeAttachmentFromEdit "+JSON.stringify(attachmentList)+' '+attachmentId+' '+typeof attachmentList)
    var attachmentListArr = [...attachmentList];
    var index = -1;
    for(var i=0;i<attachmentListArr.length;i++){
      if(attachmentId === attachmentListArr[i].id)
        index=i
    }
    if(index === -1)
      return
    console.log('index is '+index)
    attachmentListArr.splice(index, 1);
    setAttachmentList(attachmentListArr);
    setRemovedAttachmentList([...removedAttachmentList, attachmentId])    
}

function renderReporterImage(){
   if(props.ticket['reporter_contact'] === null)
      return '-'
   if(props.ticket['reporter_contact'].deleted === true ||
      props.ticket['reporter_contact'].image === 'null'){
    return (<ProfilePicture name={props.ticket['reporter_contact'].name} classProp='comment-header-img' smallSize={false}/>)
   }
   return <img src = {props.ticket["reporter_contact"].image} class='comment-header-img'/>
}
function editButtonClicked(){
  setEdit(!edit)
}
function changeSummary(e){
  var code = (e.keyCode ? e.keyCode : e.which);
  if(code == 13) { //Enter keycode
      e.preventDefault()
  }

}

function fetchReporter(){
  if(props.ticket['reporter_contact'].deleted === 'true')
    return props.ticket.reporter_contact.name
  return <a   href={"/contacts/"+props.ticket['reporter_contact'].id} style={{ verticalAlign: 'middle', display:'inline-block', overflow: 'hidden',textOverflow: 'ellipsis',maxWidth:'200px',whiteSpace: 'nowrap',textDecoration: 'none',fontSize:'14px' }}>{props.ticket.reporter_contact.name}</a>
}
return (	
			<div class="ticket-comment">                
                 <div s>
                    {renderReporterImage()}
                    <div class='comment-edit-icon' >
                       <div style={{position: 'relative',left:'175px'}} class='comment-edit-icon'>
                          <img  src={require('../../../img/dots.png')} alt='' style={{height:'15px', width:'15px'}}/>
                          <div class='comment-edit-dropdown'>
                            <div style={{padding:'5px 10px'}} onClick={editButtonClicked}>Edit</div>
                          </div>
                       </div>
                    </div>
                    <div style={{display:'inline-block' }}>                                          
                      <div style={{fontSize : '14px',fontWeight:'500', color:'#4c4f56'}}><strong>Reported by {fetchReporter()}</strong></div>
                      <div style={{fontSize:'12px', color:'#4c4f56' }}>{props.ticket.created_date}</div>
                    </div>                                      
                </div>

            <div class="comment-header-info">
            { 
             !edit ?
                      <> 
                        <div  style={{fontSize:'16px',paddingLeft: '55px' }} dangerouslySetInnerHTML={{ __html: (description === '' ? '---' : description) }} />
                        <div style={{display: 'flex',flexWrap:'wrap'}}>  
                        {
                            attachmentList.map((attachment,index) => 
                                <Attachment index={index} isEditing={edit} name={attachment.name} 
                                removeAttachmentFromEdit={removeAttachmentFromEdit}
                                attachment_id={attachment.id} source={'comment'}
                                size={attachment.size} type={attachment.type}/>
                        )}
                        </div>
                      </>  
                   :
                <>
                  <div contenteditable='true' spellcheck='true' rows = '8' class="chatbox-input" placeholder="Type a message" ref={commentRef} id='editor' style={{fontWeight:'400',height:'250px',padding:'24px',fontSize:'17px',lineHeight:'1.5' }} ref={commentRef} dangerouslySetInnerHTML={{ __html: description }} />
                  <div style={{display: 'flex',flexWrap:'wrap'}}>                      
                      {
                        attachmentList.map((attachment,index) => 
                        <Attachment id={index} isEditing={edit} name={attachment.name}
                        removeAttachmentFromEdit={removeAttachmentFromEdit}
                        attachment_id={attachment.id} source={'ticket_header'}/>
                      )}
                  </div>
                  <div class="chatbibox-footer">
                     <ul style={{textAlign:'center',margin: 'auto'}}>
                      <li style={{textAlign:'center',margin: 'auto'}}>
                        <button class='ss-button'   onClick={cancelEdit}><img src={require('../../../img/paper-plane.png')} style={{textAlign: 'center'}}/> 
                          Cancel
                        </button>
                      </li>

                      <li style={{textAlign:'center',margin: 'auto'}}>
                        <button class='ss-button'   onClick={addComment}><img src={require('../../../img/paper-plane.png')} style={{textAlign: 'center'}}/> 
                          <span class="spinner-border" role="status" style={{color: 'white', height:'15px', width:'15px',position: 'relative',display: 'none' }} ref={spinnerRef}/>
                          Edit
                        </button>
                      </li>
                    </ul>
                  </div>
                 </>
               }
            </div>              
      </div>
            );

}

export default TicketHeader;