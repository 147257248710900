import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BotLine from './BotLine'
import ApiLine from './ApiLine'
import ApiInputComponent from './ApiInputComponent'


function ChatBotListDisplay(){
	const [pageSize, setPageSize] = useState(100)	
	const [botLine, setBotLine] = useState([])
	const [apiLine, setApiLine] = useState([])
	const [topicLine, setTopicLine] = useState([])
	const groupButtonsRef = useRef(null)	
	const [checkboxState, setCheckboxState] = useState(false)
	const [ids, setIds] = useState([])
	const [selectedCategory, setSelectedCategory] = useState('bots')
	const [displayCreateAPIWindow, setDisplayCreateAPIWIndow] = useState(false)
	const editApiRef = useRef(null)
	useEffect(() => {
		loadChatBots();	
		groupButtonsRef.current.style.visibility = 'hidden'
	},[])//use effect

function loadChatBots(){

	axiosClient.getRequest(constants.CHAT_BOT_URL)
	.then(function (response) {
		let botList = null;
		botList = response.data.map((element, index) => (									
						<BotLine bot = {element} editBot={editBot} checkBoxClicked={checkBoxClicked}/>
		));
		setBotLine((Object.keys(botList).length === 0  ? 'No Records Found...' : botList))
	}).catch(function (response) {
	    console.log(response);
	});	

	axiosClient.getRequest(constants.CHAT_BOT_API)
	.then(function (response) {
		let apiList = null;
		apiList = response.data.map((element, index) => (									
						<ApiLine bot = {element} editApi={editApi} checkBoxClicked={checkBoxClicked} />
		));
		setApiLine((Object.keys(apiList).length === 0  ? 'No Records Found...' : apiList))
	}).catch(function (response) {
	    console.log(response);
	});	
}


function checkBoxClicked(id, value){
	let idsTemp = ids;
	if(value){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.style.visibility = 'visible'
	}else{
		groupButtonsRef.current.style.visibility = 'hidden'
	}
	
}


function oncheckboxClicked(){
	setCheckboxState(!checkboxState)
}


function performAction(type){
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.CHAT_BOT_URL+'delete';
	else if(type === 'block')
		url = constants.CHAT_BOT_URL+'block';
	else if(type === 'spam')
		url = constants.CHAT_BOT_URL+'spam';

	//TODO: block becomes unblock
   	axiosClient.postRequest(url, payload)
       .then(function (response) {
           loadChatBots();
		let elements = document.getElementsByClassName('chat-bot-line-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				elements[i].checked = false;		
		} 
		toast.success("Bot Deleted Successfully")          
       }).catch((error) => {
           console.log(error)
       });
	groupButtonsRef.current.children[0].style.visibility = 'hidden' 
	     
}	

function editBot(id){
	window.location.href='/admin/bot/create/'+id
}
function editApi(id){
	editApiRef.current = id
	setDisplayCreateAPIWIndow(true)
}

function navigateToCreate(){
	if(selectedCategory === 'bots')
		window.location.href='/admin/bot/create'
	else
		setDisplayCreateAPIWIndow(true)
}

function closeCreateWindow(){
	setDisplayCreateAPIWIndow(false)	
}
function displayData(type){	
	setSelectedCategory(type)
}


  const renderTableData = () => {
    switch (selectedCategory) {
      case 'bots':
        return (
          <tbody>
          	{botLine}
          </tbody>
        );
      case 'topics':
        return (
          <tbody>
				{topicLine}
          </tbody>
        );
      case 'api':
        return (
          <tbody>
          	{apiLine}
          </tbody>
        );
      default:
        return null;
    }
  };

  function handleDelete(){

  }
	return (
	<>

		<div class='header-body'>
	    	<div class="main-header ">
	    		<div class='header-right'>
	    			<button class='ss-button' style={{float:'right'}} onClick={handleDelete} ref={groupButtonsRef}>Delete</button>
	    			<button class='ss-button' style={{float:'right'}} onClick={navigateToCreate}>Create</button>
	    		</div>
	    	</div>
	    </div>

		<div class="main-body">
			<div style={{padding:'10px',position:'absolute', left:'0px',width:'30%', borderRight:'1px solid #dee2e6',minWidth:'250px', height:'100%',overflowY:'auto', paddingLeft:'20px'}} >
				<div style={{marginTop:'20px', fontSize:'26px',fontWeight:'600'}}>Categories</div>
					<div onClick={()=>displayData('bots')} style={{cursor:'pointer'}}>Chat Bots</div>
					<div onClick={()=>displayData('api')} style={{cursor:'pointer'}}>APIs</div>
			</div>
			<div style={{padding:'10px',position:'absolute', left:'30%',width:'70%', borderRight:'1px solid #dee2e6',minWidth:'250px', height:'100%',overflowY:'auto', paddingLeft:'20px'}} >
				<div class="container" style={{minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
		         <div class="row">
		            <div class="col-lg-12">
		               <div class>
		                  <div class="table-responsive">
		                     <table class="table table-nowrap align-middle table-borderless">
		                        <thead>
		                           <tr>
		                              <th scope="col" class="ps-4" style={{width: "50px", backgroundColor:'#f4f4f4'}}>
		                                 <div class="form-check font-size-16"><input type="checkbox" class="form-check-input" style={{border: '1px solid #A9A9A9', height:'15px'}} /></div>
		                              </th>
		                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Name</th>
		                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Number of Agents</th>
		                           </tr>
		                        </thead>
		                        {renderTableData()}	    	              
		                     </table>
		                  </div>
		               	</div>
		              </div>
	         		</div> 
	      		</div>
	      	</div>
		</div>
		{displayCreateAPIWindow ? <ApiInputComponent closeCreateWindow={closeCreateWindow} editApiRef={editApiRef}/> : ' ' }
  </>
 );

}
export default ChatBotListDisplay;
