import React from "react"
import {useState, setState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../js/Axios';
import * as constants from '../../js/constants';
import ProfilePicture from '../ProfilePicture/ProfilePicture'
import '../../css/topnav.css'
import TopnavProfile from './TopnavProfile'
import TopnavProfileWindow from './TopnavProfileWindow'
import ProfileChangePassword from './ProfileChangePassword'
import CreateUserForm from '../User/js/CreateUserForm'
import SocketMain from '../SocketMain'
function TopNav(){
	const [name, setName] = useState('--')
	const nameRef = useRef(null)
	const changePasswordRef = useRef(null)
	const intervalRef = useRef(null)
	const statusRef = useRef(null);
	const [displayUserProfile, setDisplayUserProfile] = useState(false)
	const dropdownRef = useRef(null)
	const [sessionData, setSessionData] = useState(null)

	useEffect(()=>{		
		let sessJson = JSON.parse(sessionStorage.getItem('ss_sess'))
		if(sessJson.name === ''){
			nameRef.current.innerHTML = '*'
		}
		else{
			nameRef.current.innerHTML = sessJson.name.charAt(0).toUpperCase()
		}
		setName(sessJson.name)	
		setStatusColor(sessJson.status)
	},[])
var colors = {'0'  : ['#5c8af0', 'white'],
			  '1' : ['#660066', 'white'],
			  '2' : ['#0078d4', 'white'],
			  '3' : ['purple', 'white'],
			  '4' : ['#444791', 'white'],
			  '5'  :['#0078d4', 'white']
			}
var alphabets={'a':0,'b':1,'c':2,'d':3,'e':4,'f':5,'g':6,'h':7,'i':8,
	'j':9,'k':10,'l':11,'m':12,'n':13,'o':14,'p':15,'q':16,'r':17,'s':18,
	't':19,'u':20,'v':21,'w':22,'x':23,'y':24,'z':25,'0':0,'1':1,'2':2,'3':3,
	'4':4,'5':5,'6':6,'7':7,'8':8,'9':9
}
function findFirstCharIndex(str){
	if(str.charAt(0).toLowerCase().includes(alphabets))
		return (alphabets[str.charAt(0).toLowerCase()] % 6)
	return (alphabets['a'] % 6)
}	
function sessionStorageCheck(){
  clearInterval(intervalRef.current)
  intervalRef.current = null
  if(sessionStorage.getItem('ss_sess') === null  ){
	intervalRef.current = setInterval(sessionStorageCheck, 1000);
	return
  }
  let sessJson = JSON.parse(sessionStorage.getItem('ss_sess')) 
  setName(sessJson.name)
}
function displayTopnavMenu(){
	if( dropdownRef.current.style.display === 'none')
		dropdownRef.current.style.display = 'block'
	else
		dropdownRef.current.style.display = 'none'
}


function displayChangePassword(show){
	changePasswordRef.current.style.display='block'
	displayTopnavMenu()
}
function hideChangePassword(show){
	changePasswordRef.current.style.display='none'
}

function setStatusColorOnLoad(){
	let sessJson = JSON.parse(sessionStorage.getItem('ss_sess')) 
	setStatusColor(sessJson.status)
}
function setStatusColor(status){	
	if(status === null)
		return
		if(status.toLowerCase() === 'online')
			statusRef.current.style.color = 'green'		
		else if(status.toLowerCase() === 'busy')
			statusRef.current.style.color = 'red'	
		else if(status.toLowerCase() === 'offline')
			statusRef.current.style.color = 'grey'	
}
function setStatus(event, status){
	axiosClient.patchRequest(constants.USER_URL+'status?type='+status)
	.then(function (response) {
		setStatusColor(status)
	}).catch(function (response) {
	    console.log(response);
	});
}
function displayUserProfileCreateForm	(){
  setDisplayUserProfile(true)
  displayTopnavMenu()
}

function closeCreateWindow(){
	setDisplayUserProfile(false)
}
function callbackFromTopnav(msg){
	if(msg.type === 'user_status_change')	{
		setStatusColor(msg.status)
	}
}


	return (
		    <div className="topnav">
		      
				<div className="topnav-user-profile" onClick={displayTopnavMenu}>
					<button  style={{borderColor:colors[findFirstCharIndex(name)][0], background: colors[findFirstCharIndex(name)][0],color:colors[findFirstCharIndex(name)][1],boxShadow:'2px', borderRadius:'50%',height:'35px',width:'35px',fontSize:'14px',position:'relative' }}>						
						<span ref={nameRef}/>
						<span class="logged-in" style={{color:'red',position: 'absolute',bottom:'14px',fontSize:'18px'}} ref={statusRef} id='status-ref'>●</span>
		        	</button>		        	
	        	</div>
	          

				<TopnavProfile dropdownRef={dropdownRef} setStatus={setStatus}
        					displayUserProfileCreateForm={displayUserProfileCreateForm} displayChangePassword={displayChangePassword}/>
	        	
        		

				{/*<TopnavProfileWindow userProfileRef={userProfileRef}
				closeUserProfileCreateForm={closeUserProfileCreateForm}/>
				*/}

				{	displayUserProfile ? <CreateUserForm 
					closeCreateWindow={closeCreateWindow} profile={true}/>
				 : '' 
				}


				<ProfileChangePassword changePasswordRef={changePasswordRef}
					hideChangePassword={hideChangePassword}/>
				<SocketMain callbackFromTopnav={callbackFromTopnav}/>
		</div>

	);
}

export default TopNav;