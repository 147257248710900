
import React from "react"
import {useState, setState, useRef,useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../../../js/Axios';
import * as constants from '../../../../../js/constants'
import '../../css/dashboard.css'

function TicketCountDashboard(props){	
  let percentage = 75;
return(			
		<div style={{marginTop:'20px',justifyContent:'start',flexDirection:'row' }}>
    <div class="container dashboard-container">
        <div class="dashboard-grid">
            <div class="dashboard-card ">
                <div>
                    <h3>Total Tickets</h3>
                    <p>{props.data.countStats !== undefined ? props.data.countStats[0] : 0 }</p>
                </div>
                <div className="dashboard-circle-container">
                  <div className="dashboard-circle" style={{ '--percentage': percentage, '--fill-color': 'red', '--background-color': 'lightgray'}} />
                </div>
            </div>

            <div class="dashboard-card ">
                <div>
                    <h3>Open Tickets</h3>
                    <p>{props.data.countStats !== undefined ? props.data.countStats[2] : 0 }</p>
                </div>
                <div className="dashboard-circle-container">
                  <div className="dashboard-circle" style={{ '--percentage': percentage, '--fill-color': 'red', '--background-color': 'lightgray'}} />
                </div>
            </div>

            <div class="dashboard-card ">
                <div>
                    <h3>Unassigned Tickets</h3>
                    <p>{props.data.countStats !== undefined ? props.data.countStats[1] : 0 }</p>
                </div>
                <div className="dashboard-circle-container">
                  <div className="dashboard-circle" style={{ '--percentage': percentage, '--fill-color': 'red', '--background-color': 'lightgray'}} />
                </div>
            </div>

            <div class="dashboard-card ">
                <div>
                    <h3>Pending First Response</h3>
                    <p>{props.data.countStats !== undefined ? props.data.countStats[3] : 0 }</p>
                </div>
                <div className="dashboard-circle-container">
                  <div className="dashboard-circle" style={{ '--percentage': percentage, '--fill-color': 'red', '--background-color': 'lightgray'}} />
                </div>
            </div>            
        </div>
    </div>	
	</div>
)
}


export default TicketCountDashboard;