import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../../js/constants'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { usePermissions } from '../../../PermissionsProvider';

function PhoneNumberLine(props){

const navigate = useNavigate(); 
const { isActionAllowed } = usePermissions();

function openConfigurePhoneNumberForm(){
	props.openConfigurePhoneNumberForm(props.phoneNumber.id)
}
function checkBoxClicked(){
	
}

function checkEditPermission(){
	if(isActionAllowed('RELEASE_NUMBER'))
		return true;
	return false
}
const popoverBottom = (
  <Popover id="popover-positioned-bottom" title="Popover bottom" style={{padding:'10px'}}>
    <div style={{cursor:'pointer', padding:'5px', fontWeight:'600'}} onClick={(event) => props.releasePhoneNumber(event, props.phoneNumber.phoneNumber)}>Release Number</div>   
  </Popover>
);
return (
	        <tr style={{backgroundColor:'white',borderBottom:'1px solid rgb(235, 237, 240)',fontFamily:"Lato,Roboto,Helvetica,BogleWeb,MyriadPro,Helvetica Neue,Helvetica,Arial,sans-serif"}}>	            
	            <td>{isActionAllowed('CONFIGURE_PHONE_NUMBER') ? <a onClick = {openConfigurePhoneNumberForm} style={{textDecoration:'none', color:'#0d6efd',cursor:'pointer',fontWeight:'800',fonFamily:'Helvetica'}}>{props.phoneNumber.phoneNumber}</a> : <span style={{textDecoration:'none', color:'#0d6efd',cursor:'pointer',fontWeight:'800',fonFamily:'Helvetica'}}>{props.phoneNumber.phoneNumber}</span>}</td>
	            <td>{props.phoneNumber.type}</td>
	            <td style={{minWidth:'200px'}}>{props.phoneNumber.country}</td>
	            <td >{props.phoneNumber.isActive === 'true' ? 'Yes' : 'No'}</td>
	            <td>{props.phoneNumber.nextRenewalDate}</td>	
	          { checkEditPermission() &&             
	            (<td style={{minWidth:'25px'}}>	            	
	            	<span style={{visibility:'hidden',display:'inline'}}>This is working</span>
				    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverBottom}>
				      <img src={require('../../../../img/dots.png')} alt='edit' style={{height:'15px', width:'15px', cursor:'pointer'}}/>				      
				    </OverlayTrigger>	            	
	            </td>)
	          }
	        </tr>
            );

}

export default PhoneNumberLine;