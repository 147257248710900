import React, { useState, useMemo, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css'; // Import Quill's styling
import '../css/Quill.css'
import Quill from 'quill';


const BlockEmbed = Quill.import('blots/block/embed');
class CustomBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.setAttribute('class', 'custom-blot');
    node.innerHTML = value;
    return node;
  }

  static value(node) {
    return node.innerHTML;
  }
}

CustomBlot.blotName = 'custom';
CustomBlot.tagName = 'div';
Quill.register(CustomBlot);


// const fonts = ['Arial', 'Courier', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'];
// const Font = Quill.import('formats/font');
// Font.whitelist = fonts;
// Quill.register(Font, true);

const TextEditor = () => {
  const quillRef = useRef(null)
  let selectedCell = useRef(null)
  let currentTable;
  let contextMenu;
  let currentImage;
  let currentResizer = null;
  let startX, startY, startWidth, startHeight;
  const editorRef = useRef(null)
  const [editorContent, setEditorContent] = useState('');

  useEffect(()=>{
      contextMenu  = document.getElementById('contextMenu');
      let button = document.getElementsByClassName('ql-table')[0]
      if(button){
        button.innerHTML = 'T'
        button.onclick=insertTable
      }
   } )
  
  
  const insertCustomElement = () => {
    // const quill = quillRef.current.getEditor();
    // const cursorPosition = quill.getSelection()?.index;
    // if (cursorPosition !== null) {
    //   console.log('inside cursorPosition')
    //   quill.insertEmbed(cursorPosition, 'user', <table></table>);
    //   quill.setSelection(cursorPosition + 1);
    // }
  };

  const handleImageUpload = () => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();
  
  input.onchange = async () => {
    const file = input.files[0];
    const formData = new FormData();
    formData.append('image', file);

    // Replace with your image upload logic, e.g., an API call
    const res = await fetch('your-image-upload-api-endpoint', {
      method: 'POST',
      body: formData,
    });
    const imageUrl = await res.json();

    const quill = this.quill;
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', imageUrl);
  };
};

  // Define formats for React Quill
  const formats = [
    'font',
    'header',
    'bold', 'italic', 'underline', 'strike',
    'align',
    'list', 'bullet',
    'blockquote', 'code-block',
    'link', 'image', 'video', 'table'
  ];



  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'font': [] }],                              // Font
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],      // Header
          ['bold', 'italic', 'underline', 'strike'],      // Bold, Italic, Underline, Strikethrough
          [{ 'align': [] }],                              // Text Alignment
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],  // Ordered and Unordered List
          ['blockquote', 'code-block'],                   // Blockquote and Code Block
          ['link', 'image', 'video'],                     // Link, Image, Video
          ['clean'], ['table'], 
          // ['insertCustomElement'],         
          // ['custom-button'],
      ],
      handlers: {
        image: handleImageUpload
      },
      handlers: {
        'insertCustomElement': insertCustomElement,
      },
    }
  }), [])

function showTableMenu(event) {    
    event.preventDefault()
    console.log('showTableMenu '+ event.clientX+"  "+event.clientY+". ")
    let react = editorRef.current.getBoundingClientRect()
    contextMenu.style.display = 'block';
    contextMenu.style.left = `${event.clientX - react.left}px`;
    contextMenu.style.top = `${event.clientY - react.top}px`;
    selectedCell.current = event.target    
  }

  function addRow(position) {
    if (!selectedCell.current) return;
    let table = selectedCell.current.parentElement.parentElement  
    let numberOfColumns = table.children[0].children.length
    let newRow = table.insertRow(0) 
    newRow.style.border='1px solid black'   
    for(var i=0;i<numberOfColumns; i++){
      let cell = newRow.insertCell(i)
      cell.style.height='20px'
      cell.style.border='1px solid black'
    }

    if(position === 'top')
      table.insertBefore(newRow,selectedCell.current.closest('tr'));    
    else{
      let tr = selectedCell.current.closest('tr').nextSibling
      if(tr)
        table.insertBefore(newRow,tr);
      else
        table.appendChild(newRow)
    }

  }

  function insertColumnLeft() {
    if (!selectedCell) {
        alert('Please select a cell first!');
        return;
    }

    const table = document.getElementById('myTable');
    const selectedColumnIndex = selectedCell.cellIndex; // Get the index of the selected cell's column

    // Loop through each row of the table and insert a new cell at the selected column index
    for (let row of table.rows) {
        const newCell = row.insertCell(selectedColumnIndex); // Insert a new cell at the column index
        newCell.innerHTML = 'New Cell'; // You can set this to whatever default content you want
    }
}
  // Add column to the table
  function addColumn(position) {
    if (!selectedCell.current) return;
    const selectedColumnIndex = selectedCell.current.cellIndex;
    let table = selectedCell.current.parentElement.parentElement      
    for (let row of table.rows) {
        if(position ===  'right')
          row.insertCell(selectedColumnIndex+1);
        else
          row.insertCell(selectedColumnIndex);
    }
  }
function initResize(event) {
    let current;
    if(currentImage)
      current = currentImage;
    else if(currentTable)
      current = currentTable
    currentResizer = event.target;
    startX = event.clientX;
    startY = event.clientY;
    startWidth = parseInt(window.getComputedStyle(current).width, 10);
    startHeight = parseInt(window.getComputedStyle(current).height, 10);

    window.addEventListener('mousemove', resizeImage);
    window.addEventListener('mouseup', stopResize);
  }

  function hideTableMenu() {
    contextMenu.style.display = 'none';
    document.removeEventListener('click', hideTableMenu);
  }


function insertTable() {
  const editor = document.getElementsByClassName('ql-editor')[0];
  const table = document.createElement('table');
  let div = document.createElement('div')
  let br = document.createElement('br')
  div.style.position='relative'
  div.style.display='inline-block'
  // div.style.width='100%'
  for (let i = 0; i < 2; i++) {
    const row = table.insertRow();
    for (let j = 0; j < 5; j++) {
      const cell = row.insertCell();
      cell.style.height='20px'
    }
  }
  table.addEventListener('contextmenu', (event) => {
      if (event.target.tagName === 'TD') {
          selectedCell.current = event.target;
          showTableMenu(event);
      }
  });
  table.addEventListener('click', (event) => {
      hideTableMenu()
  });
  div.addEventListener('click', () => addResizersForTable(div));
  
  div.appendChild(table)

  if(!elementContainsSelection(editor)){  
     editor.appendChild(div);
     editor.appendChild(br);editor.appendChild(br);editor.appendChild(br)
     editor.appendChild(br);editor.appendChild(br);editor.appendChild(br)
    return
  }
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    range.deleteContents();
    range.insertNode(div);
  } else {
    editor.appendChild(div);
    editor.appendChild(br);editor.appendChild(br);editor.appendChild(br)
    editor.appendChild(br);editor.appendChild(br);editor.appendChild(br)
  }
  
}

function elementContainsSelection(el) {
    var sel = window.getSelection();
    if (sel.rangeCount > 0) {
        for (var i = 0; i < sel.rangeCount; ++i) {
            if (!el.contains(sel.getRangeAt(i).commonAncestorContainer)) {
                return false;
            }
        }
        return true;
    }
    return false;
}
function resizeImage(event) {
    let currentElement;
    if(currentImage)
      currentElement = currentImage;
    else if(currentTable)
      currentElement = currentTable
    if (!currentElement) return;

    const dx = event.clientX - startX;
    const dy = event.clientY - startY;

    if (currentResizer.classList.contains('bottom-right')) {
      currentElement.style.width = `${startWidth + dx}px`;
      currentElement.style.height = `${startHeight + dy}px`;
    } else if (currentResizer.classList.contains('bottom-left')) {
      currentElement.style.width = `${startWidth - dx}px`;
      currentElement.style.height = `${startHeight + dy}px`;
    } else if (currentResizer.classList.contains('top-right')) {
      currentElement.style.width = `${startWidth + dx}px`;
      currentElement.style.height = `${startHeight - dy}px`;
    } else if (currentResizer.classList.contains('top-left')) {
      currentElement.style.width = `${startWidth - dx}px`;
      currentElement.style.height = `${startHeight - dy}px`;
    }

    updateResizerPosition();
  }
  // Stop resizing
  function stopResize() {
    window.removeEventListener('mousemove', resizeImage);
    window.removeEventListener('mouseup', stopResize);
  }
  // Update resizer handles position during resizing
  function updateResizerPosition() {
    let currentElement;
    if(currentImage)
      currentElement = currentImage;
    else if(currentTable)
      currentElement = currentTable
    if (!currentElement) return;
    const resizers = currentElement.parentElement.querySelectorAll('.resizer');
    resizers.forEach(resizer => {
      const position = resizer.classList[1];
      if (position === 'top-left') {
        resizer.style.top = `${currentElement.offsetTop - 4}px`;
        resizer.style.left = `${currentElement.offsetLeft - 4}px`;
      } else if (position === 'top-right') {
        resizer.style.top = `${currentElement.offsetTop - 4}px`;
        resizer.style.left = `${currentElement.offsetLeft + currentElement.offsetWidth - 4}px`;
      } else if (position === 'bottom-left') {
        resizer.style.top = `${currentElement.offsetTop + currentElement.offsetHeight - 4}px`;
        resizer.style.left = `${currentElement.offsetLeft - 4}px`;
      } else if (position === 'bottom-right') {
        resizer.style.top = `${currentElement.offsetTop + currentElement.offsetHeight - 4}px`;
        resizer.style.left = `${currentElement.offsetLeft + currentElement.offsetWidth - 4}px`;
      }
    });
  }
    // Delete selected row
  function deleteRow() {
    console.log('deleteRow')
    if (!selectedCell.current) return;
    console.log('deleteRow')
    let row = selectedCell.current.closest('tr')
    row.remove()
    contextMenu.style.display = 'none';
  }

  // Delete selected column
  function deleteColumn() {
    if (!selectedCell.current) return;
    const selectedColumnIndex = selectedCell.current.cellIndex;
    let table = selectedCell.current.parentElement.parentElement              
    for (let row of table.rows) {
      for(let cell of row.cells){
        if(cell.cellIndex === selectedColumnIndex)
          cell.remove()
      }
    }
    contextMenu.style.display = 'none';
  }
function addResizersForTable(div) {
    return
    const resizers = document.querySelectorAll('.resizer');  
    if(resizers.length > 0){    
      resizers.forEach(resizer => resizer.remove());
      currentTable = null;
      return
    }
    currentTable = div.children[0];
    const resizerPositions = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];

    resizerPositions.forEach(position => {
      const resizer = document.createElement('div');
      resizer.classList.add('resizer', position);
      resizer.addEventListener('mousedown', initResize);
      div.appendChild(resizer);
    });
  }
function print(){
  console.log(editorContent)
}
  return (
  <>
    <div style={{ position:'absolute', left:'20%', width:'80%', height:'100%'}} ref={editorRef}>
      <div style={{width:'90%'}}>
            <input type='text' placeholder='title' style={{width:'100%', paddingLeft:'20px'}} required/>
            <ReactQuill
              ref={quillRef}
              value={editorContent}
              onChange={setEditorContent}
              modules={modules}
              formats={formats}
              placeholder="Start typing..."
              theme="snow"
            />            
        
          <button class='ss-button' onClick={print} style={{width:'150px', padding:'10px',position:'absolute', bottom:'0px'}}>Create Article</button>
          <div class="context-menu" id="contextMenu">
              <button onClick={()=>addRow('top')}>Add Row Above</button>
              <button onClick={()=>addRow('bottom')}>Add Row Below</button>
              <button onClick={()=>addColumn('left')}>Add Column Left</button>
              <button onClick={()=>addColumn('right')}>Add Column Right</button>
              <button onClick={deleteRow}>Delete Row</button>
              <button onClick={deleteColumn}>Delete Column</button>
            </div>        
       </div>
    </div>
  </>

  );
};

export default TextEditor;
