import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate, Redirect} from "react-router-dom"

function TextArea(props){
    const menuRef = useRef(null)
	function displayMenu(){
        console.log('displayMenu')
        menuRef.current.style.display='block'
    }
    function hideMenu(){
        console.log('hideMenu')
        menuRef.current.style.display='none'
    }
	return(
	  <div class="form-group" onMouseOver={displayMenu} onMouseLeave={hideMenu} style={{position:'relative'}}>
            <div class="icons" style={{display:'block',transition:'opacity 0.3s',position:'absolute',top:'10px',right:'10px'}} ref={menuRef}>
                <a class="fas fa-edit icon" title="Edit" onClick={()=> props.displayTAEditWindow(props.index)}>edit</a>
                <a class="fas fa-trash-alt icon" title="Delete" onClick={()=>props.deleteIndex(props.index)}>delete</a>
            </div> 
		 <div class="form-group">
            <label class='create-form-label'>{props.payload.agentPortalName}</label>
            <textarea class='form-control' style={{border: '1px solid black' }}rows="4" placeholder="Enter detailed text"></textarea>
        </div>
     </div>
		)

}

export default TextArea