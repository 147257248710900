import React from "react"
import {useState, setState, useRef, useEffect, useContext} from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import {useNavigate} from "react-router-dom"
import {Route, Link, Routes, useParams} from 'react-router-dom';
import LoadingAnimation from '../../LoadingAnimation'
import ChatContactBox from './ChatContactBox'
import ChatMiddleWindow from './ChatMiddleWindow'
import ChatLeftWindow from './ChatLeftWindow'
import ChatProfile from './ChatProfile'
import ChatUserMessage from './ChatUserMessage'
import ChatCustomerMessage from './ChatCustomerMessage'
import ChatMessageAttachment from './ChatMessageAttachment'
import ReactDOMServer from 'react-dom/server';

import { io } from 'socket.io-client';
import '../css/chat.css'
import SocketContext from '../../SocketContext'

const chat_notification_styles = {
    notification: {
        position: 'fixed',
        top: '10px',
        right: '10px',
        padding: '15px',
        borderRadius: '8px',
        backgroundColor: '#e6eaeb',
        border: '1px solid #D3D3D3',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        maxWidth: '250px',
        textAlign: 'center',
        zIndex: '1000000 !important',
        width:'250px'
    },
    notification_p: {
      color:'black',
      fontWeight: '600',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
    },
    acceptButton: {
        backgroundColor: 'lightgreen',
        border: 'none',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontWeight:'600',
    },
    rejectButton: {
        backgroundColor: 'lightcoral',
        border: 'none',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontWeight:'600',
    },
};



function ChatDisplay(props){
  let userId = "1";
  const middleBoxRef = useRef(null)
  const messageRef = useRef(null)
  const chatNotificationRef = useRef(null)
  const  [chatProfileData, setChatProfileData] = useState(-1)
  const  [clickedProfileId, setClickedProfileId] = useState(-1)
  const [chatProfileList, setChatProfileList] = useState([])
  const [middleBoxChatMessages, setMiddleBoxChatMessages] = useState([])
	const params = useParams();
	const navigate = useNavigate();
  const [chatData, setChatData] = useState(null)
	const commentsBodyRef = useRef(null)
  const contactBoxRef = useRef(null)
	const [ticket, setTicket] = useState(1)
  const [showNotification, setShowNotification] = useState(false);
  const [botificationMessage, setNotificationMessage] = useState(null)
	const socket = useContext(SocketContext);
  const [isShaking, setIsShaking] = useState(false);
  const [newConversationId, setNewConversationId] = useState(-1)
  const currentConversationIdRef = useRef(-1)
  const [chats, setChats] = useState([])
  const [contact, setContact] = useState(null)
  useEffect(() => {
      socket.on('message', handleMessage);
      let profile = document.getElementsByClassName('topnav')
      profile[0].style.display = 'none';
      return () => {
        socket.off('message');
      };
  }, []);

useEffect(() => {
     const fetchChats = async () => {
          try {
                axiosClient.getRequest(constants.CHAT_URL+'fetch_agent_chats')
                .then(function (response) {
                      setChats(response.data);
                      // setLoading(false);
                }).catch(function (response) {
                    console.log(response);
                }); 
          } catch (error) {
              console.error("Error fetching chats:", error);
          }
    };

    fetchChats();
  },[])

function handleMessage(message){
  message = typeof message === 'string' ? JSON.parse(message) : message;
  console.log('handleMessage ChatDisplay '+ JSON.stringify(message))
  if(message.type === 'chat_initiated'){
    setNewConversationId(message.newConversationId)  
    setShowNotification(true)
    setTimeout(handleTimeout, 30000)   
  }else if(message.type === 'c_c_m'){
    console.log('handleMessage  '+JSON.stringify(message))
    if(message.conversationId === currentConversationIdRef.current)
      setMiddleBoxChatMessages((prevMessages) => [...prevMessages, message]);
    else
    {
        setChats(prevChats =>
        prevChats.map(chat =>
          chat.conversationId === message.conversationId
            ? { ...chat, content: message.content }
            : chat
        )
      );
    }
  }
}


function closeContactBox(){
  contactBoxRef.current.style.display = 'none'
}

const handleTimeout = ()=>{

}

const handleChatAccept = () => {
  axiosClient.postRequest(constants.CHAT_URL+"accept_chat?id="+newConversationId)
          .then(function (response) {
        setShowNotification(false);
        setNewConversationId(-1)          
  }).catch(function (response) {
              console.log(response.data)
  });
  
};

const handleChatReject = () => {
    setShowNotification(false);
};

function displayChatsInMiddleBox(conversationId){
    axiosClient.getRequest(constants.CHAT_URL+"fetch_chats?id="+conversationId)
    .then(function (response) {
        currentConversationIdRef.current = conversationId               
        setMiddleBoxChatMessages(response.data)        
        let chat = chats.find((chat) => chat.conversationId === conversationId)       
        setContact(chat.contactName)
        console.log('contact is '+JSON.stringify(chat))
    }).catch(function (response) {
        console.log(response.data)
    });
}


	return(
          <div class="chat-body">
              <div className="chat-window">
                  <ChatLeftWindow displayChatsInMiddleBox={displayChatsInMiddleBox} chats={chats}/>
                  <ChatMiddleWindow  middleBoxChatMessages={middleBoxChatMessages} currentConversationIdRef={currentConversationIdRef}
                        setMiddleBoxChatMessages={setMiddleBoxChatMessages}
                        contact={contact}/>
              </div>

          { showNotification &&
              (
                  <div style={chat_notification_styles.notification} className={isShaking ? 'shake' : ''} >
                      <p style={chat_notification_styles.notification_p}>
                          {botificationMessage || 'New Chat Request'}
                      </p>
                      <div style={chat_notification_styles.buttonContainer}>
                          <button 
                              style={chat_notification_styles.acceptButton} 
                              onClick={handleChatAccept}>
                              Accept
                          </button>
                          <button 
                              style={chat_notification_styles.rejectButton} 
                              onClick={handleChatReject}>
                              Reject
                          </button>
                      </div>
                  </div>
              )
          }
          </div> 
     
);
}

export default ChatDisplay;