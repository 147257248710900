import React, {useState, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'

function CustomRoleListDisplayHeader(props){

const navigate = useNavigate();
const searchRef = useRef(null) 
const [filterState, setFilterState] = useState('All CustomRoles')



  function createNewCustomRole(){
  	props.showCreateCustomRoleFormFun()
  }



return (
    <div class='header-body'>
	    <div class="main-header">		    	            
	    	    <div  style={{cursor: 'pointer',fontWeight:'bold',fontSize:'14px' }} class='header-filter-name'>Custom Roles</div>
             <div class="header-right" ref={props.groupButtonsRef}>
	             <button class='ss-button'  type="button"  onClick={() => props.performAction('delete')}>Delete</button>
	           </div>
	         <button class='ss-button'  onClick={createNewCustomRole}>+ New CustomRole</button>
	    </div>
    </div>
    );
}

export default CustomRoleListDisplayHeader;