import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../../js/constants'
import { usePermissions } from '../../../PermissionsProvider';
function BusinessCalenderListDisplayHeader(props){
	const { isActionAllowed } = usePermissions();
	const navigate = useNavigate(); 
function createNewBusinessCalender(){
	navigate(constants.BUSINESS_HOURS_URL_ABS+'create')
}

return (
<div class='header-body'>
	<div class="main-header">	
 <div class="header-left" style={{fontWeight:'bold' }}>All Business Hours</div>	
 		 <div class="search-wrapper">
 					{<img src={require('../../../../img/search.png')} alt=''/>}
 					<input placeholder="search here" class="search-input" onChange={props.searchBusinessCalenders}/>
 </div>	
	 <div class="header-right" ref={props.groupButtonsRef}>
		{isActionAllowed('DELETE_BUSINESS_HOURS') && (<button class='ss-button'type="button"onClick={() => props.performAction('delete')}>Delete</button>)}
	 </div>
 		{isActionAllowed('CREATE_BUSINESS_HOURS') && (<button class='ss-button'type="button" onClick={createNewBusinessCalender}>+ New Business Hours</button>)}
	</div>
</div>
);

}

export default BusinessCalenderListDisplayHeader;