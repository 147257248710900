import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
import { usePermissions } from '../../PermissionsProvider';

function IVRLine(props){
	const { isActionAllowed } = usePermissions();
	const navigate = useNavigate(); 
	function openIVR(id){
		let path = constants.IVR_URL_ABS +'create/'+props.IVR.id ;
		if(isActionAllowed('EDIT_IVR'))
	    	navigate(path);
	}
	function checkBoxClicked(event){
		props.checkBoxClicked(props.IVR.id, event.target.checked)
	}

	return (
	        <tr>
	        	<th scope="row" class="ps-4" style={{width:'50px'}}>
	            	 <div class="form-check font-size-16"><input type="checkbox" class="form-check-input ivr-line-checkbox" onClick={checkBoxClicked} name={'checkbox-'+props.IVR.id} style={{border:'1px solid #A9A9A9'}}/></div>
	          	</th>	            
	            <td style = {{width: "50px",textDecoration: 'none'}}><a href='' style = {{textDecoration: 'none',fontWeight:'800',fonFamily:'Helvetica'}} onClick = {openIVR}>{props.IVR.name}</a></td>
	            <td style = {{width: "80%"}}>
						{props.IVR.welcomeMessage}
	            </td>	           
	         </tr>
            );

}

export default IVRLine;