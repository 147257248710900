import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import Quill2 from './Quill2'
import Quill from './Quill'
import MyQuill from './MyQuill'
import {useNavigate, useParams} from "react-router-dom"
import * as axiosClient from "../../../js/Axios"
import * as constants from '../../../js/constants'



function CreateArticle(){
   const navigate = useNavigate(); 
   const titleRef = useRef(null)
   const [categoryId, setCategoryId] = useState(-1)
   const [subCategoryId, setSubCategoryId] = useState(-1)
   const [state, setState] = React.useState({ value: null });
   const nameRef = useRef(null)
   const categoryInputRef = useRef(null)
   const subCategoryInputRef = useRef(null)
   const apVisibilityRef = useRef(null)
   const cpVisibilityRef = useRef(null)
   const expirationRef = useRef(null)
   const errorRef = useRef(null)
   const categoryDropdownRef = useRef(null)
   const subCategoryDropdownRef = useRef(null)
   const [subCategories, setSubCategories] = useState([])
   const [subCategorySearchWord, setSubCategorySearchWord]=useState('')
   const params = useParams();

   useEffect(()=>{
    console.log('Create Article '+params.id)
    if(params.id){
        axiosClient.getRequest(constants.KB_URL+'articles?id='+params.id)
        .then(function (response) {
          setState(prevState => ({
                    value: decodeURIComponent(response.data.content).replace(/\+/g, ' ')
          }));
        }).catch(function (response) {
            console.log(response);
        });
    }

   },[])
   function selelectSubCategory(element){
      setSubCategoryId(element.id)
      subCategoryInputRef.current.value=element.name
      subCategoryDropdownRef.current.style.display='none'
   }
   function displaySubCategoriesDropdown(event){
      subCategoryDropdownRef.current.style.display='block'
      setSubCategorySearchWord(event.target.value)
   }

   function onSelectDropdown(event,element){    
      console.log('onSelectDropdown '+JSON.stringify(element.subCategories))  
      setCategoryId(element.id)
      setSubCategories(element.subCategories)
      subCategoryDropdownRef.current.style.display='block'
      categoryInputRef.current.value=element.name
      categoryDropdownRef.current.innerHTML = ''
      categoryDropdownRef.current.style.display='none'
  }
  function addChildToCategoryDropdown(element, event){
      let aTag = document.createElement('a')
      aTag.name=element.id
      aTag.innerHTML = element.name
      aTag.classList.add('category-dropdown-line')
      aTag.onclick= (event) =>onSelectDropdown(event, element)
      categoryDropdownRef.current.appendChild(aTag)
      categoryDropdownRef.current.style.display = 'block'
  }

   function searchCategories(event){
      axiosClient.getRequest(constants.KB_URL+'categories/search_by_name?name='+event.target.value)
         .then(function (response) {
            setCategoryId(-1)
            setSubCategories([])
            subCategoryInputRef.current.value=''
            console.log('searchCategories')
            categoryDropdownRef.current.innerHTML = ''
            setSubCategorySearchWord('')
            response.data.forEach((element, index)=>{             
               addChildToCategoryDropdown(element,event)
            })
         }).catch(function (response) {
             console.log(response);
         });
   }



    const uploadAllImages = async(payload, images)=>{
      const presignedData = await  fetchPresignedUrls(images.length);
      await uploadAllImagesToS3(images, presignedData);
      let result =  replaceImagesWithPlaceholders(state.value, presignedData); 
      payload['content'] = decodeURIComponent(result)
      sendRequestToCreateArticle(payload)      
    };

    const uploadAllImagesToS3 = async (images, presignedData) => {
        const uploadPromises = images.map(async (img, index) => {
              console.log('uploadAllImagesToS3 inside loop '+index)
              const presignedUrlInfo = presignedData[index];
              const response = await fetch(img.src);
              const blob = await response.blob();
              const fileType = blob.type || 'application/octet-stream'; 
              const fileExtension = fileType.split('/')[1];
              const fileName = `${presignedUrlInfo.uuid}.${fileExtension}`;
              const file = new File([blob], fileName, { type: fileType });
              try {
                await axiosClient.putExternalRequest(presignedUrlInfo.url, file,{headers: {
                  'Content-Type': 'multipart/form-data',
                  'Content-Length': file.size
                }});
                console.log(`Uploaded image with UUID: ${presignedUrlInfo.uuid}`);
              } catch (error) {
                console.error(`Error uploading image with UUID: ${presignedUrlInfo.uuid}`, error);
                throw error;
              }
        });

        return Promise.all(uploadPromises);
      };

      const replaceImagesWithPlaceholders = (htmlContent, presignedData) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const images = doc.querySelectorAll('img');

        images.forEach((img, index) => {
          const uuid = presignedData[index].uuid;
          img.replaceWith(`[--image:${uuid}--]`);
        });

        return doc.body.innerHTML;
      };


    const fetchPresignedUrls = async (length) => {
      const response = await axiosClient.getRequest(constants.KB_URL+'presigned_url?length='+length);
      return response.data
    } 

    // Extract images with attributes
    const extractImages = (htmlContent) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
      const images = doc.querySelectorAll('img');
      
      return Array.from(images).map(img => ({
        src: img.src,
        alt: img.alt,
        style: img.getAttribute('style'),
        className: img.className,
        id: img.id,
        width: img.width,
        height: img.height
      }));
    };

    // Replace image tags with updated URLs and attributes
    const replaceImageTags = (htmlContent, imageMap) => {
      console.log('replaceImageTags')
      let updatedContent = htmlContent;

      imageMap.forEach(({ placeholder, url, attributes }) => {
        const imgTag = `<img src="${url}" alt="${attributes.alt || ''}" style="${attributes.style || ''}" class="${attributes.className || ''}" id="${attributes.id || ''}" width="${attributes.width || ''}" height="${attributes.height || ''}"/>`;
        updatedContent = updatedContent.replace(new RegExp(`<img[^>]*src="${placeholder}"[^>]*>`, 'g'), imgTag);
      });
      console.log('replaceImageTags after '+updatedContent)
      return updatedContent;
    };
    // Utility function to show user feedback
    const showUserFeedback = (message, type = 'error') => {
      // Implement a function to display feedback to the user
      console[type](message);
    };

    function sendRequestToCreateArticle(payload){
      console.log('sendRequestToCreateArticle '+JSON.stringify(payload))
        axiosClient.postRequest(constants.KB_URL+'article', payload)
         .then(function (response) {
                    navigate(constants.KB_URL_ABS) 
            
         }).catch(function (response) {
             console.log(response);
         });

    }

   function handleSubmit(event){
      event.preventDefault()
      errorRef.current.innerHTML = ''
      if(nameRef.current.value === ''){
         displayError('Article Name is manadatory')
         return
      }
      if(titleRef.current.value === ''){
         displayError('Title for Article  is manadatory')
         return
      }
      if(categoryId === -1){
         displayError('Category for Article  is manadatory')
         return
      }
      if(subCategoryId === -1){
         displayError('Sub Category for Article  is manadatory')
         return
      }
      let updatedContent = ''
      let payload = {
         name: nameRef.current.value, description: titleRef.current.value ,
         id: categoryId, subCategoryId: subCategoryId,
         apVisible: apVisibilityRef.current.value,
         cpVisible: cpVisibilityRef.current.value,
         expiration: expirationRef.current.value
      }

      try {
             const images = extractImages(state.value);
             if (images.length > 0) {
               uploadAllImages(payload, images);               
             } 
             payload['content'] = state.value
            sendRequestToCreateArticle(payload)
             
             console.log('updatedContent '+updatedContent)
        } catch (error) {
            showUserFeedback(`Error processing content: ${error.message}`);
        }
      
      
   }

   function displayError(msg){
     errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
     errorRef.current.style.display='block'
     errorRef.current.scrollIntoView()
   }

   function displayTable(){}
	return (
	<>	
		<div class = 'header-body'>
		  <div class="main-header">
			<div style={{display:'inline-block', float:'left',textAlign:'baseline'}}><b>Articles</b></div>
	      </div>
		</div>	  
		<div class='main-body'>				
			<div style={{borderRight:'1px solid black',padding:'10px',position:'absolute', left:'0px',minWidth:'250px', width:'20%',borderRight:'1px solid #dee2e6', height:'100%',overflowY:'auto'}}>								
			   <div class='create-form-label'>Name:<p class='red-color'>*</p></div>
               <div><input type="text" name="fullName" style={{paddingLeft:'20px', width: "95%"}} placeholder={'Full Name'} required maxlength='500' ref={nameRef}/></div>
               <div class='create-form-label' style={{ marginTop: "10%"}}>Categories:<p class='red-color'>*</p></div>
               <div style={{position: 'relative'}}>
               		<input type="text" name="category" style={{width: "95%"}}  ref={categoryInputRef} onChange={searchCategories}/>
               		<div style={{ maxHeight:'200px', zIndex:'100', backgroundColor: 'white', display:'none',border:'1px solid black', position:'absolute', width:'90%'}} ref={categoryDropdownRef} />
               		
               </div>
               <div class='create-form-label' style={{ marginTop: "10%"}}>Sub Categories:<p class='red-color'>*</p></div>
               <div style={{position: 'relative'}}>
               		<input type="text" name="subcategory" style={{width: "95%"}}  ref={subCategoryInputRef} onChange={displaySubCategoriesDropdown}/>
               		<div style={{maxHeight:'200px', zIndex:'100', backgroundColor: 'white', display:'none', border:'1px solid black', position:'absolute', width:'90%'}} ref={subCategoryDropdownRef}>
               			{subCategories.filter(item => item.name.startsWith(subCategorySearchWord)).map((item, index) => (
                           <a href= '#' class='category-dropdown-line' onClick={(event)=>selelectSubCategory(item)}>{item.name} </a>
                        ))}   
               		</div>
               </div>
               <div class='create-form-label' style={{ marginTop: "10%"}}>Visible In Agent Portal </div>
               <select style={{width: "95%",height:'34px'}} ref={apVisibilityRef}>
                  <option>Yes</option>
                  <option>No</option>
               </select>
               <div class='create-form-label' style={{ marginTop: "10%"}}>Visible In Customer Portal </div>
               <select style={{width: "95%",height:'34px'}} ref={cpVisibilityRef}>
                  <option>Yes</option>
                  <option>No</option>
               </select>
               <div class='create-form-label' style={{ marginTop: "10%"}}>Unpublish Date</div>
               <input type='date' style={{width: "95%",height:'34px'}} ref={expirationRef}/>
               <div ref={errorRef} style={{marginTop: '10px'}}/>
               <button class='ss-button' style={{position:'absolute', bottom:'20px', left:'50px'}} onClick={handleSubmit}>Create Article</button>
			     </div>
			      
			 <Quill2 titleRef={titleRef} setState={setState} state={state}/>
      
      
			
    	</div>
    </>
	)
}

export default CreateArticle;