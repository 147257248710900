import React from "react"
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails, CognitoIdentityCredentials, WebIdentityCredentials } from 'amazon-cognito-identity-js';
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate, Redirect} from "react-router-dom"
import * as axiosClient from '../../js/Axios';
import * as constants from '../../js/constants';
import * as awsProfile from '../../js/awsProfile.js'
import InputBox from './js/InputBox'
import CheckBox from './js/CheckBox'
import RadioButton from './js/RadioButton'
import Dropdown from './js/Dropdown'
import TextArea from './js/TextArea'
import DateField from './js/DateField'
import DateTimeField from './js/DateTimeField'

import  './formBuilder.css'

function FormBuilder(props){
	const [children, setChildren] = useState([])
    const cbAPLabelRef = useRef(null)
    const cbaRequiredRef = useRef(null)
    const cbagentViewRef = useRef(null)
    const cbagentEditRef = useRef(null)
    const cbRequiredSolutionRef = useRef(null)
    const cbcustRequiredRef = useRef(null)
    const cbcustEditRef = useRef(null)

    const ibAPLabelRef = useRef(null)
    const ibaRequiredRef = useRef(null)
    const ibagentViewRef = useRef(null)
    const ibagentEditRef = useRef(null)
    const ibRequiredSolutionRef = useRef(null)
    const ibcustRequiredRef = useRef(null)
    const ibcustEditRef = useRef(null)

    const rbAPLabelRef = useRef(null)
    const rbaRequiredRef = useRef(null)
    const rbagentViewRef = useRef(null)
    const rbagentEditRef = useRef(null)
    const rbRequiredSolutionRef = useRef(null)
    const rbcustRequiredRef = useRef(null)
    const rbcustEditRef = useRef(null)

    const taAPLabelRef = useRef(null)
    const taaRequiredRef = useRef(null)
    const taagentViewRef = useRef(null)
    const taagentEditRef = useRef(null)
    const taRequiredSolutionRef = useRef(null)
    const tacustRequiredRef = useRef(null)
    const tacustEditRef = useRef(null)

    const ddAPLabelRef = useRef(null)
    const ddaRequiredRef = useRef(null)
    const ddagentViewRef = useRef(null)
    const ddagentEditRef = useRef(null)
    const ddRequiredSolutionRef = useRef(null)
    const ddcustRequiredRef = useRef(null)
    const ddcustEditRef = useRef(null)


    const dateAPLabelRef = useRef(null)
    const dateaRequiredRef = useRef(null)
    const dateagentViewRef = useRef(null)
    const dateagentEditRef = useRef(null)
    const dateRequiredSolutionRef = useRef(null)
    const datecustRequiredRef = useRef(null)
    const datecustEditRef = useRef(null)

    const dateTimeAPLabelRef = useRef(null)
    const dateTimeaRequiredRef = useRef(null)
    const dateTimeagentViewRef = useRef(null)
    const dateTimeagentEditRef = useRef(null)
    const dateTimeRequiredSolutionRef = useRef(null)
    const dateTimecustRequiredRef = useRef(null)
    const dateTimecustEditRef = useRef(null)


    const ibEditRef = useRef(null)
    const taEditRef = useRef(null)
    const cbEditRef = useRef(null)
    const rbEditRef = useRef(null)
    const ddEditRef = useRef(null)
    const dateEditRef = useRef(null)
    const dateTimeEditRef = useRef(null)
    const checkboxGroupRef = useRef(null)
    const ddGroupRef = useRef(null)
    const rbGroupRef = useRef(null)
	const [dragValue, setDragValue] = useState(null)
    const [formElements, setFormElements] = useState([])
    const [payload, setPayload] = useState({})
    const showCheckboxRef = useRef(false)
    const showTextboxRef = useRef(null)
    const showTextareaRef = useRef(null)
    const showDDRef = useRef(null)
    const showRBRef = useRef(null)
    const showDateRef = useRef(null)
     const showDateTimeRef = useRef(null)
    
    useEffect(()=>{
        console.log('useEffect formBuilder')
    },[])

    function createCheckBoxGroup(event){
        event.preventDefault();
        let localPayload = {agentPortalName: event.target.agentPortalName.value,
            customerPortalName: event.target.customerPortalName.value,
            type: 'checkbox',aRequired: event.target.aRequired.checked,
            agentView:event.target.agentView.checked,  agentEdit:event.target.agentEdit.checked,
            requiredResolution: event.target.requiredResolution.checked,cRequired: event.target.cRequired.checked,
            custEdit: event.target.custEdit.checked
        }
        let namesArr = []
        for(var i= 0; i< checkboxGroupRef.current.children.length ;i++){
            namesArr.push(checkboxGroupRef.current.children[i].value)
        }
        localPayload['names'] = namesArr;
        if(!payload[formElements.length]){
            payload[formElements.length]={}
        }
        let payloadTemp = payload
        payloadTemp[formElements.length] = localPayload
        setPayload(payloadTemp)
        showCheckboxRef.current.style.display = 'none' 
        setFormElements([...formElements , 'checkbox'])  
                  
    }
    function createTextbox(event){
        event.preventDefault();
        let localPayload = {agentPortalName: event.target.agentPortalName.value,
            customerPortalName: event.target.customerPortalName.value,
            type: 'textbox', aRequired: event.target.aRequired.checked,
            agentView:event.target.agentView.checked,  agentEdit:event.target.agentEdit.checked,
            requiredResolution: event.target.requiredResolution.checked,cRequired: event.target.cRequired.checked,
            custEdit: event.target.custEdit.checked
        }        
        let payloadTemp = payload
        payloadTemp[formElements.length] = localPayload
        setPayload(payloadTemp)
        setFormElements([...formElements , 'textbox'])  
        showTextboxRef.current.style.display = 'none'     
    }
     function createTextarea(event){
        event.preventDefault();
        let localPayload = {agentPortalName: event.target.agentPortalName.value,
            customerPortalName: event.target.customerPortalName.value,
            type: 'textarea' ,aRequired: event.target.aRequired.checked,
            agentView:event.target.agentView.checked,  agentEdit:event.target.agentEdit.checked,
            requiredResolution: event.target.requiredResolution.checked,cRequired: event.target.cRequired.checked,
            custEdit: event.target.custEdit.checked
        }        
        let payloadTemp = payload
        payloadTemp[formElements.length] = localPayload
        setPayload(payloadTemp)
        setFormElements([...formElements , 'textarea'])  
        showTextareaRef.current.style.display = 'none'    
    }
    function createDate(event){
        event.preventDefault();
        let localPayload = {agentPortalName: event.target.agentPortalName.value,
            customerPortalName: event.target.customerPortalName.value,
            type: 'date' ,aRequired: event.target.aRequired.checked,
            agentView:event.target.agentView.checked,  agentEdit:event.target.agentEdit.checked,
            requiredResolution: event.target.requiredResolution.checked,cRequired: event.target.cRequired.checked,
            custEdit: event.target.custEdit.checked
        }        
        let payloadTemp = payload
        payloadTemp[formElements.length] = localPayload
        setPayload(payloadTemp)
        setFormElements([...formElements , 'date'])  
        showDateRef.current.style.display='none'  
    }
    function createDateTime(event){
        event.preventDefault();
        let localPayload = {agentPortalName: event.target.agentPortalName.value,
            customerPortalName: event.target.customerPortalName.value,
            type: 'datetime' ,aRequired: event.target.aRequired.checked,
            agentView:event.target.agentView.checked,  agentEdit:event.target.agentEdit.checked,
            requiredResolution: event.target.requiredResolution.checked,cRequired: event.target.cRequired.checked,
            custEdit: event.target.custEdit.checked
        }        
        let payloadTemp = payload
        payloadTemp[formElements.length] = localPayload
        setPayload(payloadTemp)
        setFormElements([...formElements , 'datetime'])  
        showDateTimeRef.current.style.display='none'    
    }
    function createDropdown(event){
        event.preventDefault();
        let localPayload = {agentPortalName: event.target.agentPortalName.value,
            customerPortalName: event.target.customerPortalName.value,
            type: 'select' ,aRequired: event.target.aRequired.checked,
            agentView:event.target.agentView.checked,  agentEdit:event.target.agentEdit.checked,
            requiredResolution: event.target.requiredResolution.checked,cRequired: event.target.cRequired.checked,
            custEdit: event.target.custEdit.checked
        } 
        let namesArr = []
        for(var i= 0; i< ddGroupRef.current.children.length ;i++){
            namesArr.push(ddGroupRef.current.children[i].value)
        }
        localPayload['names'] = namesArr;
        if(!payload[formElements.length]){
            payload[formElements.length]={}
        }       
        let payloadTemp = payload
        payloadTemp[formElements.length] = localPayload
        setPayload(payloadTemp)
        setFormElements([...formElements , 'select'])  
        showDDRef.current.style.display = 'none'
    }
    function createRB(event){
        event.preventDefault();
        let localPayload = {agentPortalName: event.target.agentPortalName.value,
            customerPortalName: event.target.customerPortalName.value,
            type: 'radio' ,aRequired: event.target.aRequired.checked,
            agentView:event.target.agentView.checked,  agentEdit:event.target.agentEdit.checked,
            requiredResolution: event.target.requiredResolution.checked,cRequired: event.target.cRequired.checked,
            custEdit: event.target.custEdit.checked
        } 
        let namesArr = []
        for(var i= 0; i< rbGroupRef.current.children.length ;i++){
            namesArr.push(rbGroupRef.current.children[i].value)
        }
        localPayload['names'] = namesArr;
        if(!payload[formElements.length]){
            payload[formElements.length]={}
        }       
        let payloadTemp = payload
        payloadTemp[formElements.length] = localPayload
        setPayload(payloadTemp)
        setFormElements([...formElements , 'radio'])  
        showRBRef.current.style.display = 'none'   
    }

    

    function generateCode() {
        const form = document.getElementById("form-preview");
        const code = document.getElementById("form-code");
        code.innerText = form.outerHTML;
    }
    function displayCBEditWindow(index){
        let localPayload = payload[index]
        showCheckboxRef.current.style.display = 'block'
        cbAPLabelRef.current.value = localPayload.agentPortalName
        cbaRequiredRef.current.value= localPayload.customerPortalName
        cbagentViewRef.current.checked = localPayload.agentView
        cbagentEditRef.current.checked = localPayload.agentEdit
        cbRequiredSolutionRef.current.checked = localPayload.requiredResolution
        cbcustRequiredRef.current.checked = localPayload.cRequired
        cbcustEditRef.current.checked = localPayload.custEdit
        
    }
    function closeCBWindow(){
        cbEditRef.current = null
        showCheckboxRef.current.style.display='none'
    }
    function closeTBWindow(){
        ibEditRef.current = null
        showTextboxRef.current.style.display  = 'none'
    }
    function closeTAWindow(){
        taEditRef.current = null
        showTextareaRef.current.style.display = 'none'
    }
    function closeDDWindow(){
        ddEditRef.current = null
        showDDRef.current.style.display = 'none'
    }
    function closeRBWindow(){
        rbEditRef.current = null
        showRBRef.current.style.display = 'none'   
    }
    function closeDateWindow(){
        dateEditRef.current = null
        showDateRef.current.style.display='none'  
    }
    function closeDateTimeWindow(){
        dateTimeEditRef.current = null
        showDateTimeRef.current.style.display='none'  
    }
    function displayIBEditWindow(index){         
        ibEditRef.current = payload[index]
        showTextboxRef.current.style.display = 'block'
    }
    function displayTAEditWindow(index){
        taEditRef.current = payload[index]
        showTextareaRef.current.style.display = 'block'
    }
    function displayDDEditWindow(index){
        ddEditRef.current = payload[index]
        showDDRef.current.style.display = 'block'
    }
    function displayRBEditWindow(index){
        rbEditRef.current = payload[index]
        showRBRef.current.style.display = 'blocx'   
    }
    function displayDateEditWindow(index){
        dateEditRef.current = payload[index]
       showDateRef.current.style.display='block'        
    }
    function displayDateTimeEditWindow(index){
        dateTimeEditRef.current = payload[index]
        showDateTimeRef.current.style.display='block'  
    }
    function deleteIndex(index){
        console.log('deleteIndex')
    }

    function renderComponent(element,index){
        console.log('renderComponent '+ JSON.stringify(payload))
        if(element === 'textbox')
            return <InputBox key={index} index={index} payload={payload[index]} displayIBEditWindow={displayIBEditWindow}
                    ibEditRef={ibEditRef}  deleteIndex={deleteIndex} />
        else if(element === 'textarea')
            return <TextArea key={index} index={index} payload={payload[index]} displayTAEditWindow={displayTAEditWindow}
                    taEditRef={taEditRef}  deleteIndex={deleteIndex} />
        else if(element === 'select')
            return <Dropdown key={index} index={index} payload={payload[index]} displayDDEditWindow={displayDDEditWindow}
                    ddEditRef={ddEditRef}  deleteIndex={deleteIndex} />
        else if(element === 'checkbox')
            return <CheckBox key={index} index={index} payload={payload[index]} displayCBEditWindow={displayCBEditWindow}
                    cbEditRef={cbEditRef}  deleteIndex={deleteIndex} />
        else if(element === 'radio')
            return <RadioButton key={index} index={index} payload={payload[index]} displayRBEditWindow={displayRBEditWindow}
                    rbEditRef={rbEditRef}  deleteIndex={deleteIndex} />
        else if(element === 'date')
            return <DateField key={index} index={index} payload={payload[index]} displayDateEditWindow={displayDateEditWindow}
                        dateEditRef={dateEditRef}  deleteIndex={deleteIndex} />
        else if(element === 'datetime')
            return <DateTimeField key={index} index={index} payload={payload[index]} displayDateTimeEditWindow={displayDateTimeEditWindow}
                    dateTimeEditRef={dateTimeEditRef}  deleteIndex={deleteIndex} />

    }
    function handleSubmit(event){
        event.preventDefault()
        console.log('handleSubmit '+JSON.stringify(payload))
    }

    function addBoxes(){
        let input = document.createElement('input')
        input.style.width = "85%"
        input.style.marginLeft = "5%"
        input.style.marginTop = "14px"
        input.required = true
        checkboxGroupRef.current.appendChild(input)
    }
    function removeBoxes(){
        if(checkboxGroupRef.current.children.length <= 1)
            return
        checkboxGroupRef.current.removeChild(checkboxGroupRef.current.children[checkboxGroupRef.current.children.length-1])
    }
    function addRB(){
        let input = document.createElement('input')
        input.style.width = "85%"
        input.style.marginLeft = "5%"
        input.style.marginTop = "14px"
        input.required = true
        rbGroupRef.current.appendChild(input)
    }
    function removeRB(){
        if(rbGroupRef.current.children.length <= 1)
            return
        rbGroupRef.current.removeChild(rbGroupRef.current.children[rbGroupRef.current.children.length-1])
    }

      function addOptions(){
        let input = document.createElement('input')
        input.style.width = "85%"
        input.style.marginLeft = "5%"
        input.style.marginTop = "20px"
        input.required = true
        ddGroupRef.current.appendChild(input)
    }
    function removeBOptions(){
        if(ddGroupRef.current.children.length <= 1)
            return
        ddGroupRef.current.removeChild(ddGroupRef.current.children[ddGroupRef.current.children.length-1])
    }
    


    function setShowCheckbox(value){
        showCheckboxRef.current.style.display = 'block'
    }

	return(
	   <div class='main-body'>
        <div class="container mt-5">
        <div class="row">
            <div class="col-md-3">
                <h3>Form Elements</h3>
                <div class="toolbox">
                    <div class="form-element" onClick={()=>showTextboxRef.current.style.display = 'block'}>
                        Text Input
                    </div>
                    <div class="form-element" onClick={(event)=>showTextareaRef.current.style.display = 'block'}>
                        Text Area
                    </div>
                    <div class="form-element" onClick={(event)=>showDDRef.current.style.display = 'block'}>
                        Dropdown
                    </div>
                    <div class="form-element" onClick={()=>setShowCheckbox(true)}>
                        Checkbox
                    </div>
                    <div class="form-element" onClick={(event)=>showRBRef.current.style.display = 'block'   }>
                        Radio Button
                    </div>
                    <div class="form-element" onClick={(event)=>showDateRef.current.style.display='block'  }>
                        Date Field
                    </div> 
                    <div class="form-element" onClick={(event)=>showDateTimeRef.current.style.display='block'  }>
                        Date Time Field
                    </div>                   
                </div>
            </div>

            <div class="col-md-9">
                <h4>Form</h4>
                <div class="form-builder" >
                    <form class="form" onSubmit={handleSubmit} style={{maxHeight: '100px'}}>
                        {formElements.map((element,index) => renderComponent(element, index))}                    
                    </form>
                </div>
                <button class="btn btn-primary mt-3" onClick={generateCode}>Create Form</button>
                <pre class="mt-3"><code id="form-code"></code></pre>
            </div>
        </div>
    </div>
   
   {/****.   CheckBOX          ******/}
    <div style={{display:'none'}} ref={showCheckboxRef}>
        <div class="create-wrapper" style={{backgroundColor: '##f7f7f7'}}>      
              <form onSubmit={createCheckBoxGroup}>          
                  <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
                      <a href="javascript:void(0)" class="create-closebtn" onClick={closeCBWindow}>&times;</a>
                      
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Label on Agent Portal:<p class='red-color'>*</p></div>
                      <div><input type="text" name="agentPortalName" style={{width: "90%", paddingLeft:'5px'}} placeholder={'Label on Agent Portal'} required  maxlength='500' ref={cbAPLabelRef} /></div>
                      <div style={{ marginTop: "3%"}} class='create-form-label'>Label on Customer Portal:<p class='red-color'>*</p></div>
                      <div><input  type="text" name="customerPortalName"  style={{width: "90%", outline: 'none', paddingLeft:'5px' }}     placeholder={'Label on Customer Portal'}  maxlength='320'  required ref={cbAPLabelRef} /> </div>

                      <div style={{marginTop:'3%'}} ref={checkboxGroupRef}>
                        <div class='create-form-label'>Checkbox Values:</div>                      
                            <input  style={{marginLeft:'5%', width:'85%', display:'inline',marginRight:'20px'}}  required/> 
                      </div>
                      <div style={{marginTop:'20px'}}><a href='#' onClick={addBoxes}>Add  Boxes</a> <a href='#' style={{float:'right'}} onClick={removeBoxes}>Remove  Boxes</a></div>                       
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Agent Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}} ><input  type="checkbox" name="aRequired"  style={{width: "14px", outline: 'none' }} ref={cbaRequiredRef} /> &nbsp; Mandatory Value:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentView"  style={{width: "14px", outline: 'none' }} ref={cbagentViewRef} /> &nbsp;  Visible to agents after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentEdit"  style={{width: "14px", outline: 'none' }} ref={cbagentEditRef} /> &nbsp;  Editable after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="requiredResolution"  style={{width: "14px", outline: 'none' }} ref={cbRequiredSolutionRef} />&nbsp;   Mandatory for Ticket Resolution:  </div>
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Customer Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}}><input  type="checkbox" name="cRequired"  style={{width: "14px", outline: 'none' }} ref={cbcustRequiredRef} />&nbsp; Mandatory Value  </div>                      
                      <div style={{ marginTop: "10px",marginLeft:'50px'}}><input  type="checkbox" name="custEdit"  style={{width: "14px", outline: 'none' }} ref={cbcustEditRef} />&nbsp;  Editable after Ticket Creation:  </div>
                    </div>  
                    <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
                      <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}} >Create Checkbox Group</button>                      
                    </div>            
                  </form>                             
          </div>
        </div>
 
    {/****.  End of  CheckBOX          ******/}

{/****.   TextBox          ******/}
    <div ref={showTextboxRef} style={{display : 'none'}} >
        <div class="create-wrapper" style={{backgroundColor: '##f7f7f7'}}>      
              <form onSubmit={createTextbox}>          
                  <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
                      <a href="javascript:void(0)" class="create-closebtn" onClick={closeTBWindow}>&times;</a>                      
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Label on Agent Portal:<p class='red-color'>*</p></div>
                      <div><input type="text" name="agentPortalName" style={{width: "90%", paddingLeft:'5px'}} placeholder={'Label on Agent Portal'} required  maxlength='500' ref={ibAPLabelRef} /></div>
                      <div style={{ marginTop: "3%"}} class='create-form-label'>Label on Customer Portal:<p class='red-color'>*</p></div>
                      <div><input  type="text" name="customerPortalName"  style={{width: "90%", outline: 'none', paddingLeft:'5px' }}     placeholder={'Label on Customer Portal'}  maxlength='320' required ref={ibAPLabelRef}/> </div>
                      <div style={{ marginTop: "3%"}} class='create-form-label'>Placeholder</div>
                      <div><input  type="text" name="placeholder"  style={{width: "90%", outline: 'none' }}     placeholder={'Placeholder'}  maxlength='320'/> </div>
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Agent Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}} ><input  type="checkbox" name="aRequired"  style={{width: "14px", outline: 'none'  }} ref={ibaRequiredRef}/> &nbsp; Mandatory Value:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentView"  style={{width: "14px", outline: 'none' }} ref={ibagentViewRef}/> &nbsp;  Visible to agents after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentEdit"  style={{width: "14px", outline: 'none' }}  ref={ibagentEditRef}/> &nbsp;  Editable after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="requiredResolution"  style={{width: "14px", outline: 'none' }} ref={ibRequiredSolutionRef} />&nbsp;   Mandatory for Ticket Resolution:  </div>
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Customer Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}}><input  type="checkbox" name="cRequired"  style={{width: "14px", outline: 'none' }} ref={ibcustRequiredRef} />&nbsp; Mandatory Value  </div>                      
                      <div style={{ marginTop: "10px",marginLeft:'50px'}}><input  type="checkbox" name="custEdit"  style={{width: "14px", outline: 'none' }} ref={ibcustEditRef} />&nbsp;  Editable after Ticket Creation:  </div>
                   </div>                    
                   <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
                        <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}} >Create Textbox</button>                      
                   </div>            
                  </form>                             
          </div>
      </div>
    {/****.  End of  TextBox          ******/}


{/****.   Text Area          ******/}
    <div ref={showTextareaRef} style={{display:'none'}}>
        <div class="create-wrapper" style={{backgroundColor: '##f7f7f7'}}>      
              <form onSubmit={createTextarea}>          
                  <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
                      <a href="javascript:void(0)" class="create-closebtn" onClick={closeTAWindow}>&times;</a>                      
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Label on Agent Portal:<p class='red-color'>*</p></div>
                      <div><input type="text" name="agentPortalName" style={{width: "90%", paddingLeft:'5px'}} placeholder={'Label on Agent Portal'} required  maxlength='500' ref={taAPLabelRef}/></div>
                      <div style={{ marginTop: "3%"}} class='create-form-label'>Label on Customer Portal:<p class='red-color'>*</p></div>
                      <div><input  type="text" name="customerPortalName"  style={{width: "90%", outline: 'none', paddingLeft:'5px' }}     placeholder={'Label on Customer Portal'}  maxlength='320'  required ref={taAPLabelRef}/> </div>
                      <div style={{ marginTop: "3%"}} class='create-form-label'>Placeholder</div>
                      <div><input  type="text" name="placeholder"  style={{width: "90%", outline: 'none' }}     placeholder={'Placeholder'}  maxlength='320'/> </div>
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Agent Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}} ><input  type="checkbox" name="aRequired"  style={{width: "14px", outline: 'none' }} ref={taaRequiredRef} /> &nbsp; Mandatory Value:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentView"  style={{width: "14px", outline: 'none' }} ref={taagentViewRef} /> &nbsp;  Visible to agents after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentEdit"  style={{width: "14px", outline: 'none' }} ref={taagentEditRef} /> &nbsp;  Editable after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="requiredResolution"  style={{width: "14px", outline: 'none' }} ref={taRequiredSolutionRef}  />&nbsp;   Mandatory for Ticket Resolution:  </div>
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Customer Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}}><input  type="checkbox" name="cRequired"  style={{width: "14px", outline: 'none' }} ref={tacustRequiredRef} />&nbsp; Mandatory Value  </div>                      
                      <div style={{ marginTop: "10px",marginLeft:'50px'}}><input  type="checkbox" name="custEdit"  style={{width: "14px", outline: 'none' }} ref={tacustEditRef} />&nbsp;  Editable after Ticket Creation:  </div>
                   </div>                    
                   <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
                        <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}} >Create Text Area</button>                      
                   </div>            
                  </form>                             
          </div>
      </div>
   
    {/****.  End of  TextBox          ******/}
{/****.  Dropdown         ******/}
    <div ref={showDDRef} style={{display: 'none'}}>
        <div class="create-wrapper" style={{backgroundColor: '##f7f7f7'}}>      
              <form onSubmit={createDropdown}>          
                  <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
                      <a href="javascript:void(0)" class="create-closebtn" onClick={closeDDWindow}>&times;</a>                      
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Label on Agent Portal:<p class='red-color'>*</p></div>
                      <div><input type="text" name="agentPortalName" style={{width: "90%", paddingLeft:'5px'}} placeholder={'Label on Agent Portal'} required  maxlength='500' ref={ddAPLabelRef}/></div>
                      <div style={{ marginTop: "3%"}} class='create-form-label'>Label on Customer Portal:<p class='red-color'>*</p></div>
                      <div><input  type="text" name="customerPortalName"  style={{width: "90%", outline: 'none', paddingLeft:'5px' }}     placeholder={'Label on Customer Portal'}  maxlength='320'  required ref={ddAPLabelRef}/> </div>                      
                      <div style={{marginTop:'3%'}} ref={ddGroupRef}>
                        <div class='create-form-label'>Checkbox Values:</div>                      
                            <input  style={{marginLeft:'5%', width:'85%', display:'inline',marginRight:'20px'}}  required/> 
                      </div>
                      <div style={{marginTop:'20px'}}><a href='#' onClick={addOptions}>Add  Options</a> <a href='#' style={{float:'right'}} onClick={removeBOptions}>Remove  Options</a></div>                       
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Agent Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}} ><input  type="checkbox" name="aRequired"  style={{width: "14px", outline: 'none' }} ref={ddaRequiredRef} /> &nbsp; Mandatory Value:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentView"  style={{width: "14px", outline: 'none' }} ref={ddagentViewRef}/> &nbsp;  Visible to agents after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentEdit"  style={{width: "14px", outline: 'none' }} ref={ddagentEditRef}  /> &nbsp;  Editable after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="requiredResolution"  style={{width: "14px", outline: 'none' }}  ref={ddRequiredSolutionRef} />&nbsp;   Mandatory for Ticket Resolution:  </div>
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Customer Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}}><input  type="checkbox" name="cRequired"  style={{width: "14px", outline: 'none' }} ref={ddcustRequiredRef} />&nbsp; Mandatory Value  </div>                      
                      <div style={{ marginTop: "10px",marginLeft:'50px'}}><input  type="checkbox" name="custEdit"  style={{width: "14px", outline: 'none' }} ref={ddcustEditRef}  />&nbsp;  Editable after Ticket Creation:  </div>
                   </div>                    
                   <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
                        <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}} >Create Text Area</button>                      
                   </div>            
                  </form>                             
          </div>
        </div>
    {/****.  End of  Dropdown          ******/}
{/****.  Radio Buttons         ******/}
    <div ref={showRBRef} style={{display:'none'}}>
        <div class="create-wrapper" style={{backgroundColor: '##f7f7f7'}}>      
              <form onSubmit={createRB}>          
                  <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
                      <a href="javascript:void(0)" class="create-closebtn" onClick={closeRBWindow}>&times;</a>                      
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Label on Agent Portal:<p class='red-color'>*</p></div>
                      <div><input type="text" name="agentPortalName" style={{width: "90%", paddingLeft:'5px'}} placeholder={'Label on Agent Portal'} required  maxlength='500' ref={rbAPLabelRef}/></div>
                      <div style={{ marginTop: "3%"}} class='create-form-label'>Label on Customer Portal:<p class='red-color'>*</p></div>
                      <div><input  type="text" name="customerPortalName"  style={{width: "90%", outline: 'none', paddingLeft:'5px' }}     placeholder={'Label on Customer Portal'}  maxlength='320'  required ref={rbAPLabelRef}/> </div>                      
                      <div style={{marginTop:'3%'}} ref={rbGroupRef}>
                        <div class='create-form-label'>Radio Button Values:</div>                      
                            <input  style={{marginLeft:'5%', width:'85%', display:'inline',marginRight:'20px'}}  required/> 
                      </div>
                      <div style={{marginTop:'20px'}}><a href='#' onClick={addRB}>Add  Options</a> <a href='#' style={{float:'right'}} onClick={removeRB}>Remove  Options</a></div>                       
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Agent Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}} ><input  type="checkbox" name="aRequired"  style={{width: "14px", outline: 'none' }} ref={rbaRequiredRef} /> &nbsp; Mandatory Value:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentView"  style={{width: "14px", outline: 'none' }} ref={rbagentViewRef}  /> &nbsp;  Visible to agents after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentEdit"  style={{width: "14px", outline: 'none' }}  ref={rbagentEditRef} /> &nbsp;  Editable after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="requiredResolution"  style={{width: "14px", outline: 'none' }}  ref={rbRequiredSolutionRef} />&nbsp;   Mandatory for Ticket Resolution:  </div>
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Customer Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}}><input  type="checkbox" name="cRequired"  style={{width: "14px", outline: 'none' }} ref={rbcustRequiredRef} />&nbsp; Mandatory Value  </div>                      
                      <div style={{ marginTop: "10px",marginLeft:'50px'}}><input  type="checkbox" name="custEdit"  style={{width: "14px", outline: 'none' }} ref={rbcustEditRef} />&nbsp;  Editable after Ticket Creation:  </div>
                   </div>                    
                   <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
                        <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}} >Create Text Area</button>                      
                   </div>            
                  </form>                             
          </div>
        </div>
    {/****.  End of  Radio buttons          ******/}

    {/****.   Date       ******/}
    <div ref={showDateRef} style={{display:'none'}}>
        <div class="create-wrapper" style={{backgroundColor: '##f7f7f7'}}>      
              <form onSubmit={createDate}>          
                  <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
                      <a href="javascript:void(0)" class="create-closebtn" onClick={closeDateWindow}>&times;</a>                      
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Label on Agent Portal:<p class='red-color'>*</p></div>
                      <div><input type="text" name="agentPortalName" style={{width: "90%", paddingLeft:'5px'}} placeholder={'Label on Agent Portal'} required  maxlength='500' ref={dateAPLabelRef}/></div>
                      <div style={{ marginTop: "3%"}} class='create-form-label'>Label on Customer Portal:<p class='red-color'>*</p></div>
                      <div><input  type="text" name="customerPortalName"  style={{width: "90%", outline: 'none', paddingLeft:'5px' }}     placeholder={'Label on Customer Portal'}  maxlength='320'  required ref={dateAPLabelRef}/> </div>                      
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Agent Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}} ><input  type="checkbox" name="aRequired"  style={{width: "14px", outline: 'none' }}  ref={dateaRequiredRef} /> &nbsp; Mandatory Value:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentView"  style={{width: "14px", outline: 'none' }} ref={dateagentViewRef}  /> &nbsp;  Visible to agents after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentEdit"  style={{width: "14px", outline: 'none' }}  ref={dateagentEditRef} /> &nbsp;  Editable after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="requiredResolution"  style={{width: "14px", outline: 'none' }} ref={dateRequiredSolutionRef}  />&nbsp;   Mandatory for Ticket Resolution:  </div>
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Customer Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}}><input  type="checkbox" name="cRequired"  style={{width: "14px", outline: 'none' }} ref={datecustRequiredRef}  />&nbsp; Mandatory Value  </div>                      
                      <div style={{ marginTop: "10px",marginLeft:'50px'}}><input  type="checkbox" name="custEdit"  style={{width: "14px", outline: 'none' }}  ref={datecustEditRef}   />&nbsp;  Editable after Ticket Creation:  </div>
                   </div>                    
                   <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
                        <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}} >Create Text Area</button>                      
                   </div>            
                  </form>                             
          </div>
       </div>
    {/****.  End of  Date          ******/}

    {/****.   Date Time         ******/}
    <div ref={showDateTimeRef} style={{display:'none'}}>
        <div class="create-wrapper" style={{backgroundColor: '##f7f7f7'}}>      
              <form onSubmit={createDateTime}>          
                  <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
                      <a href="javascript:void(0)" class="create-closebtn" onClick={closeDateTimeWindow}>&times;</a>                      
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Label on Agent Portal:<p class='red-color'>*</p></div>
                      <div><input type="text" name="agentPortalName" style={{width: "90%", paddingLeft:'5px'}} placeholder={'Label on Agent Portal'} required  maxlength='500' ref={dateTimeAPLabelRef}/></div>
                      <div style={{ marginTop: "3%"}} class='create-form-label'>Label on Customer Portal:<p class='red-color'>*</p></div>
                      <div><input  type="text" name="customerPortalName"  style={{width: "90%", outline: 'none', paddingLeft:'5px' }}     placeholder={'Label on Customer Portal'}  maxlength='320'  required ref={dateTimeAPLabelRef} /> </div>
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Agent Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}} ><input  type="checkbox" name="aRequired"  style={{width: "14px", outline: 'none' }} ref={dateTimeaRequiredRef} /> &nbsp; Mandatory Value:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentView"  style={{width: "14px", outline: 'none' }}  ref={dateTimeagentViewRef} /> &nbsp;  Visible to agents after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentEdit"  style={{width: "14px", outline: 'none' }} ref={dateTimeagentEditRef}  /> &nbsp;  Editable after Ticket Creation:  </div>
                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="requiredResolution"  style={{width: "14px", outline: 'none' }} ref={dateTimeRequiredSolutionRef} />&nbsp;   Mandatory for Ticket Resolution:  </div>
                      <div class='create-form-label' style={{ marginTop: "3%"}}>Customer Portal</div>
                      <div style={{ marginTop: "5px",marginLeft:'50px'}}><input  type="checkbox" name="cRequired"  style={{width: "14px", outline: 'none' }} ref={dateTimecustRequiredRef}   />&nbsp; Mandatory Value  </div>                      
                      <div style={{ marginTop: "10px",marginLeft:'50px'}}><input  type="checkbox" name="custEdit"  style={{width: "14px", outline: 'none' }} ref={dateTimecustEditRef}  />&nbsp;  Editable after Ticket Creation:  </div>
                   </div>                    
                   <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
                        <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}} >Create Text Area</button>                      
                   </div>            
                  </form>                             
          </div>
       </div>
    {/****.  End of  Date Time        ******/}
</div>
		)


}

export default FormBuilder